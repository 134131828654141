var trackError = require('trackError')
var Cookies = require('js-cookie')
var getUserType = require('./get-user-type')
var getNormalizedUserflowLocale = require('./get-normalized-userflow-locale')
var localeToLang = require('i18n').localeToLang
var capitalize = require('lodash').capitalize

var INTERCOM_EU_IAM_API_BASE_URL = 'https://api-iam.eu.intercom.io'
var isIntercomActive = false

var tracking = module.exports = {}

tracking.hasAnalyticsCode = false

/**
 * Tracks a visit / page view, if analytics is configured
 *
 * Currently this method will only emit a page view in
 * Google Analytics
 */
tracking.trackVisit = function(uri) {
    var analyticsId = ENV.GOOGLE_ANALYTICS_ID

    if (analyticsId) {
        tracking._getAnalyticsPageViewCode(analyticsId, uri)
    }
}

/**
 * Tracks a partner adds signup, if analytics is configured
 *
 * This is currently only intended to be  used if a signup
 * comes from the `external_signup` route
 */
tracking.trackPartnerAddsSignup = function(user, root) {
    if (!ENV.PARTNER_ADS_PROGRAM_ID) {
        return
    }

    var paid = Cookies.get('paid')
    var pacid = Cookies.get('pacid')

    if (!paid || !pacid) {
        return
    }

    var html = tracking._getPartnerAddsCode(ENV.PARTNER_ADS_PROGRAM_ID, user.get('id'), paid, pacid)
    $(root).append('<div class="track-partner-adds-signup-code">' + html + '</div>')
}

tracking._getPartnerAddsCode = function(programId, userId, paid, pacid) {
    return '<img src="https://www.partner-ads.com/dk/leadtracks2s.php?programid=' + programId + '&type=lead&partnerid=' + paid + '&pacid=' + pacid + '&uiv=' + userId + '" width="1" height="1">\n'
}

tracking._getAnalyticsCode = function(accountId) {
    // NOTE(nicklasmoeller): This semicolon on the next line is
    // strictly necessary. If omitted, the build will break, as
    // a result of the minification process we currently use.
    tracking.hasAnalyticsCode = true;

    /* eslint-disable */
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga')
    /* eslint-enable */

    ga('create', accountId, 'auto')
    ga('require', 'displayfeatures')
}

tracking._getAnalyticsPageViewCode = function(account, uri) {
    if (!tracking.hasAnalyticsCode) {
        tracking._getAnalyticsCode(account)
    }
    ga('send', 'pageview', uri)
}

tracking.getAnalyticsEventCode = function(account, eventPart1, eventPart2) {
    if (!tracking.hasAnalyticsCode) {
        tracking._getAnalyticsCode(account)
    }
    ga('send', 'event', eventPart1, eventPart2)
}

/*
This whole module is a little messy. Methods named `get*` which have side
effects fx. This method is a clean break to allow easily emitting Google
Analytics events.
*/
/**
 * Emits a Google Analytics event
 *
 * `category`: required
 * `action`: required
 * `label`: optional
 * `value`: optional
 */
tracking.emitAnalyticsEvent = function(category, action, label, value) {
    try {
        var analyticsId = ENV.GOOGLE_ANALYTICS_ID
        if (!analyticsId) {
            return
        }
        if (!tracking.hasAnalyticsCode) {
            tracking._getAnalyticsCode(analyticsId)
        }
        ga('send', 'event', category, action, label, value)
    } catch(e) {
        trackError(e)
    }
}

/**
 * Gets user type for tracking based on activeMembership data
 */
tracking.getUserType = getUserType

var getIdentificationData = function(user, activeMembership, organization) {
    var signedUpAt = user.get('createdTime')

    var userType = getUserType(activeMembership)

    var identification = {
        globalId: user.get('globalId'),
        name: user.get('name'),
        signed_up_at: signedUpAt ? signedUpAt.format() : null,
        email: user.get('email'),
        locale_code: getNormalizedUserflowLocale(user.get('locale')),
        is_support_staff: window.location.host.startsWith('support'),
        userType: userType,
        npsScore: null,
        is_beta_candidate: organization.get('settings.isBetaCandidate') === '1'
    }

    return identification
}

/**
 * Initializes Userflow for organizations
 */
tracking.startUserflow = function(user, org, userUmbrellas, activeMembership, bankConnectionsPromise) {
    if (!window.userflow || !ENV.USERFLOW_TOKEN) {
        console.warn('Userflow token is not present. Error grouping.')
        return
    }

    var isTrustedPartner = false
    var bankConnections = []

    userUmbrellas
        .all()
        .then(function(umbrellas) {
            isTrustedPartner = umbrellas.some(function(umbrella) {
                return umbrella.isTrustedPartner
            })
        })
        .then(function() {
            // delibaretly placed here to not create dependencies on legacy ember service class
            // this will be removed once the new bank integration is fully rolled out
            return bankConnectionsPromise
                ? bankConnectionsPromise.then(function(connections) { bankConnections = connections })
                : Em.RSVP.resolve()
        })
        .then(function() {
            var identification = getIdentificationData(user, activeMembership, org)
            var hasLegacyBankConnection = bankConnections.length > 0
            var hasExpiredLegacyBankConnection = bankConnections.some(function(connection) {
                return ['E_SESSION_EXPIRED', 'E_LOGIN_FAILED'].includes(connection.errorCode)
            })

            window.userflow.init(ENV.USERFLOW_TOKEN)
            window.userflow.identify(user.get('globalId'), identification)
            window.userflow.group(org.get('globalId'), {
                name: org.get('name'),
                isTrustedPartner: isTrustedPartner,
                eligibleForLoan: true,
                hasLegacyBankConnection: hasLegacyBankConnection,
                hasExpiredLegacyBankConnection: hasExpiredLegacyBankConnection,
                randomBucket: org.get('randomBucket'),
                coaType: org.get('chartOfAccountId'),
                vatPeriod: org.get('salesTaxPeriod').toLowerCase(),
                url: org.get('url'),
                createdAt: org.get('createdTime').format(),
                type: 'organization',
                subscriptionProductPlan: org.get('subscriptionProductPlan'),
                country: org.get('country.id')
            })
            // show or hide resource center (Bookkeeping Hero) depending on privacy settings
            window.userflow.on('resourceCenterChanged', function() {
                Billy.__container__.lookup('service:userflow').setResourceCenterLauncherHidden()
            })
        })
}

/**
 * Initializes Userflow for umbrellas
 */
tracking.startUmbrellaUserflow = function(activeMembership, activeUmbrella, api, user) {
    if (!window.userflow || !ENV.USERFLOW_TOKEN) {
        console.warn('Userflow token is not present. Error grouping.')
        return
    }

    api.getData('/umbrellas/' + activeUmbrella.id + '/organizations')
        .then(function(organizations) {
            var identification = getIdentificationData(user, activeMembership)

            window.userflow.init(ENV.USERFLOW_TOKEN)
            window.userflow.identify(user.get('globalId'), identification)
            window.userflow.group(activeUmbrella.id, {
                name: '(Admin) ' + activeUmbrella.name,
                type: 'admin',
                registrationNo: activeUmbrella.registrationNo,
                hasLogo: !!activeUmbrella.logoFileId,
                customerCount: organizations.length
            })
        })
}

tracking.stopUserflow = function() {
    if (!window.userflow) {
        return
    }

    window.userflow.reset()
}

/**
 * Starts intercom tracking
 */
tracking.startIntercomTracking = function(user, appId, userId, userHash) {
    if (window.Intercom && appId && userId && userHash) {
        var userLanguage = localeToLang(user.get('locale'))
        isIntercomActive = true
        var capitalizedName = user.get('name')
            .split(' ')
            .map(capitalize)
            .join(' ')

        window.Intercom('boot', {
            app_id: appId,
            api_base: INTERCOM_EU_IAM_API_BASE_URL,
            user_id: userId,
            user_hash: userHash,
            language_override: userLanguage,
            name: capitalizedName,
            created_at: user.get('createdTime'),
            email: user.get('email'),
            phone: user.get('phone'),
            legacy_user_id: user.id
        })
    }
}

/**
 * Add company information into intercom
 */
tracking.addCompanyInfoToIntercomTracking = function(organization) {
    if (window.Intercom && isIntercomActive) {
        window.Intercom('update', {
            company: {
                company_id: organization.get('globalId'),
                name: organization.get('name'),
                created_at: organization.get('createdTime'),
                plan: organization.get('subscriptionProductPlan'),
                vat_number: organization.get('vatNo'),
                legal_registration_number: organization.get('registrationNo'),
                country: organization.get('country').id,
                brand: organization.get('brand'),
                random_bucket: organization.get('randomBucket'),
                is_test: organization.get('isTest'),
                legacy_company_id: organization.id
            }
        })
    }
}

/**
 * Stops intercom tracking
 */
tracking.stopIntercomTracking = function() {
    if (window.Intercom && isIntercomActive) {
        isIntercomActive = false
        window.Intercom('shutdown')
    }
}

/**
 * Refreshes Hubspot chat widget
 */
tracking.refreshHubspotWidget = function() {
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        setTimeout(function() {
            window.HubSpotConversations.widget.refresh()
        }, 0)
    }
}
