var moment = require('momentjs')
var storage = require('storage')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.Component.extend({
    segment: Em.inject.service(),

    customEvent: Ember.inject.service(),

    layoutName: 'components/trial-expiration-warning-bar',

    classNames: ['top-bar', 'top-bar-yellow'],

    organization: null,

    daysLeft: function() {
        return this.get('organization.subscriptionExpires').diff(moment.utc().startOf('day'), 'days')
    }.property('organization.subscriptionExpires'),

    daysSinceSignup: function() {
        return moment().diff(this.get('organization.createdTime'), 'days')
    }.property('organization.createdTime'),

    isExpired: Em.computed.lt('daysLeft', 0),

    canClose: function() {
        // Danish organizations cannot close it
        return this.get('organization.country.id') !== 'DK'
    }.property('organization.country.id'),

    actions: {
        notifyTrialBarClicked: function() {
            this.get('segment').track('Trial Message Clicked (Client)', {
                context: 'top_banner',
                days_remaining_in_trial: this.get('daysLeft'),
                days_since_signup: this.get('daysSinceSignup')
            })
        },

        close: function() {
            storage('hideTrialExpirationWarningBar:' + this.get('organization.id'), 'true')
            this.container.lookup('controller:application').notifyPropertyChange('hasTrialExpirationBar')
        },

        redirectToSubscriptionPlans: function() {
            var self = this

            // when already inside React router - redirect to plans_selection route
            // has to be done on React side (triggered with customEvent),
            // otherwise Ember transitionTo can be used
            if (window.location.href.includes('/settings#')) {
                self.get('customEvent').dispatchEvent(CustomEvent.RoutingLinkClicked, { route: '/organization/subscription/plans_selection' })
                return
            }

            self.container.lookup('router:main').transitionTo('organization_subscription.plans_selection')
        }
    }
})
