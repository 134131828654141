var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')
var getUserType = require('../utils/get-user-type')
var billsView = require('../mixins/bills-view')

module.exports = Em.ObjectController.extend(ReactDirtyRouteMixin, billsView, {
    needs: ['application', 'user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership'),

    customActions: function() {
        return _.merge(this.get('_customActions'), this.get('_customActionsBills'), {})
    }.property('_customActions', '_customActionsBills')
})
