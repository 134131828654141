function isError(errorOrMessage) {
    return errorOrMessage instanceof Error
}

module.exports = function trackError(errorOrMessage) {
    var error

    if (typeof errorOrMessage === 'string') {
        error = new Error(errorOrMessage)
    } else if (isError(errorOrMessage)) {
        error = errorOrMessage
    } else {
        error = new Error(String(errorOrMessage))
    }

    if (window.DD_RUM && window.DD_RUM.addError) {
        // it's easier not to log 403 errors than to scope guard them in Ember code
        if (error.status !== 403) {
            window.DD_RUM.addError(error)
        }

        return
    }

    // eslint-disable-next-line no-console
    console.error(error)
}
