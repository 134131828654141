var trackError = require('trackError')
var assign = require('lodash').assign

var SEGMENT_WRITE_KEY = window.ENV ? window.ENV.SEGMENT_WRITE_KEY : process.env.SEGMENT_WRITE_KEY

module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user').get('model')
    }.property(),

    getIdentificationId: function(organization) {
        return organization.get('globalId')
    },

    /**
     * Tracks a segment event
     */
    track: function(event, payload) {
        if (!window.analytics) {
            trackError('Analytics script not present')
            return
        }

        var organization = this.container.lookup('controller:organization').get('model')
        var user = this.get('user')
        var userId = user && user.get('id')
        var userEmail = user && user.get('email')

        if (!organization) {
            window.analytics.track(event, payload)
            return
        }

        var identificationId = this.getIdentificationId(organization)

        var deviceData = {
            screen_width: window.screen.width,
            screen_height: window.screen.height
        }

        var organizationData = {
            id: identificationId,
            organization_id: identificationId,
            name: organization.get('name'),
            email: organization.get('ownerUmbrellaId') ? '' : organization.get('email'),
            billing_plan: organization.get('subscriptionProductPlan'),
            billing_period: organization.get('subscriptionPeriod'),
            is_trial: organization.get('isTrial'),
            brand: organization.get('brand'),
            current_user_id: user.get('globalId'),
            client: 'webapp'
        }

        if (!identificationId || !userId) {
            try {
                var noOrganizationDataTraits = {
                    event: event,
                    organization_url: organization.get('url'),
                    debug_organization_id: organization.get('id'),
                    path: window.location.pathname,
                    source: organization.get('source'),
                    client: 'webapp'
                }

                window.analytics.track('XXX - Debug - Possible no organization data', assign(organizationData, noOrganizationDataTraits, deviceData))
            } catch(error) {
                var noOrganizationDataFailedTraits = {
                    error: error.message,
                    event: event,
                    organization_url: organization.get('url'),
                    path: window.location.pathname,
                    client: 'webapp'
                }

                window.analytics.track('XXX - Debug - Possible no organization data - failed to get id', assign(organizationData, noOrganizationDataFailedTraits, deviceData))
            }
            return
        }

        var facebookRequiredEvents = {
            action_source: 'website',
            client_user_agent: navigator.userAgent,
            event_source_url: window.location.href
        }

        var userData = !userId ? {} : {
            user_id: organization.get('id'),
            email: userEmail
        }

        window.analytics.track(event, assign(organizationData, userData, facebookRequiredEvents, deviceData, payload))
    },

    /**
     * Returns an object containing identification traits for the given organization.
     *
     * @param {string} identificationId - The identification ID of the organization.
     * @param {Object} organization - The organization object.
     * @returns {Object} An object containing identification traits.
     */
    getIdentificationTraits: function(identificationId, organization) {
        return {
            id: identificationId,
            organization_id: identificationId,
            name: organization.get('name'),
            created_at: organization.get('createdTime').format(),
            email: organization.get('ownerUmbrellaId') ? '' : organization.get('email'),
            current_billing_period: organization.get('subscriptionPeriod'),
            current_billing_plan: organization.get('subscriptionProductPlan'),
            vat_number: organization.get('registrationNo'),
            current_brand: organization.get('brand'),
            country: organization.get('countryId'),
            is_test: organization.get('isBrandAgeras') ? organization.get('isTest') : undefined, // in Billy case this is handled by BE event
            random_bucket: organization.get('randomBucket'),
            currency: organization.get('baseCurrencyId'),
            vat_period: organization.get('salesTaxPeriod'),
            coa_type: organization.get('chartOfAccountId'),
            url: organization.get('url'),
            owner_user_id: organization.get('ownerUserId'),
            owner_umbrella_id: organization.get('ownerUmbrellaId'),
            trial_expiry_date: organization.get('trialExpiryDate'),
            consolidated_billing: organization.get('consolidatedBilling'),
            locale_id: organization.get('localeId'),
            billing_platform: organization.get('billingType') === 'external' ? 'upodi' : 'billy',
            is_trial: organization.get('isTrial'),
            is_beta_candidate: organization.get('settings.isBetaCandidate') === '1'
        }
    },

    /**
     * Identifies the given organization to the analytics service.
     *
     * @param {Object} organization - The organization object.
     */
    identify: function(organization) {
        var self = this
        if (!window.analytics) {
            trackError('Analytics script not present')
            return
        }

        if (!organization) {
            trackError('Organization not present')
            return
        }

        var identificationId = this.getIdentificationId(organization)

        window.analytics.ready(function() {
            window.analytics.identify(identificationId, self.getIdentificationTraits(identificationId, organization))
        })
    },

    /**
     * Forces identification of the given organization to the analytics service without checking analytics service presence.
     *
     * @param {Object} organization - The organization object.
     */
    forceIdentify: function(organization) {
        var self = this
        if (!window.analytics) {
            trackError('Analytics script not present')
            return
        }

        if (!organization) {
            trackError('Organization not present')
            return
        }

        var identificationId = this.getIdentificationId(organization)

        window.analytics.identify(identificationId, self.getIdentificationTraits(identificationId, organization))
    },

    setAnonymousId: function(anonymousId) {
        if (!window.analytics) {
            return
        }

        try {
            if (window.analytics.setAnonymousId) {
                window.analytics.setAnonymousId(anonymousId)
            } else if (SEGMENT_WRITE_KEY) {
                window.analytics.load(SEGMENT_WRITE_KEY)
                window.analytics.page()
                window.analytics.setAnonymousId(anonymousId)
            }
        } catch(error) {
            trackError(error)
        }
    }
})
