var t = require('i18n').t
var tracking = require('../utils/tracking')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    classNames: ['subscriptions-overview'],

    needs: ['organization'],

    userOrganizations: Em.inject.service(),

    organizationSubscription: Em.inject.service(),

    constraints: Ember.inject.service(),

    featureList: Em.inject.service(),

    intercom: Em.inject.service(),

    subscription: Em.inject.service(),

    segment: Em.inject.service(),

    coupons: Em.inject.service(),

    dashboardNavigator: Em.inject.service(),

    organization: Em.computed.oneWay('controllers.organization.model'),

    isOwner: Em.computed.oneWay('userOrganizations.activeMembership.isOwner'),

    defaultActiveCardSubscription: Em.computed.alias('organization.defaultActiveCardSubscription'),

    // TODO: Should probably compute from locked-subscription-plan
    selectedSubscriptionPeriod: 'yearly',

    subscriptionCost: 0,

    addonsCost: 0,

    subscriptionAmount: 0,

    requiredAddons: null,

    selectedProduct: null,

    dirty: false,

    focusSelector: null,

    error: null,

    isSubscriptionPeriodYearly: null,

    monthlyPriceMonthlySubscription: null,

    monthlyPriceYearlySubscription: null,

    yearlyPriceYearlySubscription: null,

    yearlySavings: null,

    queryParams: [
        'period',
        'coupon',
        'duration',
        'plan'
    ],

    init: function() {
        this.set('monthlyPriceMonthlySubscription', this.get('organizationSubscription').getSubscriptionPriceMonthly('premium', false))
        this.set('monthlyPriceYearlySubscription', this.get('organizationSubscription').getSubscriptionPriceMonthly('premium', true))
        this.set('yearlyPriceYearlySubscription', this.get('organizationSubscription').getSubscriptionPriceYearly())
        this.set('yearlySavings', this.get('organizationSubscription').getYearlySavings())
    },

    isAddonsSelected: function() {
        var selectedProduct = this.get('selectedProduct')

        return selectedProduct === 'addons'
    }.property('selectedProduct'),

    isPremiumSelected: function() {
        var selectedProduct = this.get('selectedProduct')

        return selectedProduct === 'premium'
    }.property('selectedProduct'),

    isOverdueInvoice: function() {
        return this.get('isOwner') && this.get('organization.lockedCode') === 'overdueInvoice'
    }.property('organization.lockedCode'),

    isOverdueInvoiceAndNotOwner: function() {
        return !this.get('isOwner') && this.get('organization.lockedCode') === 'overdueInvoice'
    }.property('organization.lockedCode'),

    isExpiredTrial: function() {
        return this.get('organization.lockedCode') === 'expiredTrial'
    }.property('organization.lockedCode'),

    isExceededPostingLimit: function() {
        return this.get('organization.lockedCode') === 'exceededPostingLimit'
    }.property('organization.lockedCode'),

    isOtherErrorReason: function() {
        return (
            !this.get('overdueInvoice') &&
            !this.get('isExpiredTrial') &&
            !this.get('exceededPostingLimit')
        )
    }.property('overdueInvoice', 'isExpiredTrial', 'exceededPostingLimit'),

    plans: function() {
        return this.get('organizationSubscription').getAllPlans()
    }.property('organizationSubscription'),

    basicPlan: function() {
        return this.get('plans').findBy('id', 'basic')
    }.property('plans'),

    premiumPlan: function() {
        return this.get('plans').findBy('id', 'premium')
    }.property('plans'),

    invalidDisable: function(addon) {
        var message = null

        switch (addon.id) {
        case 'auto_bookkeeping':
            message = t('organization_subscription.addon.' + addon.id + '.clicked_disabled_activation_button')
            break
        }

        this.set('disabledAddonButtonClickedWarning', message)
    },

    addonRenewalMessage: function() {
        var renewalDates = this.get('requiredAddons').map(function(addOn) {
            if (addOn.expirationDate) {
                return moment(addOn.expirationDate).add(1, 'days')
            } else if (addOn.period === 'monthly') {
                return moment().add(1, 'months').startOf('month')
            } else {
                return moment().add(1, 'year').startOf('year')
            }
        })

        return t('organization_locked.footer', {
            count: renewalDates.length,
            renewalDate: _.min(renewalDates).format('DD/MM/YYYY')
        })
    }.property('requiredAddons'),

    addons: function() {
        return this.get('featureList').getAddons()
    }.property('featureList'),

    initSetUp: function() {
        // reset error on init
        this.set('error', null)
    }.on('init'),

    features: function() {
        return {
            basic: this.get('featureList').getFeatures(false, 'isNotIncluded', 'basic'),
            premium: this.get('featureList').getFeatures(false, 'isIncluded', 'premium')
        }
    }.property('featureList'),

    notifyLockedViewed: function() {
        if (this.get('isExpiredTrial')) {
            this.get('segment').track('Billing Expired Trial Viewed (Client)')
        } else if (this.get('isOverdueInvoice')) {
            this.get('segment').track('Billing Overdue Invoice Viewed (Client)')
        }
    }.on('init'),

    actions: {
        showIntercomMessenger: function() {
            this.get('intercom').showNewMessage()
        },

        toggleSubscriptionPeriod: function(value) {
            this.set('isSubscriptionPeriodYearly', value)
        },

        showChangeSubscriptionWindow: function(clickedSubscriptionPlan) {
            var self = this
            this.get('organizationSubscription')
                .checkIfSubscriptionChangeIsPossible(clickedSubscriptionPlan)
                .then(function(isChangePossible) {
                    if (!isChangePossible) {
                        return
                    }

                    self.container.lookup('component:confirm-subscription')
                        .set('subscriptionPlan', clickedSubscriptionPlan)
                        .set('organization', self.get('organization'))
                        .set('isSubscriptionPeriodYearly', self.get('isSubscriptionPeriodYearly'))
                        .set('isExpiredTrial', self.get('isExpiredTrial'))
                        .set('hideChangePlanLink', true)
                        .show()
                })
        },

        addonsCostChanged: function(consolidatedCost) {
            this.set('addonsCost', consolidatedCost)

            if (consolidatedCost > 0) {
                this.set('selectedProduct', 'addons')
            } else {
                this.set('selectedProduct', null)
            }
        },

        didSelectPremium: function() {
            this.set('dirty', true)
            this.set('selectedProduct', 'premium')
        },

        didSelectAddons: function() {
            this.set('dirty', true)
            this.set('selectedProduct', 'addons')
        },

        setSubscriptionCost: function(context) {
            this.set('subscriptionCost', context.price)
            this.set('selectedSubscriptionPeriod', context.period)
        },

        setSubscriptionAmount: function(price) {
            this.set('subscriptionAmount', price)
        },

        didClickOpenSubscriptionOverview: function() {
            this.get('constraints').showUpgradeOverlay('', 'organization_locked')
        },

        didClickUpgrade: function() {
            var self = this
            var organizationSubscription = this.get('organizationSubscription')
            var addons = this.get('requiredAddons')
            var selectedProduct = this.get('selectedProduct')
            var period = this.get('selectedSubscriptionPeriod')

            // Assume plan downgrade to basic, if selectedProduct isn't premium
            var subscriptionPlan = selectedProduct === 'premium' ? 'premium' : 'basic'
            var subscriptionPeriod = selectedProduct === 'premium' ? period : undefined

            return organizationSubscription.editSubscription(subscriptionPlan, subscriptionPeriod, addons)
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.ORGANIZATION_LOCKED_UPGRADE, t('changes_saved'))
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.ORGANIZATION_LOCKED_UPGRADE, e.message)
                })
        },

        enterCardDetails: function() {
            var subscription = this.get('subscription')
            subscription.subscribeCard()
        },

        enterCardDetailsStripe: function() {
            this.get('organizationSubscription').stripeCheckout()
        },

        skip: function() {
            this.get('dashboardNavigator').replaceUrlWithDashboard()
        },

        redeemCouponError: function() {
            this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.invalid_coupon'))
        },

        redeemCouponSuccess: function() {
            this.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.upgraded_to_premium'))
        }
    },

    updateSubscription: function(plan, period, addons, couponCode, price) {
        var self = this
        var organizationSubscription = this.get('organizationSubscription')

        this.set('error', null) // reset error

        return organizationSubscription.ensureLoaded()
            .then(function() {
                return organizationSubscription.editSubscription(plan, period, addons, couponCode, price)
            })
            .then(function() {
                if (plan === 'basic') {
                    tracking.emitAnalyticsEvent('plan', 'basic_chosen')
                }
                return organizationSubscription.load() // reload subscription
            })
            .catch(function(e) {
                self.set('error', e.message)
                throw e
            })
    },

    displayErrorNotification: function() {
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.invalid_coupon'))
    },

    displaySuccessNotification: function() {
        this.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.upgraded_to_premium'))
    },

    customActions: {
        editSubscription: function(e) {
            var self = this
            var couponCode = this.get('redeemedCouponCode')

            return self.updateSubscription(e.detail.plan, e.detail.period, undefined, couponCode, e.detail.price)
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.SUBSCRIPTION_UPDATE, t('changes_saved'))
                })
        },
        redeemCoupon: function(e) {
            var self = this
            var coupons = this.get('coupons')
            coupons.redeemCoupon(this.get('organization.id'), e.detail.code)
                .then(function() { // coupon is valid and redeemed
                    self.displaySuccessNotification()
                })
                .catch(function() { // invalid coupon
                    self.displayErrorNotification()
                })
        }
    }

})
