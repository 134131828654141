var trackError = require('trackError')
var absoluteRouteUrl = require('../utils/absolute-route-url')
var BankConnectionSetup = require('./bank-connection-setup')
var batmask = require('batmask')
var get = require('lodash').get
var NOTIFICATION_KEYS = require('../notificationKeys')
var t = require('i18n').t
var uuid = require('../utils/uuid')

module.exports = BankConnectionSetup.extend({
    layout: require('../templates/components/bank-connection-setup-nordic-api'),

    classNames: ['nordic-api-setup'],

    width: 600,

    closable: false,

    accounts: [],

    sessions: [],

    matchingSession: null,

    selectedAccount: null,

    onSubmit: null,

    organizationSubscription: Em.inject.service(),

    organizationConsentEntries: Em.inject.service(),

    error: null,

    errorHandler: function(e) {
        if (e.code === 'UNATTENDED_LOGIN_NOT_SUPPORTED_DANSKE') {
            this.set('error', t('nordic_api.setup.unattended_error_danske'))
        } else if (e.code === 'UNATTENDED_LOGIN_NOT_SUPPORTED') {
            this.set('error', t('nordic_api.setup.unattended_error'))
        } else {
            this.set('error', e.message)
        }
    },

    isMissingAccount: function() {
        // Is true if the user hasn't selected a bank account from the dropdown
        if (this.selectedAccount) {
            return false
        }
        return true
    }.property('selectedAccount'),

    hasMatchingSession: function() {
        // Is true if current account has a nordic-api session linked to it
        if (this.get('matchingSession')) {
            return true
        }
        return false
    }.property('matchingSession'),

    loadSessions: function() {
        var self = this
        var url = '/spiir/organizations/' + this.get('account.organization.id') + '/sessions'

        this.set('error', null)
        this.set('isLoading', true)

        // Get all nordic-api sessions for the current organization
        this.get('api').getData(url)
            .then(function(sessions) {
                self.set('sessions', _.map(sessions, function(session) {
                    return {
                        id: session.id,
                        bankName: session.bankName || 'bank',
                        bankId: session.bankId,
                        loginTokenExpiryTime: session.loginTokenExpiryTime
                    }
                }))

                if (!sessions.length) {
                    self.set('hasMatchingSession', false)
                } else {
                    return self.findMatchingSession()
                }
            }, this.errorHandler.bind(this))
            .finally(function() {
                self.set('isLoading', false)
            })
    }.observes('account'),

    giveConsent: function() {
        var self = this

        return this.get('organizationConsentEntries')
            .giveConsent(
                self.get('account.organization.id'),
                self.get('organizationConsentEntries.context.BANK_INTEGRATION'),
                self.get('organizationConsentEntries.context.BANK_INTEGRATION_VERSION'),
                t('bank_connection.legal_note')
            )
            .then(function(response) {
                var consentEntry = response.organizationConsentEntries[0]
                return consentEntry
            })
    },

    findMatchingSession: function() {
        var self = this
        var sessions = this.get('sessions')
        var sessionFound = null

        if (this.get('account.bankConnection.isConnected')) {
            // Find the session matching Billy's account connected Session (if Billy account has an active connection)
            sessionFound = _.find(sessions, { id: this.get('account.bankConnection.referenceId') })
        } else if (this.get('sessionId')) {
            // Find the session matching the sessionId from the URL_PARAMS
            sessionFound = _.find(sessions, { id: this.get('sessionId') })
        } else {
            // Find the session matching current's account session (probably fails if current account has a custom bank)
            sessionFound = _.find(sessions, { bankId: self.get('account.bank.id') })
        }

        if (sessionFound) {
            self.set('matchingSession', sessionFound)
            return self.loadAccounts()
        }
    },

    loadAccounts: function() {
        var self = this

        var url = '/spiir/organizations/' + this.get('account.organization.id') + '/sessions/' + this.get('matchingSession.id') + '/accounts'

        this.set('error', null)
        this.set('accounts', [])

        // Get all bank accounts from the current nordic-api session
        return this.get('api').request('get', url)
            .then(function(payload) {
                return payload.data
            })
            .then(function(accounts) {
                self.set('accounts', accounts)
            }, this.errorHandler.bind(this))
            .catch()
    },

    accountList: function() {
        var accounts = this.get('accounts')

        return accounts.map(function(account) {
            var bban = get(account, 'number.bban', '')

            return Em.Object.create({
                name: account.name + ' ' + bban,
                value: {
                    id: account.id,
                    name: account.name,
                    bban: bban,
                    label: account.name + ' ' + bban
                }
            })
        })
    }.property('accounts'),

    setSelectedAccount: function() {
        var billyAccountRegNo = this.get('account.bankRoutingNo')
        var billyAccountNo = this.get('account.bankAccountNo')

        if (!billyAccountRegNo || !billyAccountNo) {
            return
        }

        var matchingAccount = this.get('accountList').find(function(account) {
            return account.value.bban === (billyAccountRegNo + '-' + billyAccountNo)
        })

        if (matchingAccount) {
            this.set('selectedAccount', matchingAccount.value)
        }
    }.observes('accountList'),

    onLegalNoteConfirm: function() {
        var self = this
        var account = this.get('account')
        var sessions = this.get('sessions')

        // Find the session matching current's account session (probably fails if current account has a custom bank)
        var matchingSession = _.find(sessions, { bankId: self.get('account.bank.id') })
        if (matchingSession) {
            this.set('matchingSession', matchingSession)
            return self.loadAccounts()
        }

        if (account.get('isDirty')) {
            account.save()
                .mask()
                .on('success', function() {
                    self.send('redirectToNordicApi')
                })
                .on('error', function(error) {
                    trackError(error)
                })
        } else {
            this.send('redirectToNordicApi')
        }
    },

    handleConfirmLegalNoteFromModal: function() {
        this.onLegalNoteConfirm()
    },

    handleOnSubmitCallback: function() {
        var onSubmit = this.get('onSubmit')

        if (typeof onSubmit === 'function') {
            onSubmit()
        }
    },

    actions: {
        submitConnection: function() {
            var self = this
            var wasConnected

            batmask.maskDelayed()

            this.giveConsent()
                .then(function(consentEntry) {
                    var changes = {
                        type: 'nordic_api',
                        consentEntryId: consentEntry.id,
                        bankName: self.get('matchingSession.bankName'),
                        accountName: self.get('selectedAccount.label'),
                        accountNo: self.get('selectedAccount.id'),
                        referenceId: self.get('matchingSession.id')
                    }
                    wasConnected = self.get('account.bankConnection.isConnected')
                    return self.get('bankConnections').updateBankConnection(self.get('account'), changes)
                })
                .then(function(bankConnection) {
                    if (!wasConnected) {
                        bankConnection.set('session', self.get('matchingSession'))
                        self.container.lookup('util:notification').success(NOTIFICATION_KEYS.BANK_CONNECTION_CONNECT, t('bank_connection_editor.connected_confirmation'))
                    }

                    self.handleOnSubmitCallback()
                })
                .catch(self.errorHandler)
                .finally(function() {
                    batmask.unmask()
                    self.close()
                })
        },

        next: function() {
            var self = this

            this.container.lookup('component:bank-legal-note-modal')
                .set('giveConsentOnConfirm', false)
                .set('onConfirm', function() {
                    self.handleConfirmLegalNoteFromModal()
                })
                .show()
        },

        redirectToNordicApi: function() {
            var domain = ENV.isTest ? '' : ENV.newApiUrl

            var redirectUrl = absoluteRouteUrl(this.container, 'bank_sync', this.get('account.id'))
            var url = domain + '/spiir/organizations/' + this.get('account.organization.id') + '/login?redirect_url=' + redirectUrl

            url += '&tid=' + uuid.getRandom()

            var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
            url += '&access_token=' + accessToken

            var bankId = this.get('account.bank.id')

            this.get('api').request('get', '/v2/banks/' + bankId)
                .then(function(payload) {
                    return payload.bank
                })
                .then(function(bank) {
                    url += '&bankId=' + bank.identifier
                })
                .finally(function() {
                    window.location = url
                })
        },

        cancelConfirm: function() {
            var self = this

            this.container.lookup('util:dialog')
                .confirmWarning(
                    t('nordic_api.select_account.confirm_cancel_title'),
                    t('nordic_api.select_account.confirm_cancel_description'),
                    t('nordic_api.select_account.confirm_cancel_yes'),
                    t('nordic_api.select_account.confirm_cancel_no')
                )
                .then(function(answer) {
                    if (answer === 'yes') {
                        self.close()
                    }
                })
        },

        disconnectAccount: function() {
            var self = this
            var account = this.get('account')

            this.container.lookup('util:dialog').confirmWarning(
                null,
                t('nordic_api.account_connected.confirm_disconnect_info'),
                t('nordic_api.account_connected.confirm_disconnect_yes')
            )
                .then(function() {
                    batmask.maskDelayed()
                    return self.get('bankConnections').disconnectBankConnection(account)
                })
                .then(function() {
                    self.handleOnSubmitCallback()
                    self.close()
                }, this.errorHandler.bind(this))
                .finally(function() {
                    batmask.unmask()
                })
        },

        invalidDisable: function() {
            var message = t('nordic_api.setup.clicked_disabled_toggle_button')

            this.set('disabledAddonButtonClickedWarning', message)
        },

        onDismissAlert: function() {
            this.set('disabledAddonButtonClickedWarning', null)
        },

        back: function() {
            this.close()
        }
    }
})
