var trackError = require('trackError')
var t = require('i18n').t
var postingSides = require('posting-sides')
var f = require('float')
var moment = require('moment')
var BankFeeAccountWindow = require('../components/bank-fee-account-window')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var CustomEvent = require('../constants/customEvent')
var batmask = require('batmask')

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'bankSyncMatches', 'bankSyncPossibleSubjects', 'nextVoucherNo', 'bankApprovedLines'],

    organization: Em.computed.alias('controllers.organization.model'),

    segment: Em.inject.service(),

    matchSuggestions: Em.computed.alias('controllers.bankSyncMatches.matchSuggestions'),

    customEvent: Ember.inject.service(),

    subscriptionLimits: Ember.inject.service(),

    attachmentsUploadsObject: Em.Object.create(),

    attachmentsUploads: function() {
        var matchId = this.get('model.id')
        return this.get('attachmentsUploadsObject')[matchId] || Em.A([])
    }.property('attachmentsUploadsObject'),

    lines: function() {
        return Em.ArrayController.create({
            container: this.container,
            parentController: this,
            model: this.get('model.lines'),
            itemController: 'bank-sync-match-line',
            sortProperties: ['entryDate']
        })
    }.property('model.lines'),

    bankLineId: function() {
        var ids = this.get('model.lines.ids')

        if (ids.length === 0) {
            return this.get('model.id')
        }

        return this.get('model.lines.ids')[0]
    }.property('model.id', 'model.lines'),

    lineCount: function() {
        return this.get('lines.length')
    }.property('lines.length'),

    lineDescription: function() {
        if (!this.get('lines.length')) {
            return ''
        }
        return this.get('lines').mapProperty('description').join(', ')
    }.property('lines.@each.description'),

    lineAmount: function() {
        if (!this.get('lines.length')) {
            return 0
        }
        return this.get('lines').reduce(function(total, line) {
            return total + (line.get('side.isDebit') ? 1 : -1) * line.get('amount')
        }, 0)
    }.property('lines.@each.amount', 'lines.@each.side'),

    subjectAssociations: function() {
        return Em.ArrayController.create({
            container: this.container,
            parentController: this,
            model: this.get('model.subjectAssociations'),
            itemController: 'bank-sync-match-subject-association'
        })
    }.property('model.subjectAssociations'),

    subjectAmount: function() {
        return this.get('subjectAssociations').reduce(function(total, subjectAsscociation) {
            return total + subjectAsscociation.get('sideAmount')
        }, 0)
    }.property('subjectAssociations.@each.sideAmount'),

    isFulfilled: function() {
        if (!this.get('lines.length') || !this.get('subjectAssociations.length')) {
            return false
        }
        return (!Em.isEmpty(this.get('differenceType')) || f.equals(this.get('lineAmount'), this.get('subjectAmount')))
    }.property('lines.length', 'subjectAssociations.length', 'lineAmount', 'subjectAmount', 'differenceType'),

    differenceLabel: function() {
        switch (this.get('differenceType')) {
        case 'overpayment':
            return t('bank_sync.overpayment')
        case 'underpayment':
            return t('bank_sync.underpayment')
        case 'bankFee':
            return t('bank_sync.bank_fee')
        }
    }.property('differenceType'),

    differenceAmount: function() {
        return this.get('lineAmount') - this.get('subjectAmount')
    }.property('lineAmount', 'subjectAmount'),

    formattedDifferenceAmount: function() {
        return Billy.money(this.get('differenceAmount'), this.get('account.currency.id'))
    }.property('differenceAmount'),

    didSelectDifferenceType: function(differenceType) {
        var self = this
        var match = this.get('model')

        function fail(e) {
            match.rollback()
            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SELECT_DIFFERENT_TYPE, e.message)
        }

        if (differenceType === 'bankFee') {
            BankFeeAccountWindow.getDefaultBankFeeAccount(match.get('account.organization'), this.container)
                .then(function(bankFeeAccount) {
                    match.set('feeAccount', bankFeeAccount)
                        .set('differenceType', differenceType)
                        .save()
                        .then(null, fail)
                })
        } else {
            match.set('differenceType', differenceType)
                .save()
                .then(null, fail)
        }
    },

    linesOrsubjectAssociationsDidChange: function() {
        if (!Em.isEmpty(this.get('differenceType'))) {
            this.set('differenceType', null)
        }
    }.observes('lines.@each', 'subjectAssociations.@each'),

    doMatch: function() {
        var self = this
        var match = this.get('model')
        var matchesController = self.get('controllers.bankSyncMatches')
        var possibleSubjectsController = self.get('controllers.bankSyncPossibleSubjects')

        // Remember the subjects. If one of them is an invoice/bill that was only partially paid, then we must add it back to the possible-subjects list on success
        var subjects = match.get('subjectAssociations').map(function(a) { return a.get('subject') })
        var transactionsSubjects = subjects.filter(function(subject) {
            return subject && subject._data && subject._data.attributes && subject._data.attributes.type !== 'bill' && subject._data.attributes.type !== 'invoice'
        })

        matchesController.removeMatch(match)

        match.set('isApproved', true)

        this.get('segment').track('xxx - ml - Bank reconciliation - matching', {
            type: 'manual_association_accepted'
        })

        if (transactionsSubjects.length > 1 && !Em.isEmpty(this.get('differenceType'))) {
            matchesController.addMatch(match)
            match.rollback()
            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SAVE, t('bank_sync.matches.multiple_journal_postings.error'))
            return
        }

        return match.save()
            .then(function() {
                matchesController.loadMatchSuggestions()
                // Add any still unpaid invoices/bills back to the possible-subjects list
                subjects.forEach(function(subject) {
                    if ((subject instanceof Billy.Invoice || subject instanceof Billy.Bill) && !subject.get('isPaid')) {
                        possibleSubjectsController.addSubject(subject)
                    }
                })
            }, function(e) {
                matchesController.addMatch(match)
                match.rollback()
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SAVE, e.message)
                throw e
            })
    },

    addSubjectAssociation: function(matchId, subjectReferenceId) {
        var api = this.get('api')
        var subjectAssociationsEndpoint = '/v2/bankLineSubjectAssociations'

        return api.request('POST', subjectAssociationsEndpoint, {
            accept: 'application/json',
            payload: {
                bankLineSubjectAssociation: {
                    matchId: matchId,
                    subjectReference: subjectReferenceId
                }
            }
        })
    },

    matchBankLine: function(matchId) {
        var api = this.get('api')
        var matchEndpoint = '/v2/bankLineMatches/' + matchId

        return api.request('PUT', matchEndpoint, {
            accept: 'application/json',
            payload: {
                bankLineMatch: {
                    id: matchId,
                    isApproved: true,
                    differenceType: null
                }
            }
        })
    },

    doAutoMatch: function() {
        var self = this
        var transaction = this.get('autoMatch.transactions')[0]
        var matchId = transaction.match_id
        var subjectReference = this.get('autoMatch.reconcilables')[0]
        var matchesController = this.get('controllers.bankSyncMatches')
        var possibleSubjectsController = self.get('controllers.bankSyncPossibleSubjects')
        var matchesElement = document.querySelector('.matches')
        var currentScrollTop = matchesElement ? matchesElement.scrollTop : 0
        var isAmountEqual = Math.abs(transaction.amount) - Math.abs(subjectReference.amount) === 0

        this.set('hasMatchSuggestion', false)

        this.addSubjectAssociation(matchId, subjectReference.id)
            .then(function() {
                return isAmountEqual ? self.matchBankLine(matchId) : Em.RSVP.resolve()
            }).then(function() {
                var match = self.get('model')
                var promise

                if (isAmountEqual) {
                    matchesController.removeMatch(match)
                    promise = Em.RSVP.resolve()
                } else {
                    // reload matches to be able to do next action on the match
                    batmask.mask()
                    BD.store.unloadAllByType(Billy.BankLineMatch)
                    promise = matchesController.load()
                }

                return promise
            }).then(function() {
                return matchesController.loadMatchSuggestions()
            }).then(function() {
                return possibleSubjectsController.load()
            }).then(function() {
                batmask.unmask()
                matchesElement.scrollTop = currentScrollTop
            }).catch(function(error) {
                batmask.unmask()
                self.set('hasMatchSuggestion', true)
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SAVE, error.message || t('something_went_wrong'))
            })

        this.get('segment').track('xxx - ml - Bank reconciliation - matching', {
            type: 'automatic_suggestion_accepted'
        })
    },

    autoMatch: function() {
        var matchSuggestions = this.get('matchSuggestions')
        var bankLineId = this.get('bankLineId')

        if (!matchSuggestions || matchSuggestions.length === 0) {
            return null
        }

        return matchSuggestions.filter(function(suggestion) {
            return suggestion.transactions.find(function(transaction) {
                return transaction.id === bankLineId
            })
        }).sort(function(a, b) {
            return a.confidence > b.confidence
        })[0]
    }.property('matchSuggestions'),

    autoMatchViewData: null,

    inlineCreator: null,

    hasInvoiceEditor: Em.computed.equal('inlineCreator', 'invoice'),

    hasBillEditor: Em.computed.equal('inlineCreator', 'bill'),

    hasTransactionEditor: Em.computed.equal('inlineCreator', 'transaction'),

    hasMatchSuggestion: false,

    isDroppable: function() {
        return !this.get('hasMatchSuggestion')
    }.property('hasMatchSuggestion'),

    rejectMatchSuggestion: function() {
        var self = this
        var api = this.get('api')
        var endpointUrl = '/api/matching-service/' + this.get('organization.id') + '/matches/reject'

        api.request('POST', endpointUrl, { accept: 'application/json', payload: this.get('autoMatch') }).catch(function() {
            self.set('hasMatchSuggestion', true)
            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SAVE, t('something_went_wrong'))
        })

        this.get('segment').track('xxx - ml - Bank reconciliation - matching', {
            type: 'automatic_suggestion_rejected'
        })
    },

    getDescriptionFromReconcilable: function(reconcilable) {
        switch (reconcilable.type) {
        case 'bill':
            return t('invoice.description.approved_bill', { billNo: reconcilable.invoice_number, name: reconcilable.contact.name })
        case 'invoice':
            return t('invoice.description.approved_invoice', { invoiceNo: reconcilable.invoice_number, name: reconcilable.contact.name })
        default:
            return reconcilable.description
        }
    },

    observeMatchSuggestions: function() {
        var self = this
        var autoMatch = this.get('autoMatch')
        var subjectAssociationsIds = this.get('model.subjectAssociations.ids')
        var hasSubjectAssociations = subjectAssociationsIds && subjectAssociationsIds.length !== 0
        var hasMatchSuggestion = !!autoMatch && !hasSubjectAssociations

        if (hasMatchSuggestion) {
            var reconcilable = this.get('autoMatch.reconcilables')[0]
            var amountValue = reconcilable.amount
            var numberAmount = Math.abs(amountValue)
            var amountSign = amountValue < 0 ? '-' : '+'
            var amount = {
                value: amountSign + Billy.money(numberAmount),
                color: amountValue <= 0 ? 'red-text' : 'green-text'
            }
            var date = reconcilable.entry_date

            this.set('autoMatchViewData', {
                amount: amount,
                date: date,
                description: self.getDescriptionFromReconcilable(reconcilable)
            })
        }

        this.set('hasMatchSuggestion', hasMatchSuggestion)
    }.observes('matchSuggestions', 'model.subjectAssociations'),

    inlineCreateOptions: function() {
        var options = []
        var lineAmount = this.get('lineAmount')
        if (lineAmount > 0) {
            options.push(Ember.Object.create({
                value: 'invoice',
                name: t('bank_sync.create_invoice')
            }))
        } else if (lineAmount < 0) {
            options.push(Ember.Object.create({
                value: 'bill',
                name: t('bank_sync.create_bill')
            }))
        }
        options.push(Ember.Object.create({
            value: 'transaction',
            name: t('bank_sync.create_transaction')
        }))
        return options
    }.property('lineAmount'),

    invoice: null,

    invoiceIsUploading: false,

    enableInvoiceCreator: function() {
        var entryDate = this.get('model.entryDate') || moment()
        var invoice = Billy.Invoice.createRecord({
            organization: this.get('organization'),
            currency: this.get('account.currency'),
            taxMode: 'incl',
            entryDate: entryDate
        })
        Billy.InvoiceLine.createRecord({
            invoice: invoice
        })
        this.set('invoice', invoice)
    },

    bill: null,

    billIsUploading: false,

    billAccountNatures: function() {
        return [Billy.AccountNature.find('expense'), Billy.AccountNature.find('asset')]
    }.property(),

    taxRatePlaceholder: function() {
        return this.get('organization.hasVat') ? t('bank_sync.select_vat_rate') : t('bank_sync.select_tax_rate')
    }.property('organization.hasVat'),

    getPredictedContraAccount: function() {
        var contraAccounts = this.get('model.lines')
            .map(function(line) { return line.get('contraAccount') })
            .filter(function(contraAccount) { return !!contraAccount })
        return contraAccounts[0]
    },

    enableBillCreator: function() {
        var organization = this.get('organization')
        var entryDate = this.get('model.entryDate') || moment()
        var bill = Billy.Bill.createRecord({
            organization: organization,
            currency: this.get('account.currency'),
            taxMode: 'incl',
            voucherNo: organization.get('hasBillVoucherNo') ? this.get('controllers.nextVoucherNo').getAndIncrement() : null,
            entryDate: entryDate
        })
        Billy.BillLine.createRecord({
            bill: bill,
            description: this.get('lineDescription'),
            account: this.getPredictedContraAccount()
        })
        this.set('bill', bill)
    },

    transaction: null,

    transactionIsUploading: false,

    enableTransactionCreator: function() {
        var organization = this.get('organization')
        var defaultDaybook = organization.get('defaultDaybook')

        if (this.get('account.currency.id') !== organization.get('baseCurrency.id')) {
            this.set('inlineCreator', null)
            this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_TRANSACTION_CREATE, t('bank_sync.transaction_not_allowed_with_foreign_currencies'))
            return
        }
        var transaction = Billy.DaybookTransaction.createRecord({
            organization: organization,
            daybook: defaultDaybook,
            voucherNo: this.get('controllers.nextVoucherNo').getAndIncrement()
        })

        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            text: this.get('lineDescription'),
            account: this.getPredictedContraAccount()
        })
        this.set('transaction', transaction)
    },

    getAllAttachmentsUploadsIds: function() {
        var attachmentsUploadsIds = []
        var attachmentsUploadsObject = this.get('attachmentsUploadsObject')
        var matchIds = Object.keys(attachmentsUploadsObject)

        matchIds.forEach(function(matchId) {
            var attachments = attachmentsUploadsObject[matchId]

            attachments.forEach(function(attachment) {
                attachmentsUploadsIds.push(attachment.id)
            })
        })

        return attachmentsUploadsIds
    },

    updateAttachmentOwnerReference: function(ownerId, ownerType, attachmentId) {
        return this.get('api').putData('/v2/attachments/' + attachmentId, {
            attachment: {
                ownerReference: ownerType + ':' + ownerId,
                ownerId: ownerId
            }
        }).catch(function(error) {
            trackError(error)
        })
    },

    updateAttachments: function(ownerType, attachments) {
        var self = this
        var ownerId

        switch (ownerType) {
        case 'bill':
            ownerId = self.get('bill.id')
            break
        case 'daybookTransaction':
            ownerId = self.get('transaction.id')
            break
        case 'invoice':
            ownerId = self.get('invoice.id')
            break
        }

        return Em.RSVP.Promise.all(attachments.map(function(attachment) {
            var attachmentId = attachment.id
            return self.updateAttachmentOwnerReference(ownerId, ownerType, attachmentId)
        }))
    },

    createSubjectAssociationAndMatch: function(subject) {
        var self = this
        var association = Billy.BankLineSubjectAssociation.createRecord({
            match: this.get('model'),
            subject: subject
        })

        return association.save()
            .then(function() {
                self.set('inlineCreator', null)
                return self.doMatch()
            }, function(e) {
                association.rollback()
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_CREATE, e.message)
            })
    },

    actions: {
        doMatch: function() {
            this.doMatch()
        },

        doAutoMatch: function() {
            this.doAutoMatch()
        },

        doUnapproveMatch: function() {
            this.doUnapproveMatch()
        },

        rejectSuggestion: function() {
            this.set('hasMatchSuggestion', false)
            this.rejectMatchSuggestion()
        },

        createInline: function(inlineCreator) {
            var self = this
            switch (inlineCreator) {
            case 'invoice':
                self.get('subscriptionLimits').checkForInvoiceLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableInvoiceCreator()
                })
                break
            case 'bill':
                self.get('subscriptionLimits').checkForExpenseLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableBillCreator()
                })
                break
            case 'transaction':
                self.get('subscriptionLimits').checkForExpenseLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableTransactionCreator()
                })
                break
            }
        },

        disableInvoiceCreator: function() {
            var invoice = this.get('invoice')
            var attachmentsList = this.get('attachmentsUploads')
            this.get('attachmentsUploads').removeObjects(attachmentsList)
            invoice.rollback()
            this.set('invoice', null)
            this.set('inlineCreator', null)
        },

        saveInvoice: function() {
            if (this.get('invoiceIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var self = this
            var invoice = this.get('invoice')
            var line = invoice.get('lines.firstObject')
            var attachments = this.get('attachmentsUploads')

            if (!invoice.get('entryDate')) {
                invoice.setProperties({
                    entryDate: this.get('entryDate')
                })
            }

            line.setProperties({
                quantity: 1,
                unitPrice: this.get('lineAmount')
            })
            this.get('customEvent').dispatchEvent(CustomEvent.InvoiceUpdated)
            invoice.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            })
                .then(this.createSubjectAssociationAndMatch.bind(this, invoice))
                .then(this.updateAttachments.bind(this, 'invoice', attachments))
                .then(function() {
                    self.get('customEvent').dispatchEvent(CustomEvent.InvoiceCreated)
                    self.get('customEvent').dispatchEvent(CustomEvent.UploadsUpdated)
                })
        },

        didSelectBillContact: function(contact) {
            this.get('bill.lines').forEach(function(line) {
                line.populateContactDefaults()
            })
        },

        didSelectBillAccount: function(account) {
            this.set('bill.lines.firstObject.taxRate', account.get('taxRate'))
        },

        disableBillCreator: function() {
            var bill = this.get('bill')
            var attachmentsList = this.get('attachmentsUploads')
            this.get('attachmentsUploads').removeObjects(attachmentsList)
            this.get('controllers.nextVoucherNo').release(bill.get('voucherNo'))
            bill.rollback()
            this.set('bill', null)
            this.set('inlineCreator', null)
        },

        saveBill: function() {
            if (this.get('billIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var self = this
            var bill = this.get('bill')
            var line = bill.get('lines.firstObject')
            var amount = -1 * this.get('lineAmount')
            var attachments = this.get('attachmentsUploads')

            if (!bill.get('entryDate')) {
                bill.setProperties({
                    entryDate: this.get('entryDate')
                })
            }

            line.setProperties({
                amount: amount
            })
            this.get('customEvent').dispatchEvent(CustomEvent.BillUpdated)
            bill.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            })
                .then(this.createSubjectAssociationAndMatch.bind(this, bill))
                .then(this.updateAttachments.bind(this, 'bill', attachments))
                .then(function() {
                    self.get('customEvent').dispatchEvent(CustomEvent.BillCreated)
                    self.get('customEvent').dispatchEvent(CustomEvent.UploadsUpdated)
                })
        },

        didSelectTransactionAccount: function(account) {
            this.set('transaction.lines.firstObject.taxRate', account && account.get('taxRate'))
        },

        disableTransactionCreator: function() {
            var transaction = this.get('transaction')
            var attachmentsList = this.get('attachmentsUploads')
            this.get('attachmentsUploads').removeObjects(attachmentsList)
            this.get('controllers.nextVoucherNo').release(transaction.get('voucherNo'))
            transaction.rollback()
            this.set('transaction', null)
            this.set('inlineCreator', null)
        },

        saveTransaction: function() {
            if (this.get('transactionIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var self = this
            var match = this.get('model')
            var account = match.get('account')
            var lineAmount = this.get('lineAmount')
            var daybookTransaction = this.get('transaction')
            var line = daybookTransaction.get('lines.firstObject')
            var attachments = this.get('attachmentsUploads')

            // Update daybook transaction and line with properties
            daybookTransaction.setProperties({
                entryDate: this.get('entryDate')
            })
            line.setProperties({
                contraAccount: account,
                amount: Math.abs(lineAmount),
                side: lineAmount < 0 ? postingSides.debit : postingSides.credit
            })
            this.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
            // Save the daybook transaction
            daybookTransaction.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            }).promise
                // Find transactions for the daybook transaction
                .then(function() {
                    return Billy.Transaction.find({
                        organizationId: self.get('organization.id'),
                        originatorReference: daybookTransaction.get('reference')
                    }).promise
                })

                // Find postings for a single transaction
                .then(function(transactions) {
                    if (transactions.get('length') !== 1) {
                        throw new Error('Newly created DaybookTransaction should be the originator of exactly 1 Transaction. ' + transactions.get('length') + ' found')
                    }
                    return transactions.objectAt(0).get('postings').promise
                })

                // Associate and match
                .then(function(postings) {
                    postings = postings.filterBy('account', account)
                    if (postings.get('length') !== 1) {
                        throw new Error('Newly created Transaction for DaybookTransaction should have exactly 1 Posting on the bank account. ' + postings.get('length') + ' found')
                    }
                    return self.createSubjectAssociationAndMatch(postings.objectAt(0))
                })
                .then(this.updateAttachments.bind(this, 'daybookTransaction', attachments))
                .then(function() {
                    self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
                    self.get('customEvent').dispatchEvent(CustomEvent.UploadsUpdated)
                })
        },

        showReceipts: function() {
            var self = this
            var selector = this.container.lookup('component:choose-receipt-modal')
            selector.set('idsToSkipStringified', JSON.stringify(this.getAllAttachmentsUploadsIds()))
            selector.show()

            selector.on('didSelect', function(attachment) {
                self.addAttachment(attachment)
            })
        },

        handleDeleteUploadsAttachment: function(attachmentId) {
            var attachment = this.get('attachmentsUploads').find(function(attachment) {
                return attachment.get('id') === attachmentId
            })

            this.get('attachmentsUploads').removeObject(attachment)
        }
    },

    addAttachment: function(attachment) {
        // Find the attachment and keep the ember instance of it
        var attachmentInstance = Billy.Attachment.find(attachment.id)

        // Check if the instance contain a file; get it of doesn't
        if (!attachmentInstance.get('file')) {
            var fileId = attachment.file.id
            var file = Billy.File.find(fileId)
            attachmentInstance.set('file', file)
        }

        // Get attachmentsUploads for the match
        var matchId = this.get('model.id')
        var attachmentsUploads = this.get('attachmentsUploadsObject')[matchId]

        // Initialize array if it does't exist
        if (!attachmentsUploads) {
            this.get('attachmentsUploadsObject').set(matchId, Em.A([]))
        }

        // Update the array with the attachment instance
        var attachmentsUploadsUpdated = this.get('attachmentsUploadsObject')[matchId]
        attachmentsUploadsUpdated.pushObject(attachmentInstance)
        this.get('attachmentsUploadsObject').set(matchId, attachmentsUploadsUpdated)
        this.notifyPropertyChange('attachmentsUploadsObject')
    },

    didDropLine: function(line) {
        var self = this
        var match = this.get('model')
        var otherMatch = line.get('match')
        var matchesController = this.get('controllers.bankSyncMatches')
        var possibleSubjectsController = this.get('controllers.bankSyncPossibleSubjects')
        var isOnly = otherMatch.get('lines.length') === 1
        var otherMatchSubjects

        // Remove otherMatch from matches controller, and add any subjects to possible subjects, if the line is the only one
        if (isOnly) {
            matchesController.removeMatch(otherMatch, true)
            otherMatchSubjects = otherMatch.get('subjectAssociations').mapBy('subject')
            otherMatchSubjects.forEach(possibleSubjectsController.addSubject.bind(possibleSubjectsController))
        }

        // Add the line to the this match and save
        line.set('match', match)
        line.save()
            .then(function() {
                // If the request succeeded, we delete the otherMatch if the line was the only one
                if (!otherMatch.get('isDestroying') && isOnly) {
                    otherMatch.deleteRecord()
                }
                matchesController.loadMatchSuggestions()
            }, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_LINE_DROP, e.message)
                // If the request failed, we add the otherMatch back and remove the possible subjects again
                line.rollback()
                if (!otherMatch.get('isDestroying') && isOnly) {
                    matchesController.addMatch(otherMatch)
                    otherMatchSubjects.forEach(possibleSubjectsController.removeSubject.bind(possibleSubjectsController))
                }
            })
    },

    didDropSubjectAssociation: function(association) {
        var self = this
        var match = this.get('model')
        association.set('match', match)
        association.save()
            .then(null, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_DROP, e.message)
                association.rollback()
            })
    },

    didDropSubject: function(subject) {
        var self = this
        var match = this.get('model')
        var possibleSubjectsController = this.get('controllers.bankSyncPossibleSubjects')

        possibleSubjectsController.removeSubject(subject)

        var association = Billy.BankLineSubjectAssociation.createRecord({
            match: match,
            subject: subject
        })
        association.save()
            .then(null, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_DROP, e.message)
                association.rollback()
                possibleSubjectsController.addSubject(subject)
            })

        this.get('segment').track('xxx - ml - Bank reconciliation - matching', {
            type: 'manual_association_created'
        })
    },

    doUnapproveMatch: function() {
        var bankApprovedLinesController = this.get('controllers.bankApprovedLines')
        var match = this.get('model')

        bankApprovedLinesController.unapproveMatch(match)
    }
})
