var trackError = require('trackError')
var Window = require('ember-window')

module.exports = Window.extend({

    classNames: ['request-loan-modal'],

    user: null,

    focusSelector: null,

    api: Em.inject.service(),

    userOrganizations: Ember.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    layout: require('../templates/components/request-loan-modal'),

    width: 688,

    invoiceDueDate: null,

    invoiceCurrency: null,

    invoiceAmount: null,

    invoiceId: null,

    invoiceCustomerId: null,

    amountPayout: null,

    infoPayoutAmount: function() {
        return Billy.money(this.get('amountPayout'))
    }.property('amountPayout'),

    actions: {
        applyForLoan: function() {
            var self = this
            var api = self.get('api')
            var baseUrl = '/integrations/agerasfinance/organizations/' + this.get('organization.id')

            var estimateId = this.container.lookup('controller:invoice-index').get('estimateId')
            var userId = this.get('user.id')

            var loadingAnimationModal = self.container.lookup('component:request-loan-loading-modal')

            this.close()
            loadingAnimationModal.show()

            api.post(baseUrl + '/invoice-financing-estimates/' + estimateId + '/actions/approve')
                .catch(function(e) {
                    if (e.code === 409) {
                        trackError('Estimate was already approved')
                    } else {
                        throw e
                    }
                })
                .then(function(response) {
                    // If there is a token at a response it's a sign that KYC is not done. Let's do it now!
                    if (response.token) {
                        var trailingURL = 'customer_id=' + self.get('organization.id') + '&user_id=' + userId

                        var url = ENV.AGERAS_FINANCE_FRONTEND_URL + '/invoice-financing?' + trailingURL + '#token=' + response.token.accessToken
                        window.location.replace(url)
                    } else {
                        loadingAnimationModal.close()

                        var modal = self.container.lookup('component:request-loan-success-modal')
                        modal.show()
                    }
                })
        }
    }
})
