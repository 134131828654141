module.exports = Em.Object.extend({
    userOrganizations: Em.inject.service(),

    getDashboardUrl: function(organizationName) {
        var organizationUrl = organizationName || this.get('userOrganizations.lastOrganizationUrl')
        return '/' + organizationUrl + '/dashboard'
    },

    /* Regular link behaviour: go to /dashboard with adding this to the history list */
    goToDashboard: function(organizationName, queryParams) {
        var url = this.getDashboardUrl(organizationName)
        this.container.lookup('router:main').transitionTo(url, { queryParams: queryParams })
    },

    /* Redirection to /dashboard with replacing current path in history list */
    replaceUrlWithDashboard: function(organizationName, queryParams) {
        var url = this.getDashboardUrl(organizationName)
        this.container.lookup('router:main').replaceWith(url, { queryParams: queryParams })
    }
})
