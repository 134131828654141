var invoiceTotalHelper = require('../utils/invoice-total-helper')
var i18n = require('i18n')
var t = i18n.t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    html: null,

    htmlId: null,

    resolvedLocale: function() {
        return this.get('contact.locale.id') || this.get('organization.locale.id')
    }.property(),

    segment: Em.inject.service(),

    getDocumentUrl: function(type) {
        var invoice = this.get('model')
        var host = this.container.lookup('adapter:application').host
        return host + '/organizations/' + invoice.get('organization.id') + '/recurringInvoices/' + invoice.id + '.' + type
    },

    getDocumentHeaders: function() {
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return {
            Authorization: 'Bearer ' + accessToken,
            'Accept-Language': this.get('resolvedLocale')
        }
    },

    loadHtml: function() {
        var self = this
        var invoiceId = this.get('model.id')

        // If the currently displayed HTML does not belong to the recurring invoice we are going to display, then clear the HTML right away
        if (this.get('htmlId') !== invoiceId) {
            this.set('html', '')
                .set('htmlId', null)
        }

        $.ajax({
            type: 'GET',
            url: this.getDocumentUrl('html'),
            headers: this.getDocumentHeaders()
        })
            .then(function(payload) {
                Em.run(function() {
                    self.set('html', payload)
                        .set('htmlId', invoiceId)
                })
            }, function() {
                Em.run(function() {
                    // TODO: Translation
                    self.set('html', 'Could not load recurring invoice document at this moment.')
                })
            })
    },

    showAlert: function() {
        return this.get('hasError') || !this.get('amountIsPositive') || this.get('onetimeLines.length')
    }.property('hasError', 'amountIsPositive', 'onetimeLines'),

    onetimeLines: function() {
        var no = 0
        return this.get('model.lines').reduce(function(memo, line) {
            no++
            if (line.get('isOnetime')) {
                memo.push({
                    no: no,
                    line: line
                })
            }
            return memo
        }, [])
    }.property('model.lines.@each.isOnetime'),

    calculatedTotal: function() {
        return invoiceTotalHelper(this.get('model'), this.get('model.lines'))
    }.property('model.taxMode', 'model.lines.@each.{quantity,unitPrice,discountMode,discountValue,currentTaxRate,taxRate.name}'),

    amountIsPositive: function() {
        return this.get('calculatedTotal.netAmount') > 0
    }.property('calculatedTotal.netAmount'),

    spawnedInvoices: function() {
        var recurring = this.get('model')
        return Billy.Invoice.findByQuery({
            organizationId: recurring.get('organization.id'),
            recurringInvoiceId: recurring.get('id'),
            sortProperty: 'entryDate',
            sortDirection: 'DESC'
        })
    }.property('model.id'),

    hasError: function() {
        return !!(this.get('spawnErrorCode') || this.get('sendErrorCode'))
    }.property('model.{spawnErrorCode,sendErrorCode}'),

    actions: {
        backToList: function() {
            this.transitionToRoute('recurring_invoices.index')
        },

        delete: function() {
            var self = this
            var invoice = this.get('model')
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_recurring_invoice_viewed',
                action: 'more: delete'
            })
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('recurring_invoice.index.confirm_delete'), t('yes_delete'))
                .then(function() {
                    return invoice.destroyRecord()
                })
                .then(function() {
                    self.get('segment').track('Recurring Invoice Deleted (Client)')
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.RECURRING_INVOICE_DELETE, t('delete_confirmation'))
                    self.replaceRoute('recurring_invoices')
                })
        },
        editRecurringInvoice: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_recurring_invoice_viewed',
                action: 'edit recurring invoice'
            })
            this.transitionTo('recurring_invoice.edit')
        }
    }
})
