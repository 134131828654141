var annualReportData = require('../data/annual-report')
var batmask = require('batmask')

module.exports = require('./abstract-annual-report').extend({
    annualReport: Em.inject.service(),

    organizationState: Em.inject.service(),

    api: Em.inject.service(),

    cvr: Em.inject.service(),

    dashboardNavigator: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    /**
     * @param {AnnualReportStepEnum} step
     */
    saveProgress: function(step) {
        var organization = this.get('organization')
        var organizationSettings = organization.get('settings')
        var stepOrder = this.get('annualReport').getStepOrder()
        var stepIndex = stepOrder.indexOf(step)
        if (stepIndex < 0) {
            return
        }

        var progress = stepIndex === 0 ? null : Math.round(((stepIndex + 1) / stepOrder.length) * 100)
        organizationSettings.set('annualReportProgress', progress + '%')
    },

    /**
     * @param {string} year
     * @returns {Promise<boolean>}
     */
    checkIfPackageBought: function(year) {
        return this.get('organizationState')
            .getStateValue('bought:accountingPackage:' + year)
            .then(function(value) {
                return value === 'true'
            })
            .catch(function() {
                return false
            })
    },

    /**
     * @param {AnnualReportYear[]} years
     * @returns {number}
     */
    getMaxYear: function(years) {
        var availableMax = 0
        var totalMax = 0
        years.forEach(function(year) {
            var yearNumber = parseInt(year.year)
            if (year.isAvailable) {
                availableMax = Math.max(availableMax, yearNumber)
            }

            totalMax = Math.max(totalMax, yearNumber)
        })

        return availableMax || totalMax
    },

    isStaggeredYearByOrganization: function(commitment) {
        var organization = this.modelFor('organization')
        var fiscalYear = this.get('annualReport').getFiscalYearForYear(
            commitment.year, organization.get('firstFiscalYearStart'), organization.get('firstFiscalYearEnd')
        )

        // fiscal year is complete and fiscal year does not end in December
        return fiscalYear.endDate.isBefore(moment.utc()) && fiscalYear.endDate.month() !== 11
    },

    isStaggeredYearByCvr: function(cvrData) {
        var fiscalYearEndCvr = cvrData.fiscalYearEnd.split('-')
        var fiscalYearEndMonth = parseInt(fiscalYearEndCvr[0], 10)
        var fiscalYearEndDay = parseInt(fiscalYearEndCvr[1], 10)

        var fiscalYearEndDate = cvrData.firstFiscalYearEnd
        var fiscalYearEndDayOfMonth = moment(fiscalYearEndDate).endOf('month').date()

        var fiscalYearEndsOnMonthEnd = fiscalYearEndDayOfMonth === fiscalYearEndDay
        var fiscalYearEndsOnYearEnd = fiscalYearEndDay === 31 && fiscalYearEndMonth === 12
        // fiscal year does not end on month's last day and
        return !fiscalYearEndsOnMonthEnd && !fiscalYearEndsOnYearEnd
    },

    /**
     * @param {AnnualReportModel} commitment
     * @param cvrData
     * @returns {boolean}
     */
    isStaggeredYear: function(commitment, cvrData) {
        var isDateStaggered = cvrData.fiscalYearEnd ? this.isStaggeredYearByCvr(cvrData) : this.isStaggeredYearByOrganization(commitment)
        // Disables reporting for staggered fiscal years
        if (
            isDateStaggered &&
            // report not yet started or invoiced
            (commitment.state === 'inactive' || !commitment.invoiceId)
        ) {
            return true
        }

        return false
    },

    /**
     * @param {string} year
     * @returns {Promise<AnnualReportModel>}
     */
    bootstrapAnnualReport: function(year) {
        var self = this
        var annualReportService = this.get('annualReport')
        var organization = this.get('organization')
        var activeStep = annualReportService.get('activeStep')
        var isInvalidRegistrationNo = annualReportService.isInvalidRegistrationNo()
        var cvrData = null
        var arSubscription = null
        var isCompletePlan = organization.get('isCompletePlan')

        return this.get('cvr').ensureLoaded()
            .then(function(cvr) {
                cvrData = cvr

                return annualReportService.getAnnualReportSubscription()
            })
            .then(function(subscription) {
                arSubscription = subscription
                return annualReportService.getYears()
            })
            .then(function(model) {
                if (year === '0') {
                    var maxYear = self.getMaxYear(model.years)
                    self.transitionTo('annual_report.start', maxYear)
                }
                var commitment = _.find(model.years, { year: _.toInteger(year) })
                var isCommitmentInactive = commitment.state === 'inactive'
                var isCommitmentAvailable = commitment.isAvailable

                if ((isCompletePlan || arSubscription) && isCommitmentInactive && isCommitmentAvailable && !isInvalidRegistrationNo) {
                    return annualReportService
                        .commit(year, organization.get('registrationNo'))
                        .then(function() {
                            self.transitionTo('annual_report.checklist', year)
                        })
                }

                if (isInvalidRegistrationNo) {
                    return Em.RSVP.hash({
                        years: model.years,
                        commitment: null
                    })
                } else {
                    return Em.RSVP.hash({
                        years: model.years,
                        commitment: commitment.state === 'inactive'
                            ? null
                            : annualReportService.getCommitment(commitment.id)
                    })
                }
            })
            .then(function(model) {
                var data = model.commitment
                    ? model.commitment.data
                    : {}

                var rawCommitment = model.years.find(function(yearInformation) {
                    return '' + yearInformation.year === '' + year
                })

                var commitment = Object.assign({ completion: {} }, data, rawCommitment, model.commitment)
                var steps = annualReportService.getSteps(commitment)
                var currentStep = annualReportService.getCurrentStep(commitment)
                var isStaggeredYear = self.isStaggeredYear(commitment, cvrData)
                var isPackageBought = self.get('isPackageBought')
                var queryParams = {}

                if (isInvalidRegistrationNo) {
                    currentStep = 'start'
                }

                if (isPackageBought) {
                    currentStep = 'package_bought'
                }

                return {
                    years: model.years,
                    steps: steps,
                    activeStep: activeStep,
                    currentStep: currentStep,
                    settings: organization,
                    commitment: commitment,
                    queryParams: queryParams,
                    isStaggeredYear: isStaggeredYear
                }
            })
    },

    /**
     * @param {EmberTransition} transition
     * @returns {Promise<void>}
     */
    beforeModel: function(transition) {
        this._super(transition)
        var self = this
        if (!this.get('organization.companyType')) {
            this.transitionTo('annual_report.start')
        }

        // show loading only when navigating in app, not on app load, this is covered by loading screen
        if (transition.urlMethod === 'update' && transition.targetName === 'annual_report.index') {
            self.set('containerEl', $('.user-main'))
            batmask.mask(self.get('containerEl'), 0, true)
        }

        var isAvailable = this.get('annualReport').isAvailable()

        if (!isAvailable) {
            this.get('dashboardNavigator').replaceUrlWithDashboard()
        }
    },

    /**
     * @param {{year?: string}} params
     * @returns {Promise<AnnualReportModel>}
     */
    model: function(params) {
        var self = this
        return Em.RSVP
            .resolve(self.checkIfPackageBought(params.year))
            .then(
                /** @param {boolean} isPackageBought */
                function(isPackageBought) {
                    self.set('isPackageBought', isPackageBought)

                    return self.bootstrapAnnualReport(params.year)
                }
            )
            .catch(function() {
                return self.bootstrapAnnualReport(params.year)
            })
    },

    /**
     * @param {AnnualReportModel} model
     */
    afterModel: function(model) {
        if (model) {
            this.saveProgress(model.currentStep)
        }

        if (window.Intercom) {
            window.Intercom('update')
        }
    },

    /**
     * @param {AnnualReportStepEnum} route
     * @returns {boolean}
     */
    isRevisedDesignRoute: function(route) {
        return annualReportData.revisedDesignRoutes.includes(route)
    },

    isChecklistStepActive: function() {
        var activeStep = this.get('annualReport').get('activeStep')
        return activeStep === 'checklist'
    },

    /**
     * @param {Ember.Controller} controller
     * @param {AnnualReportModel} model
     * @param {EmberTransition} transition
     * @returns {void}
     */
    setupController: function(controller, model, transition) {
        var annualReportService = this.get('annualReport')
        var activeStep = annualReportService.get('activeStep')
        var stepOrder = annualReportService.getStepOrder()
        var currentStep = model && model.currentStep ? model.currentStep : stepOrder[0]
        var previousStep = annualReportService.getPreviousStep(activeStep)
        var stepSettings = annualReportService.getStepSettings(activeStep)
        var currentYear = controller.get('model.commitment.year')
        var nextYear = parseInt(transition.params.annual_report.year)
        var queryParams = model.queryParams || {}

        // All the conditions that make us force the browser to redirect to the current annual report step (as
        // opposed to the "active" step in the localhost)
        if (
            // - We have flagged the step so that we are not allowed to revisit it at any point once it has been completed
            (currentStep !== activeStep && !stepSettings.canBeRevisited) ||
            // - If the active step does not exist (for example if someone tried to navigate through URL)
            (!annualReportData.allSteps.includes(activeStep)) ||
            // - If we're trying to navigate to a step after the finished one
            (previousStep && !this.get('annualReport').isStepCompleted(previousStep, model.commitment)) ||
            // - If we're trying to navigate back to another step even though we already submitted the ar
            (currentStep !== activeStep && this.get('annualReport').isSubmittedState(model.commitment.state)) ||
            // - If we're trying to navigate between years (switch tabs)
            (currentYear !== nextYear)
        ) {
            // ...then we force transition to the currentStep of the report
            // Also, we set the active step at this point. We could wait for it to be filled after the transition, but
            // why wait? If we set it now, we save ourselves a few milliseconds of flicker.
            this.get('annualReport').set('activeStep', currentStep)
            this.transitionTo('annual_report.' + currentStep, { queryParams: queryParams })
        }

        this._super(controller, model, transition)

        controller.set('model', model)
        controller.set('revisedDesign', this.isRevisedDesignRoute(currentStep))
    },

    actions: {
        didTransition: function() {
            // We do this because controllers do not receive the didTransition hook. We're simply passing it forward
            this.controllerFor('annual-report').send('didTransition')

            // The batmask is removed every time a transition is made to another step except for the checklist step
            // The checklist controller will handle the mask because it's the step where the payment callback is made
            // Removing the mask here would interfere with the control of the mask made by the controller
            if (!this.isChecklistStepActive()) {
                batmask.unmask(this.get('containerEl'))
            }
        },

        // It is important that the model is invalidated here and not in abstract-annual-report - the source
        // of truth for the main model is this route, annual-report.js (look at model/setupController). All
        // other routes use modelFor('annual-report'). So do not move this to another route
        invalidateModel: function() {
            this.refresh()
        },

        /**
         * @param {AnnualReportStepEnum} step
         */
        progressUpdate: function(step) {
            this.saveProgress(step)
        }
    }
})
