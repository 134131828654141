var trackError = require('trackError')
var t = require('i18n').t
var f = require('float') // TODO: Shouldn't we use bignumber.js instead?
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.ObjectController.extend({
    needs: ['organization'],

    api: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    selected: false,

    focused: false,

    organization: Em.computed.alias('controllers.organization.model'),

    lines: function() {
        var lines = []
        // we just want a raw array
        this.get('model.lines').forEach(function(line) {
            lines.pushObject(line)
        })

        lines = lines.sortBy('priority')

        return Em.ArrayController.create({
            container: this.container,
            target: this,
            parentController: this,
            model: lines,
            itemController: 'daybook-edit-transaction-line'
        })
    }.property('model.lines'),

    attachmentsPopover: null,

    validate: function() {
        var self = this
        var isValid = true
        var fields = [
            'entryDate'
        ]
        fields.forEach(function(k) {
            if (Em.isEmpty(self.get(k))) {
                self.set('errors.' + k, t('required_field'))
                isValid = false
            }
        })

        this.get('lines').forEach(function(c) {
            if (!c.validate()) {
                isValid = false
            }
        })

        if (isValid) {
            if (!this.get('doesBalance')) {
                isValid = false
                this.set('error', t('daybook.index.unbalanced_transaction'))
            }
        }

        return isValid
    },

    save: function() {
        var self = this
        if (this.savePromise) {
            this.saveAgain = true
            return this.savePromise
        }
        this.savePromise = this.get('model').save({
            embed: ['lines', 'attachments']
        })
            .then(null, function(e) {
                // If the save failed, we simply warn the user that something wasn't saved properly
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_SAVE, e.message || e)
            })
            .finally(function() {
                delete self.savePromise
                if (self.saveAgain) {
                    self.saveAgain = false
                    return self.save()
                }
            })
        return this.savePromise
    },

    updateAttachmentOwnerReference: function(daybookTransactionId, attachmentId) {
        var self = this

        return this.get('api').putData('/v2/attachments/' + attachmentId, {
            attachment: {
                ownerReference: 'daybookTransaction:' + daybookTransactionId,
                ownerId: daybookTransactionId
            }
        }).catch(function(error) {
            trackError(error)
            self.container.lookup('util:notification').notify(
                NOTIFICATION_KEYS.OWNER_REFERENCE_UPDATE,
                t('notification_settings.daybooks.update_attachment_owner_reference_error'),
                NOTIFICATION_VARIANT.ERROR
            )
        })
    },

    stealAttachment: function(attachment) {
        var attachmentId = attachment.id
        var daybookTransactionId = this.get('id')
        var customEventService = this.get('customEvent')
        this.updateAttachmentOwnerReference(daybookTransactionId, attachmentId)
            .then(function() {
                // Next action updates attachments count and attachments list automatically
                Billy.Attachment.find(attachmentId)
                // Use Custom events API to notify about updates(React)
                customEventService.dispatchEvent(CustomEvent.UploadsUpdated)
            })
    },

    // Returns an object with a key for each account, which is an object with `name` (name of the account) and `movement` (a `Billy.SideAmount` instance) keys
    // Example: {'account1234': {name: 'Some account', movement: new Billy.SideAmount(100, 'debit')}}
    accountMovements: function() {
        var movements = {}
        var organization = this.get('organization')
        if (!organization) {
            return movements
        }
        var type = this.get('type')
        this.get('lines').forEach(function(line) {
            var side = line.get('side')
            if (!side) {
                return
            }

            var contraAccount = line.get('contraAccount')
            var opposite = line.get('side.opposite')
            var grossAmount = line.get('amount')

            if (type === 'entry') {
                var account = line.get('account')

                // Make sure the account comes before the tax account
                if (account) {
                    applyMovement(movements, account, 0, side)
                }

                var taxRate = line.get('taxRate')
                var accountAmount = grossAmount // tax will/might be subtracted from this amount

                if (taxRate) {
                    var tax = taxRate.calcTaxFromGrossAmount(grossAmount)
                    var netAmount = f.round(grossAmount - tax)
                    taxRate.get('deductionComponents').forEach(function(deductionComponent) {
                        var sourceAmount = deductionComponent.get('source') === 'tax' ? tax : netAmount
                        var deductionTax = f.round(sourceAmount * deductionComponent.get('share'))
                        var deductionAccount = deductionComponent.get('account')
                        applyMovement(movements, deductionAccount, deductionTax, side)
                        accountAmount = f.round(accountAmount - deductionTax)
                    })
                }

                if (account) {
                    applyMovement(movements, account, accountAmount, side)
                }
            } else if (type === 'invoicePayment' || type === 'externalInvoicePayment') {
                applyMovement(movements, Billy.Account.findBySystemRole(organization, 'accountsReceivable'), grossAmount, side)
            } else if (type === 'billPayment') {
                applyMovement(movements, Billy.Account.findBySystemRole(organization, 'accountsPayable'), grossAmount, side)
            }

            if (contraAccount) {
                applyMovement(movements, contraAccount, grossAmount, opposite)
            }
        })
        return movements
    }.property('type', 'lines.@each.{amount,side,account,contraAccount,taxRate}'),

    doesBalance: function() {
        var movements = this.get('accountMovements')
        var balance = new Billy.SideAmount()
        for (var accountId in movements) {
            if (Object.prototype.hasOwnProperty.call(movements, accountId)) {
                balance = balance.add(movements[accountId].movement)
            }
        }
        return balance.isZero()
    }.property('accountMovements'),

    typeOptions: function() {
        var types = ['entry']
        var organization = this.get('organization')
        var isOrganizationWithoutCashBasedAccounting = !organization.get('hasCashBasedAccounting')

        if (isOrganizationWithoutCashBasedAccounting) {
            // decide between invoice and external invoice
            var invoicePaymentType = organization.get('isSourceZervant') ? 'externalInvoicePayment' : 'invoicePayment'
            types.push(invoicePaymentType, 'billPayment')
        }

        return types.map(function(type) {
            return Em.O({
                value: type,
                shortName: t('daybook_transaction.type_short.' + Em.String.underscore(type)),
                name: t('daybook_transaction.type.' + Em.String.underscore(type))
            })
        })
    }.property(),

    compoundError: function() {
        return [this.get('error')]
            .concat(this.get('lines').mapBy('error'))
            .filter(function(error) {
                return !!error
            })
            .join('\n')
    }.property('error', 'lines.@each.error'),

    typeDidChange: function() {
        var type = this.get('type')
        this.get('lines').forEach(function(line) {
            if (type !== 'entry') {
                line.set('account', null)
                line.set('taxRate', null)
            }
            if (type !== 'invoicePayment') {
                line.set('paidInvoice', null)
            }
            if (type !== 'externalInvoicePayment') {
                line.set('paidExternalInvoice', null)
            }
            if (type !== 'billPayment') {
                line.set('paidBill', null)
            }
        })
    }.observes('type'),

    actions: {
        addLine: function(lineController) {
            var organization = this.get('organization')
            var lines = this.get('lines')
            var transaction = this.get('model')
            var priority = (lines.get('lastObject.priority') || 0) + 1
            var currency = lines.get('lastObject.currency') || organization.get('baseCurrency')
            var text = lineController.get('text')
            if (text === undefined) {
                var closestLineNode = document.activeElement && document.activeElement.closest('.daybook-edit-transaction-line')
                var closestLineNodeText
                if (closestLineNode) {
                    closestLineNodeText = closestLineNode.querySelector('[data-name=description-text-field] input').value
                }
                text = closestLineNodeText || lines.get('firstObject.text')
            }
            var line = Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: transaction,
                contraAccount: transaction.get('daybook.defaultContraAccount'),
                currency: currency,
                priority: priority,
                text: text
            })
            lines.get('content').pushObject(line)
            this.save()
        },

        deleteLine: function(lineController) {
            var line = lineController.get('content')
            var lines = this.get('lines')
            if (lines.get('length') === 1) {
                this.send('deleteTransaction', this)
            } else {
                line.deleteRecord()
                lines.get('content').removeObject(line)
                this.save()
            }
        },

        duplicateLine: function() {
            this.send('duplicateTransaction', this)
        }
    }
})

function applyMovement(movements, account, amount, side) {
    var accountId = account.get('id')
    if (movements[accountId]) {
        movements[accountId].movement = movements[accountId].movement.add(amount, side)
    } else {
        movements[accountId] = {
            name: account.get('name'),
            movement: new Billy.SideAmount(amount, side)
        }
    }
}
