var absoluteRouteUrl = require('../utils/absolute-route-url')
var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    queryParams: [
        'payment_cancelled',
        'payment_successful',
        'payment_failed',
        'message',
        'activate'
    ],

    needs: ['organization', 'user'],

    organization: Em.computed.alias('controllers.organization.model'),

    user: Em.computed.alias('controllers.user.model'),

    organizationSubscription: Em.inject.service(),

    subscription: Em.inject.service(),

    startGuide: Em.inject.service(),

    coupons: Em.inject.service(),

    dashboardNavigator: Em.inject.service(),

    couponOffer: null,

    couponInputSelected: true,

    couponCode: null,

    couponInfo: null,

    trialDays: function() {
        var startDate = this.get('organization.createdTime')
        var endDate = this.get('organization.isTrial')
            ? this.get('organization.trialExpiryDate') || this.get('organization.subscriptionExpires')
            : this.get('organization.trialExpiryDate')

        return endDate
            ? moment(endDate).diff(moment(startDate).startOf('day'), 'days')
            : 30
    }.property('organization.{createdTime,subscriptionExpires,trialExpiryDate,isTrial}'),

    premiumMonthlyPrice: function() {
        if (this.get('organizationSubscription.isLoaded')) {
            return this.get('organizationSubscription').getSubscriptionPriceMonthly('premium')
        }

        return 0
    }.property('organizationSubscription.isLoaded'),

    premiumMonthlyPriceYearly: function() {
        if (this.get('organizationSubscription.isLoaded')) {
            return this.get('organizationSubscription').getSubscriptionPriceMonthly('premium', true)
        }

        return 0
    }.property('organizationSubscription.isLoaded'),

    isCouponRedeemed: function() {
        return this.get('organization.couponRedeemed')
    }.property('organization.couponRedeemed'),

    couponCodeIsEmpty: function() {
        return !this.get('couponCode')
    }.property('couponCode'),

    trialEndDate: function() {
        var subscriptionExpires = this.get('organization.subscriptionExpires')
        return subscriptionExpires && subscriptionExpires.format('LL')
    }.property('organization.subscriptionExpires'),

    isBasic: function() {
        return this.get('organization.subscriptionPlan') === 'basic'
    }.property('organization.subscriptionPlan'),

    setupCouponInputSelected: function() { // TODO: Can be moved to setupController if `organization.couponRedeemed` is not needed.
        this.set('couponInputSelected',
            this.get('isCouponOfferAvailable') &&
            !this.get('organization.couponRedeemed')
        )
    }.observes('isCouponOfferAvailable', 'organization.couponRedeemed'),

    isCouponInputToggleVisible: function() {
        return (
            this.get('isCouponOfferAvailable') &&
            !this.get('organization.couponRedeemed')
        )
    }.property('isCouponOfferAvailable', 'organization.couponRedeemed'),

    actions: {
        enterPaymentDetails: function() {
            var self = this

            // We want to track whenever a company initiates the action of
            // entering their credit card details. The tracking is to
            // clarify the intent, and not necessarily if they succesfully
            // entered their credit card.
            this.get('startGuide').completeStep()
                .then(function() {
                    var organization = self.get('organization')
                    var subscription = self.get('subscription')
                    var continueUrl = absoluteRouteUrl(self.container, 'startguide.payment_details', organization)
                    var cancelUrl = absoluteRouteUrl(self.container, 'startguide.payment_details', organization)

                    return subscription.subscribeCard({
                        continueUrl: continueUrl,
                        cancelUrl: cancelUrl,
                        saveHistory: 'saveHistory'
                    })
                })
        },

        // Called from route, when coming back from Quickpay with a successful payment method added
        onPaymentDetailsSuccess: function() {
            this.get('organization.settings').set('setupGuidePaymentDetails', '1')
            var organizationUrl = this.get('organization.url')
            this.get('dashboardNavigator').goToDashboard(organizationUrl)
        },

        showSubscriptionsOverviewModal: function() {
            this.get('constraints').showUpgradeOverlay('', 'startguide_payment_details')
        },

        toggleCouponInput: function() {
            this.toggleProperty('couponInputSelected')
        },

        redeemCoupon: function() {
            var self = this
            var coupons = this.get('coupons')
            var organization = this.get('organization')

            batmask.maskDelayed()

            coupons.redeemCoupon(this.get('organization.id'), this.get('couponCode'))
                .then(function() { // coupon is valid and redeemed
                    batmask.unmask()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('startguide_payment_details.valid_coupon_code'))

                    if (!organization.get('isSubscriptionPlanPaid')) {
                        // upgrade user to premium
                        self.get('organizationSubscription').updateOrganizationSubscription('premium', 'monthly')
                            .then(function() {
                                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.upgraded_to_premium'))
                            })
                    }
                })
                .catch(function() { // invalid coupon
                    batmask.unmask()
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, t('startguide_payment_details.invalid_coupon_code'))
                })
        },

        skip: function() {
            var organizationUrl = this.get('organization.url')
            this.get('dashboardNavigator').goToDashboard(organizationUrl)
        }
    }
})
