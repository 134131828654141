var invoiceTotalHelper = require('../utils/invoice-total-helper')
var isDkApplicable = require('../utils/is-dk-applicable')
var t = require('i18n').t
var updateInvoiceLineTaxRate = require('../utils/invoice-line/update-tax-rate')
var RecurringIntervalSerializer = require('../mixins/recurring-interval-serializer')
var Scope = require('../utils/scope')

module.exports = Em.Component.extend(RecurringIntervalSerializer, {
    classNames: ['invoice-editor', 'section-body-content'],

    templates: Ember.inject.service(),

    auth: Ember.inject.service(),

    segment: Em.inject.service(),

    paymentMethods: Ember.inject.service(),

    invoice: null,

    organization: Em.computed.alias('invoice.organization'),

    templateSelector: true,

    standardAttachment: null,

    preselectedTemplate: null,

    isInvoiceAttachmentEnabled: null,

    isInvoiceModel: function() {
        return this.get('invoice') instanceof Billy.Invoice
    }.property('invoice'),

    isQuoteModel: function() {
        return this.container.lookupFactory('model:quote').detectInstance(this.get('invoice'))
    }.property('invoice'),

    isRecurringInvoiceModel: function() {
        return this.container.lookupFactory('model:recurring-invoice').detectInstance(this.get('invoice'))
    }.property('invoice'),

    showTaxableAmount: function() {
        return isDkApplicable(this.get('organization.country.id'))
    }.property('organization.hasVat'),

    typeIsInvoice: Em.computed.equal('invoice.type', 'invoice'),

    typeIsCreditNote: Em.computed.equal('invoice.type', 'creditNote'),

    hasHeaderMessage: Em.computed.or('isQuoteModel', 'isRecurringInvoiceModel'),

    errors: function() {
        return DS.Errors.create()
    }.property(),

    lines: Em.computed.alias('invoice.lines'),

    sortedLines: function() {
        return this.get('invoice.lines').sortBy('priority')
    }.property('invoice.lines.@each.priority'),

    isCreditedInvoiceDisabled: function() {
        var invoice = this.get('invoice')
        return !(invoice.get('contact') && invoice.get('isNew'))
    }.property('invoice.{contact,isNew}'),

    creditedInvoicesQuery: function() {
        var invoice = this.get('invoice')
        return {
            organization: invoice.get('organization'),
            contact: invoice.get('contact'),
            type: 'invoice',
            state: 'approved'
        }
    }.property('invoice.{organization,contact}'),

    isContactDisabled: function() {
        return this.get('invoice.state') === 'approved'
    }.property('invoice.state'),

    isCurrencyFieldDisabled: function() {
        if (this.get('invoice.isCreditNote') && !this.get('invoice.creditedInvoice')) {
            return false
        }
        return this.get('invoice.isApproved') || this.get('invoice.isCreditNote')
    }.property('invoice.isApproved', 'invoice.isCreditNote', 'invoice.creditedInvoice'),

    isInvoiceNoEditable: function() {
        return (this.get('invoice.state') !== 'draft' || this.get('organization.invoiceNoMode') === 'manual')
    }.property('invoice.state', 'organization.invoiceNoMode'),

    isInvoiceNoDisabled: function() {
        return this.get('invoice.state') !== 'draft'
    }.property('invoice.state'),

    tentativeNextInvoiceNo: function() {
        return this.get('organization.nextInvoiceNo')
    }.property('organization.nextInvoiceNo'),

    shouldShowFinancingBanner: function() {
        var shouldShowFinancing = this.get('shouldShowFinancing')

        return (this.get('invoice.state') === 'draft' || this.get('invoice.isNew')) && this.get('invoice.type') === 'invoice' && shouldShowFinancing
    }.property('invoice.state', 'invoice.isNew'),

    updatePaymentTerms: function() {
    // Only do this for invoices and recurring invoices
        if (!((this.get('isInvoiceModel') && this.get('invoice.type') === 'invoice') || this.get('isRecurringInvoiceModel'))) {
            return
        }
        var invoice = this.get('invoice')
        var contact = invoice.get('contact')
        var contactMode = contact && contact.get('paymentTermsMode')
        if (contactMode) {
            invoice.set('paymentTermsMode', contactMode)
                .set('paymentTermsDays', contact.get('paymentTermsDays'))
                .set('dueDate', null)
        } else {
            var organization = invoice.get('organization')
            invoice.set('paymentTermsMode', organization.get('paymentTermsMode'))
                .set('paymentTermsDays', organization.get('paymentTermsDays'))
                .set('dueDate', null)
        }
    },

    initPaymentTermsIfNew: function() {
        var invoice = this.get('invoice')
        if (invoice.get('isNew') && !invoice.get('paymentTermsMode')) {
            this.updatePaymentTerms()
        }
    }.on('init'),

    updateMessagesOnInit: function() {
        this.updateMessages()
    }.on('init').observes('invoice'),

    updateMessages: function(quoteTypeChanged) {
        var self = this
        var templates = this.get('templates')
        templates.ensureLoaded()
            .then(function() {
                var tplVars = templates.variablesForInvoice(self.get('invoice'))
                if (tplVars) {
                    var invoice = self.get('invoice')
                    var type = invoice.get('type')

                    if (quoteTypeChanged) {
                        invoice.set('headerMessage', tplVars[type + 'HeaderMessage'])
                        invoice.set('footerMessage', tplVars[type + 'FooterMessage'])
                    } else {
                        invoice.set('headerMessage', invoice.get('headerMessage') || tplVars[type + 'HeaderMessage'])
                        invoice.set('footerMessage', invoice.get('footerMessage') || tplVars[type + 'FooterMessage'])
                    }

                    invoice.get('isNew') && self.set('standardAttachment', tplVars.standardAttachmentUrl && Billy.File.createRecord({
                        fileName: tplVars.standardAttachmentUrl.replace(/^.*[\\\/]/, ''),
                        downloadUrl: tplVars.standardAttachmentUrl
                    }))
                } else {
                    self.set('standardAttachment', null)
                }
                self._lastTemplateVars = tplVars
            })
    },

    initMessagesIfNew: function() {
        var invoice = this.get('invoice')
        if (invoice.get('isNew') && Em.isEmpty(invoice.get('headerMessage')) && Em.isEmpty(invoice.get('footerMessage'))) {
            this.updateMessages()
        }
    }.on('init'),

    isAttContactPersonDisabled: Em.computed.not('invoice.contact'),

    attContactPersonPlaceholder: function() {
        if (this.get('invoice.recurringSpawnMode') === 'email') {
            return t('invoice.edit.contact_person_placeholder_required')
        } else {
            return t('invoice.edit.contact_person_placeholder')
        }
    }.property('invoice.recurringSpawnMode'),

    contactPersonsQuery: function() {
        var query = {}
        var contact = this.get('invoice.contact')
        if (contact) {
            query.contact = contact
        }
        return query
    }.property('invoice.contact'),

    quoteTypeOptions: function() {
        return [
            Em.Object.create({
                name: t('quote.type.capitalized.quote'),
                value: 'quote'
            }),
            Em.Object.create({
                name: t('quote.type.capitalized.estimate'),
                value: 'estimate'
            }),
            Em.Object.create({
                name: t('quote.type.capitalized.order_confirmation'),
                value: 'orderConfirmation'
            })
        ]
    }.property(),

    checkIfInvoiceAttachmentEnabled: function() {
        var scopes = [Scope.InvoiceAttachmentWrite]
        // @todo doubled scopes-check
        var isAuthorizedToUploadAttachments = this.get('auth').isAuthorized(scopes, false)

        this.set('isInvoiceAttachmentEnabled', isAuthorizedToUploadAttachments)
    }.on('init'),

    recurringSpawnModeOptions: function() {
        return ['email', 'approve', 'draft'].map(function(mode) {
            return Em.O({
                value: mode,
                name: t('recurring_invoice.spawn_mode.' + mode + '.short')
            })
        })
    }.property(),

    isRecurringSpawnModeEmail: Em.computed.equal('invoice.recurringSpawnMode', 'email'),

    showTaxRateFields: function() {
        // All other than Danish companies will see the tax rate field
        return this.get('organization.country.id') !== 'DK'
    }.property('organization.country.id'),

    taxModeOptions: function() {
        var hasVat = this.get('organization.hasVat')
        return [
            Em.O({
                value: 'excl',
                name: hasVat ? t('excl_vat') : t('excl_tax')
            }),
            Em.O({
                value: 'incl',
                name: hasVat ? t('incl_vat') : t('incl_tax')
            })
        ]
    }.property('organization.hasVat'),

    hasProductImage: function() {
        return !!this.get('lines').any(function(line) {
            return line.get('product.imageUrl')
        })
    }.property('lines.@each.product.imageUrl'),

    total: function() {
        return invoiceTotalHelper(this.get('invoice'), this.get('lines'))
    }.property('invoice.taxMode', 'lines.@each.{quantity,unitPrice,discountMode,discountValue,currentTaxRate,taxRate.name}'),

    focusLastLine: function() {
        this.$('.invoice-line-editor:last() input:first()').focus()
    },

    setupInvoiceNoClick: function() {
        var self = this
        var field = this.$('input[name="tentativeNextInvoiceNo"]')
        if (!field.length) {
            return
        }
        var overlay = $('<div style="position:absolute; left:0; right:0; top:0; bottom:0;"></div>')
        overlay.insertAfter(field)
        overlay.on('click', function() {
            self.container.lookup('util:dialog').dialog(t('invoice.edit.invoice_no_label'), t('invoice.edit.automatic_invoice_no_click', { no: self.get('organization.nextInvoiceNo') }), [
                { value: 'ok', text: t('ok'), primary: true }
            ], { focusSelector: '.window-footer button', closable: true })
        })
    }.on('didInsertElement'),

    supportsPaymentMethods: function() {
        return this.get('typeIsInvoice') || this.get('isRecurringInvoiceModel')
    }.property('typeIsInvoice', 'isRecurringInvoiceModel'),

    paymentMethodsEditedByUser: false,

    editingPaymentMethods: false,

    applyDefaultPaymentMethods: function() {
        var invoice = this.get('invoice')
        if (!invoice.get('isNew') || this.get('paymentMethodsEditedByUser')) {
            return
        }
        this.get('paymentMethods').getDefaultPaymentMethods(invoice.get('currency.id'))
            .then(function(paymentMethods) {
                return paymentMethods.filter(function(paymentMethod) {
                    return !paymentMethod.get('isPremiumRestricted') && !paymentMethod.isCurrencyBlocked(invoice.get('currency.id')) && !paymentMethod.get('isDisabled')
                })
            })
            .then(function(paymentMethods) {
                invoice.set(
                    'paymentMethods',
                    paymentMethods.map(function(paymentMethod) {
                        return {
                            paymentMethodId: paymentMethod.get('id')
                        }
                    })
                )
            })
    }.on('init').observes('invoice.currency'),

    paymentMethodOptions: function() {
        var selectedIds = this.get('invoice.paymentMethods').mapBy('paymentMethodId')
        var records = this.get('paymentMethods.data')
        var invoice = this.get('invoice')

        if (!records) {
            return []
        }

        return records
            .filter(function(paymentMethod) {
                return !paymentMethod.get('isPremiumRestricted') && !paymentMethod.isCurrencyBlocked(invoice.get('currency.id')) && !paymentMethod.get('isDisabled')
            })
            .map(function(paymentMethod) {
                return Ember.Object.create({
                    checked: selectedIds.contains(paymentMethod.get('id')),
                    paymentMethod: paymentMethod
                })
            })
    }.property('paymentMethods.data.@each', 'invoice.paymentMethods.@each', 'organization.isSubscriptionPlanPremium'),

    checkedPaymentMethodOptions: function() {
        return this.get('paymentMethodOptions').filterBy('checked', true)
    }.property('paymentMethodOptions.@each'),

    templatesOptions: function() {
        var records = this.get('templates.data')
        if (!records) {
            return []
        }
        return records.map(function(template) {
            return Ember.Object.create({
                value: template.id,
                name: template.name
            })
        })
    }.property('templates.data.@each'),

    showTemplatesOptions: function() {
        return this.get('organization.isSubscriptionPlanPremium') &&
            this.get('templatesOptions.length') > 0 &&
            this.get('templateSelector')
    }.property('organization.isSubscriptionPlanPremium', 'templatesOptions.length', 'templateSelector'),

    actions: {
        didUploadFile: function(test) {
            this.get('segment').track('File Uploaded (Client)', {
                context: 'create_invoice'
            })
        },

        handleTemplateSelect: function(templateId) {
            var invoice = this.get('invoice')
            var oldTemplateId = invoice.get('templateId')
            if (oldTemplateId !== templateId) {
                invoice.set('templateId', templateId)
                this.updateMessages()
            }
        },

        didClickUploadVoucher: function() {
            var context
            if (this.get('isInvoiceModel')) {
                context = 'invoice_attachment'
            }
            if (this.get('isQuoteModel')) {
                context = 'quote_attachment'
            }
            if (this.get('isRecurringInvoiceModel')) {
                context = 'recurring_invoice_attachment'
            }
            this.container.lookup('component:upgrade-plan-overlay')
                .set('trackingContext', context)
                .show()
        },

        didSelectContact: function() {
            var invoice = this.get('invoice')
            var organization = invoice.get('organization')
            var contact = invoice.get('contact')
            // Clear contact person and additional recipients
            invoice.set('attContactPerson', null)
            invoice.set('recipientContactPersonIds', [])

            // Update contact related defaults
            this.updatePaymentTerms()
            this.updateMessages()

            if (contact) {
                var contactCurrency = contact.get('currency')
                if (contactCurrency) {
                    invoice.set('currency', contactCurrency)
                }
            }

            // Update line amounts
            this.get('lines').forEach(function(line) {
                updateInvoiceLineTaxRate(line, organization, contact)
            })
        },

        didSelectQuoteType: function() {
            this.updateMessages()
        },

        addLine: function() {
            var invoice = this.get('invoice')
            var priority = this.get('lines').reduce(function(max, line) {
                return Math.max((line.get('priority') || 0) + 1, max)
            }, 1)
            var props = {
                priority: priority,
                quantity: 1
            }
            var store = this.container.lookup('store:main')
            if (this.get('isInvoiceModel')) {
                props.invoice = invoice
                Billy.InvoiceLine.createRecord(props)
            } else if (this.get('isQuoteModel')) {
                props.quote = invoice
                store.createRecord('quote-line', props)
            } else if (this.get('isRecurringInvoiceModel')) {
                props.recurringInvoice = invoice
                store.createRecord('recurring-invoice-line', props)
            }
            Em.run.schedule('afterRender', this, this.focusLastLine)
        },

        deleteLine: function(line) {
            line.deleteRecord()
            this.sendAction('deleteLine', line)
        },

        editRecurringEmail: function() {
            this.container.lookup('component:recurring-invoice-email-window').showForInvoice(this.get('invoice'))
        },

        editPaymentMethods: function() {
            this.set('editingPaymentMethods', true)
        },

        paymentMethodChanged: function(checked, paymentMethod) {
            var invoice = this.get('invoice')
            var paymentMethods = invoice.get('paymentMethods')
            if (checked) {
                paymentMethods = paymentMethods.concat([
                    {
                        paymentMethodId: paymentMethod.get('id')
                    }
                ])
            } else {
                paymentMethods = paymentMethods.rejectBy('paymentMethodId', paymentMethod.get('id'))
            }
            invoice.set('paymentMethods', paymentMethods)
            this.set('paymentMethodsEditedByUser', true)
        },

        createPaymentMethod: function() {
            var self = this
            var invoice = this.get('invoice')
            this.set('editingPaymentMethods', true)
            this.get('paymentMethods').createPaymentMethod(invoice.get('organization'), invoice.get('currency.id'))
                .then(function(paymentMethod) {
                    self.set('paymentMethodsEditedByUser', true)
                    invoice.get('paymentMethods').pushObject({
                        paymentMethodId: paymentMethod.get('id')
                    })
                })
        }
    },

    customActions: {
        onBannerInteraction: function() {
            this.set('isFinancingBannerDismissed', true)
        }
    }
})
