module.exports = Em.Route.extend({
    couponOffer: Ember.inject.service(),

    dashboardNavigator: Em.inject.service(),

    beforeModel: function() {
        var self = this

        var couponOffer = this.get('couponOffer')

        return couponOffer.load()
            .then(function() {
                self.set('showCouponOffer', couponOffer.isApplicable())
            })
    },

    setupController: function(controller) {
        if (!this.get('showCouponOffer')) {
            this.get('dashboardNavigator').replaceUrlWithDashboard()
        }
        controller.set('couponOffer', this.get('couponOffer'))
        controller.set('showCouponOffer', this.get('showCouponOffer'))
    }
})
