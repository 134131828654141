var trackError = require('trackError')

module.exports = Em.Controller.extend({
    token: null,

    userOrganizations: Em.inject.service(),

    segment: Em.inject.service(),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute.apply(this, [route].concat(props || []))
        },
        authorizeUser: function(e) {
            var token = e.detail.token

            if (!token) {
                trackError('You have to pass "token" to authorize user')
                return
            }

            var api = this.container.lookup('api:billy')
            api.authorize(token, false)
        },
        registerOrganization: function(e) {
            var self = this
            var organizationUrl = e.detail.organizationUrl
            var onSuccess = e.detail.onSuccess
            var onError = e.detail.onError

            if (!organizationUrl) {
                // eslint-disable-next-line no-console
                console.error(
                    'You have to pass "organizationUrl" to register organization'
                )
                return
            }

            if (!onSuccess || !onError) {
                // eslint-disable-next-line no-console
                console.error(
                    'You have to pass "onSuccess" and "onError" to register organization'
                )
                return
            }

            var userOrganizations = this.get('userOrganizations')
            userOrganizations.resetAll()
            userOrganizations
                .bootstrapByUrl(organizationUrl)
                .then(function(organizationModel) {
                    var organization = organizationModel.serialize({ includeAll: true })

                    self.get('segment').forceIdentify(organizationModel)
                    setTimeout(function() { // to allow Segment identify to finish
                        onSuccess(organization)
                    })
                })
                .catch(function(error) {
                    onError(error)
                })
        }
    }
})
