module.exports = BD.Model.extend({
    createdTime: BD.attr('datetime', { readonly: true }),
    name: BD.attr('string'),
    email: BD.attr('string'),
    phone: BD.attr('string'),
    profilePicFile: BD.belongsTo('Billy.File', { readonly: true }),
    profilePicUrl: BD.attr('string', { readonly: true }),
    profilePic48Url: BD.attr('string', { readonly: true }),
    isAdmin: BD.attr('boolean'),
    isStaff: BD.attr('boolean'),
    isSupporter: BD.attr('boolean'),
    isSupportAccessAllowed: BD.attr('boolean'),
    canCreateDaybooks: BD.attr('boolean'),
    locale: BD.attr('string'),
    setupGuideWelcome: BD.attr('string'),
    signupCouponId: BD.attr('string'),
    trustpilotReviewData: BD.attr('string'),
    globalId: BD.attr('string'),
    externalId: BD.attr('string'),
    themeMode: BD.attr('string')
})
