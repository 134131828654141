module.exports = {
    AdminProfileUpdated: 'admin-profile-updated',
    BillCreated: 'bill-created',
    BillUpdated: 'bill-updated',
    ContactUpdated: 'contact-updated',
    InvoiceCreated: 'invoice-created',
    InvoiceUpdated: 'invoice-updated',
    EmberUserOrganizationsLoaded: 'ember-user-organizations-loaded',
    OrganizationOwnersUpdated: 'organization-owners-updated',
    OrganizationSettingsUpdated: 'organization-settings-updated',
    OrganizationSettingsUpdatedInReactApp: 'organization-settings-updated-in-react-app',
    OrganizationUpdated: 'organization-updated',
    PaymentMethodsUpdated: 'payment-methods-updated',
    PaymentSaved: 'payment-saved',
    PaymentUpdated: 'payment-updated',
    RoutingLinkClicked: 'routing-link-clicked',
    StripeIntegrationDeliverResult: 'stripe-integration-deliver-result',
    StripeIntegrationRequestResult: 'stripe-integration-request-result',
    TaxRulesetUpdated: 'tax-ruleset-updated',
    TaxRuleUpdated: 'tax-rules-updated',
    ThemeLayoutChanged: 'theme-layout-changed',
    ThemeLayoutModeChanged: 'theme-layout-mode-changed',
    TransactionCreated: 'transaction-created',
    TransactionUpdated: 'transaction-updated',
    UploadsUpdated: 'uploads-updated'
}
