var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.InvoiceRead],

    auth: Ember.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationFeatures: Em.inject.service(),

    queryParams: {
        category: {
            replace: true
        }
    }
})
