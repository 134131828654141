var OrganizationBrand = require('../constants/organizationBrand')

var AGERAS_APP_URL = window.ENV ? window.ENV.AGERAS_APP_URL : process.env.AGERAS_APP_URL
var BILLY_APP_URL = window.ENV ? window.ENV.BILLY_APP_URL : process.env.BILLY_APP_URL

function redirectToBrand(brand, organizationUrl) {
    var redirectUrl = brand === OrganizationBrand.Billy ? BILLY_APP_URL + '/' + organizationUrl : AGERAS_APP_URL + '/' + organizationUrl

    if (window.location.hostname === 'localhost') {
        redirectUrl = window.location.origin + '/logout'
        window.alert(
            'This app is running with ' + ENV.theme.toUpperCase() + ' brand.\n' +
            'You are logged using an account that belongs to ' + brand.toUpperCase() + ' brand.\n' +
            'Run the app with the correct brand to use this account.\n' +
            'You will be logged out now.')
    }

    window.location.replace(redirectUrl)
}

module.exports = {
    redirectToBrand: redirectToBrand
}
