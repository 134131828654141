var Window = require('ember-window')
var batmask = require('batmask')
var t = require('i18n').t
var postingSides = require('posting-sides')
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/bank-transfer-window'),

    classNames: ['transaction-window-view'],

    customEvent: Ember.inject.service(),

    segment: Em.inject.service(),

    organization: null,

    entryDate: function() {
        return moment()
    }.property(),

    sourceAccount: null,

    targetAccount: null,

    sourceAmount: null,

    targetAmount: null,

    description: null,

    error: null,

    errors: function() {
        return Em.O()
    }.property(),

    bankAccountQuery: function() {
        return { isBankAccount: true }
    }.property(),

    currenciesAreDifferent: function() {
        var source = this.get('sourceAccount.currency')
        var target = this.get('targetAccount.currency')
        return source && target && source !== target
    }.property('sourceAccount', 'targetAccount'),

    // Bound to attachments-list in template
    isUploading: null,

    attachments: function() {
        return []
    }.property(),

    validate: function() {
        var self = this
        var errors = this.get('errors')
        var isValid = true

        var required = [
            'entryDate',
            'sourceAccount',
            'targetAccount',
            'sourceAmount',
            'description'
        ]

        if (this.get('currenciesAreDifferent')) {
            required.push('targetAmount')
        }

        required.forEach(function(k) {
            if (!self.get(k)) {
                errors.set(k, t('required_field'))
                isValid = false
            }
        })

        // Check for unfinished attachment uploads
        if (this.get('isUploading')) {
            this.set('error', t('still_uploading'))
            isValid = false
        }

        return isValid
    },

    getBaseAmount: function(sourceAccount, targetAccount) {
        var baseCurrency = this.get('organization.baseCurrency')
        var baseCurrencyId = baseCurrency.get('id')
        var sourceAccountCurrencyId = sourceAccount.get('currency.id')
        var targetAccountCurrencyId = targetAccount.get('currency.id')

        if (baseCurrencyId === sourceAccountCurrencyId) {
            return this.get('sourceAmount')
        }

        if (baseCurrencyId === targetAccountCurrencyId) {
            return this.get('targetAmount')
        }

        return sourceAccount.get('currency').exchangeTo(this.get('sourceAmount'), baseCurrency)
    },

    createTransaction: function() {
        var sourceAccount = this.get('sourceAccount')
        var targetAccount = this.get('targetAccount')
        var sourceAmount = this.get('sourceAmount')
        var targetAmount = this.get('targetAmount')
        var baseAmount = this.getBaseAmount(sourceAccount, targetAccount)

        var transaction = Billy.DaybookTransaction.createRecord({
            organization: this.get('organization'),
            state: 'approved',
            entryDate: this.get('entryDate'),
            description: this.get('description')
        })

        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            account: sourceAccount,
            amount: sourceAmount,
            baseAmount: baseAmount,
            side: postingSides.credit,
            currency: sourceAccount.get('currency'),
            priority: 1
        })

        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            account: targetAccount,
            amount: targetAmount || targetAmount === 0 ? targetAmount : sourceAmount,
            baseAmount: baseAmount,
            side: postingSides.debit,
            currency: targetAccount.get('currency'),
            priority: 2
        })

        // Set the transaction as the owner of any attachments
        this.get('attachments').forEach(function(attachment) {
            attachment.set('owner', transaction)
        })

        return transaction
    },

    save: function() {
        var self = this
        this.set('error', '')

        if (!this.validate()) {
            return
        }

        var transaction = this.createTransaction()

        batmask.maskDelayed()

        transaction.save({
            embed: ['lines', 'attachments']
        }).then(function() {
            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TRANSACTION_APPROVED, t('transaction.window.approval_success'))
            self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
            self.close()

            self.get('segment').track('Transaction Template Approved (Client)', {
                type: 'bank-transfer-window'
            })
        }).catch(function(e) {
            if (transaction) {
                transaction.rollback()
            }

            self.set('error', e.message)
        }).finally(function() {
            batmask.unmask()
        })
    },

    actions: {
        didUploadFile: function() {
            this.get('segment').track('File Uploaded (Client)', {
                context: 'transactions'
            })
        },

        approve: function() {
            this.save()
        }
    }
})
