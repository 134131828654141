var Cookies = require('js-cookie')
var qs = require('qs')
var popupWindow = require('../utils/popup-window')
var t = require('i18n').t
var cookieKey = require('../constants/cookieKey')
var CustomEvent = require('../constants/customEvent')
var tracking = require('../utils/tracking')
var invoiceTotalHelper = require('../utils/invoice-total-helper')
var ENUMS = require('../enums')
var NOTIFICATION_KEYS = require('../notificationKeys')
var getUserType = require('../utils/get-user-type')
var _ = require('lodash')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')
var billsView = require('../mixins/bills-view')

module.exports = Em.ObjectController.extend(ReactDirtyRouteMixin, billsView, {
    needs: ['application', 'user'],

    agerasLoan: Em.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    subscriptionLimits: Em.inject.service(),

    templates: Ember.inject.service(),

    intercom: Ember.inject.service(),

    constraints: Ember.inject.service(),

    segment: Em.inject.service(),

    customEvent: Ember.inject.service(),

    fileSizeLimit: ENUMS.FILE_SIZE_LIMIT,

    isFinancingBannerDismissed: false,

    preselectedTemplate: null,

    sendInvoiceType: null,

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    activeOrganization: Em.computed.alias('controllers.user.activeOrganization'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership'),

    isZervantSource: function() {
        var organization = this.get('organization')
        return organization.get('isSourceZervant')
    }.property('organization'),

    duplicateCheckDisabled: function() {
        return !!this.get('organization.settings.isDuplicateInvoiceCheckDisabled')
    }.property('organization.settings.isDuplicateInvoiceCheckDisabled'),

    isApproveInvoiceInfoDismissed: null, // set from route

    isInvoiceDateInfoDismissed: function() {
        return this.get('organization.settings.isInvoiceDateInfoDismissed') === '1'
    }.property('organization.settings.isInvoiceDateInfoDismissed'),

    loadAgerasFinance: function() {
        var self = this

        return Em.RSVP.hash({
            financingEnabled: self.get('agerasLoan').isFinancingEnabled(),
            financingDismissed: self.get('agerasLoan').isFinancingDismissed()
        }).then(function(results) {
            self.set('financingEnabled', results.financingEnabled && !results.financingDismissed)
            self.debouncedFinancingParametersChanged() // to load simulation after i.e. draft invoice opened
        })
    }.on('init').observes('id'),

    // value of toggle that finances invoice after approving
    shouldFinanceInvoiceOnSave: false,

    // is invoice not eligible for financing? updates after getting an estimate, reversed logic for template
    isApprovingFinancingDisabled: function() {
        return !this.get('financingEstimate.payout_amount')
    }.property('financingEstimate'),

    financingEstimate: null,

    financingEnabled: null, // from route

    shouldShowFinancing: function() {
        var isTrial = this.get('organization.isTrial')

        return !isTrial && !this.get('isFinancingBannerDismissed')
    }.property('isFinancingBannerDismissed'),

    isFinancingAvailable: function() {
        return this.get('financingEnabled') && (this.get('state') === 'draft' || this.get('isNew'))
    }.property('state', 'isNew', 'financingEnabled'),

    financingCta: function() {
        return t('invoice.index.loan_get_paid_today', { amount: this.get('payoutAmount') })
    }.property('financingEstimate', 'payoutAmount'),

    payoutAmount: function() {
        return Billy.money(this.get('financingEstimate.payout_amount'), this.get('financingEstimate.payout_currency'))
    }.property('financingEstimate.payout_amount'),

    setPreselectedTemplateOption: function() {
        var invoiceTemplateId = this.get('model.templateId')

        if (invoiceTemplateId) {
            this.set('preselectedTemplate', invoiceTemplateId)
            return
        }

        var templateOptions = this.get('templatesOptions')

        if (templateOptions && templateOptions.length) {
            this.set('preselectedTemplate', templateOptions[0].value)
        }
    }.observes('model'),

    templatesOptions: function() {
        var records = this.get('templates.data')
        if (!records) {
            return []
        }
        return records.map(function(template) {
            return Ember.Object.create({
                value: template.id,
                name: template.name
            })
        })
    }.property('templates.data.@each'),

    getInvoiceFinancingEstimate: function(invoiceNo) {
        var self = this

        return self.get('agerasLoan').getInvoiceFactoring(
            self.get('id'),
            invoiceNo || 'draft',
            self.get('currency.id'),
            self.get('grossAmount'),
            self.get('dueDate')
        ).then(function(estimate) {
            if (estimate && estimate.payout_amount) {
                self.set('financingEstimate', estimate)
            }

            return Em.RSVP.Promise.resolve(estimate)
        })
    },

    title: function() {
        if (!this.get('isLoaded')) {
            return ''
        }
        if (this.get('isInvoice')) {
            if (this.get('isNew')) {
                return t('invoice.edit.title.create_invoice')
            } else {
                if (this.get('isDraft')) {
                    return t('invoice.edit.title.edit_draft_invoice')
                } else {
                    return t('invoice.edit.title.edit_invoice')
                }
            }
        } else {
            if (this.get('isNew')) {
                return t('invoice.edit.title.create_credit_note')
            } else {
                return t('invoice.edit.title.edit_credit_note')
            }
        }
    }.property('isLoaded', 'isInvoice', 'isNew', 'isDraft'),

    debouncedFinancingParametersChanged: _.debounce(function() { // debounced because of manual input of amount
        if (!this.get('isFinancingAvailable')) {
            return
        }

        var self = this
        var invoice = this.get('model')
        var lines = invoice.get('lines')

        if (lines === undefined) {
            return
        }

        var currency = invoice.get('currency.id')
        var totalGrossAmount = invoiceTotalHelper(invoice, lines).grossAmount
        var dueDate = moment(new Date()).add(this.get('model.paymentTermsDays'), 'days').format()

        if (totalGrossAmount <= 0) {
            return
        }

        this.get('agerasLoan').getEstimateSimulation(currency, totalGrossAmount, dueDate).then(function(simulatedEstimate) {
            self.set('financingEstimate', simulatedEstimate)
        })
    }, 300),

    // debouncing observer because of possibility of manual input of amount
    financingParametersChanged: function() {
        this.debouncedFinancingParametersChanged()
    }.observes('model.lines.@each.quantity', 'model.lines.@each.unitPrice', 'model.paymentTermsDays', 'model.currency.id', 'model.id'),

    findDoublets: function() {
        var invoice = this.get('model')

        var entryDate = invoice.get('entryDate')
        var contact = invoice.get('contact')
        var currency = invoice.get('currency')
        var lines = invoice.get('lines')
        var totalGrossAmount = invoiceTotalHelper(invoice, lines).grossAmount

        return Billy.Invoice.findByQuery({
            organizationId: this.get('organization.id'),
            minEntryDate: entryDate.format('YYYY-MM-DD'),
            maxEntryDate: entryDate.format('YYYY-MM-DD'),
            contactId: contact.get('id'),
            currencyId: currency.get('id'),
            grossAmount: totalGrossAmount
        }).promise
    },

    saveAndThen: function(properties, successCallback, errorCallback) {
        var self = this
        var invoice = this.get('model')
        properties = properties || {}

        var duplicateCheckDisabled = this.get('duplicateCheckDisabled')
        var entryDate = invoice.get('entryDate')
        var contact = invoice.get('contact')
        var currency = invoice.get('currency')
        var lines = invoice.get('lines')
        var lineDescription = lines.map(function(line) {
            var desc = line.get('product.name')
            if (line.get('description')) {
                desc += ': ' + line.get('description')
            }
            return desc
        }).join(', ')
        var totalGrossAmount = invoiceTotalHelper(invoice, lines).grossAmount

        if (!duplicateCheckDisabled && entryDate && contact && currency && lineDescription) {
            this.findDoublets().then(function(invoices) {
                invoices = invoices.filter(function(invoice) {
                    return invoice.get('id') !== self.get('model.id') && invoice.get('type') === self.get('type')
                })

                if (!invoices.get('length')) {
                    return self.saveInvoice(properties, successCallback, errorCallback)
                }

                var newInvoice = Billy.Invoice.createRecord(invoice.getProperties(['entryDate', 'contact', 'currency']))
                newInvoice.set('lineDescription', lineDescription)
                newInvoice.set('grossAmount', totalGrossAmount)

                self.container.lookup('component:doublet-checker-window')
                    .set('model', newInvoice)
                    .set('isNew', self.get('isNew'))
                    .set('organization', self.get('organization'))
                    .setMatches(invoices)
                    .set('saveCallback', function() {
                        self.saveInvoice(properties, successCallback, errorCallback)
                    })
                    .set('deleteCallback', function() {
                        if (self.get('isNew')) {
                            invoice.resetClean()
                            self.transitionToRoute('invoices.index')
                            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.INVOICE_DISCARD, t('doublet_checker_window.invoice.discarded'))
                        }
                    })
                    .show()
            })

            return
        }

        this.saveInvoice(properties, successCallback, errorCallback)
    },

    saveInvoice: function(properties, successCallback, errorCallback) {
        var self = this
        var invoice = this.get('model')
        var customEventService = this.get('customEvent')
        invoice
            .save({
                embed: ['lines', 'attachments'],
                properties: properties
            })
            .mask()
            .on('success', function(payload) {
                customEventService.dispatchEvent(CustomEvent.InvoiceUpdated)
                self.replaceRoute('invoice.edit', invoice).then(function() {
                    if (successCallback) {
                        successCallback.call(self, payload)
                    }
                })
            })
            .on('error', function(errorText, payload) {
                if (errorCallback) {
                    errorCallback.call(self, errorText, payload)
                }
            })
    },

    showFirstInvoiceModal: function() {
        var self = this
        var query = {
            organizationId: self.get('organization.id')
        }
        Billy.Invoice.find(query).promise.then(function(invoices) {
            if (invoices.content.length <= 1) {
                Cookies.set(cookieKey.hasFirstInvoiceModaBeenSeen + '-' + query.organizationId, true)
                self.container.lookup('component:first-invoice-modal').show()
            }
        })
    },

    save: function(saveProperties) {
        var properties = saveProperties
        var self = this
        var invoice = this.get('model')
        var beforeState = invoice.get('state')
        var convertingToDraft = false

        this.get('subscriptionLimits').ensureLoaded().then(function() {
            // if limits are exceeded and trying to approve (state present), we save the draft by removing state
            if (self.get('subscriptionLimits.isInvoiceLimitExceeded') && properties.state) {
                convertingToDraft = true
                delete properties.state
            }

            self.saveAndThen(properties, function(savePayload) {
                // and if the limit is exceeded, after saving draft we show the upgrade modal
                if (self.get('subscriptionLimits.isInvoiceLimitExceeded') && convertingToDraft) {
                    self.get('constraints').showUpgradeOverlay('free_invoices_limit_exceeded', 'free_invoices_limit_exceeded')
                }
                self.get('subscriptionLimits').invalidate()

                var invoiceType = invoice.get('type')
                var organizationId = invoice.get('organization.id')
                if (invoice.get('state') === 'approved') {
                    self.get('segment').track('Invoice Approved (Client)', {
                        type: invoiceType === 'creditNote' ? 'credit_note' : invoiceType
                    })
                    var hasFirstInvoiceModaBeenSeen = Cookies.get(cookieKey.hasFirstInvoiceModaBeenSeen + '-' + organizationId)
                    if (!hasFirstInvoiceModaBeenSeen) {
                        self.showFirstInvoiceModal()
                    }
                }

                // after credit note is saved, refresh info on underlying invoice
                if (invoice.get('creditedInvoice.id')) {
                    self.get('customEvent').dispatchEvent(CustomEvent.PaymentUpdated, { subjectId: invoice.get('creditedInvoice.id'), subjectType: 'invoice' })
                }

                if (invoice.get('isDraft')) {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_DRAFT_SAVE, t('invoice.edit.saved_as_draft'))
                    self.get('segment').track('Invoice Draft Created (Client)', {
                        type: invoiceType === 'creditNote' ? 'credit_note' : invoiceType
                    })
                } else {
                    self.transitionToRoute('invoice', invoice)
                        .then(function() {
                            if (self.get('sendInvoiceType') !== null) {
                                var sentAsEInvoice = self.get('sendInvoiceType') === 'eInvoice'
                                self.set('sendInvoiceType', null)
                                self.container.lookup('controller:invoice-index').sendInvoice(sentAsEInvoice)
                            }
                            if (invoice.get('state') === 'approved' && beforeState === 'draft') {
                                tracking.emitAnalyticsEvent('free_activity', 'approve_invoice', 'approved an invoice')
                                self.get('intercom').trackEvent(self.get('organization'), 'approved_invoice')
                                self.container.lookup('controller:invoice-index').set('justApproved', true)

                                if (self.get('shouldFinanceInvoiceOnSave')) {
                                    self.set('shouldFinanceInvoiceOnSave', false)
                                    self.container.lookup('component:approve-financing-modal')
                                        .set('estimate', self.get('financingEstimate'))
                                        .set('invoice', self.get('model'))
                                        .show()
                                }
                            }
                        })
                }
            }, self.notifySaveError.bind(self))
        })
    },

    newDuplicateFromId: function(id) {
        this.replaceRoute('invoices.new', {
            queryParams: {
                fromInvoiceId: id
            }
        })

        this.container.lookup('util:notification').success(NOTIFICATION_KEYS.INVOICE_DUBLICATE, t('invoice.edit.duplicate_draft_success'))
    },

    getErrorMessage: function(errorCode) {
        switch (errorCode) {
        case 'UNPROCESSABLE_RECORD':
            return t('invoice.save.already_approved')
        default:
            return undefined
        }
    },

    notifySaveError: function(errorText, payload) {
        var errorDetail = this.getErrorMessage(payload && payload.errorCode) || errorText
        var validationErrors = payload.validationErrors ? Object.values(payload.validationErrors) : []

        if (validationErrors.length) {
            validationErrors.forEach(function(validationError) {
                errorDetail += ' ' + Object.values(validationError.attributes).join(', ')
            })
        }

        var errorMessage
        var invoiceTemplateId = this.get('model.templateId')

        if (invoiceTemplateId === 'null') {
            errorMessage = t('invoice.save.missing_template')
        } else {
            errorMessage = errorDetail
        }

        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.INVOICE_ERROR, errorMessage)
    },

    approveInvoice: function() {
        var total = invoiceTotalHelper(this.get('model'), this.get('lines'))
        this.get('segment').track('XXX - Jesper - Action Taken', {
            context: 'draft_invoice_view',
            action: 'approve'
        })
        var templateId = this.get('preselectedTemplate')

        if (total.grossAmount < 0) {
            var invoice = this
            if (invoice.get('type') !== 'creditNote') {
                this.container.lookup('util:dialog')
                    .confirm(null, t('invoice_negative_balance'), t('general_continue_and_aprove'), t('general_cancel'))
                    .then(function() {
                        return invoice.save({ state: 'approved', templateId: templateId })
                    })
            } else {
                this.container.lookup('util:dialog')
                    .confirm(null, t('invoice_negative_balance_credit_note'), t('general_continue_and_aprove'), t('general_cancel'))
                    .then(function() {
                        return invoice.save({ state: 'approved', templateId: templateId })
                    })
            }
        } else {
            var saveProperties = { state: 'approved', templateId: templateId }
            if (this.get('fromQuoteId') !== null) {
                saveProperties.quoteId = this.get('fromQuoteId')
            }
            this.save(saveProperties)
        }
    },

    isEntryDateInThePast: function() {
        var invoice = this.get('model')
        var entryDate = invoice.get('entryDate')

        return entryDate.isBefore(moment(), 'day')
    }.property('model.{entryDate}'),

    approveAndAdjustDateIfRequired: function() {
        var self = this
        var invoice = this.get('model')
        var isNewInvoice = invoice.get('isNew')
        var entryDate = invoice.get('entryDate')._d.toDateString()
        var shouldDisplayInvoiceDateModal = !isNewInvoice && this.get('isEntryDateInThePast') && !this.get('isInvoiceDateInfoDismissed')

        if (shouldDisplayInvoiceDateModal) {
            self.container.lookup('component:invoice-date-modal').set('approveInvoice', this.get('approveInvoiceInInvoiceDateModal').bind(this)).set('entryDate', entryDate).show()
        } else {
            this.approveInvoice()
        }
    },

    approveInvoiceInApproveInvoiceModal: function(event) {
        var shouldNeverShowAgain = _.get(event, 'detail.shouldNeverShowAgain')
        if (shouldNeverShowAgain) {
            this.set('isApproveInvoiceInfoDismissed', true)
            this.set('organization.settings.isApproveInvoiceInfoDismissed', '1')
        }

        this.approveAndAdjustDateIfRequired()
    },

    approveInvoiceInInvoiceDateModal: function(event) {
        var self = this
        var shouldNeverShowAgain = _.get(event, 'detail.shouldNeverShowAgain')
        if (shouldNeverShowAgain) {
            this.set('isInvoiceDateInfoDismissed', true)
            this.set('organization.settings.isInvoiceDateInfoDismissed', '1')
        }

        var shouldApproveWithTodaysDate = _.get(event, 'detail.shouldApproveWithTodaysDate')
        if (shouldApproveWithTodaysDate) {
            var invoice = self.get('model')
            var todaysDate = moment()
            invoice.set('entryDate', todaysDate)
        }

        this.approveInvoice()
    },

    actions: {
        backToList: function() {
            var invoicesListQuery = Cookies.get(cookieKey.invoicesListQuery) || ''
            Cookies.remove(cookieKey.invoicesListQuery)
            this.transitionToRoute('invoices.index', { queryParams: qs.parse(invoicesListQuery, { ignoreQueryPrefix: true }) })
        },

        save: function() {
            var saveProperties = { templateId: this.get('preselectedTemplate') }

            if (this.get('fromQuoteId') !== null) {
                saveProperties.quoteId = this.get('fromQuoteId')
            }
            this.save(saveProperties)
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'draft_invoice_view',
                action: 'save as draft'
            })
        },

        toggleShouldFinanceInvoice: function() {
            this.set('shouldFinanceInvoiceOnSave', !this.get('shouldFinanceInvoiceOnSave'))
        },

        saveAndPrint: function() {
            var invoice = this.get('model')
            var win = popupWindow.open()

            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'draft_invoice_view',
                action: 'preview or more: print / save as pdf'
            })

            this.saveAndThen({}, function() {
                win.setLocation(invoice.get('downloadUrl'))
            }, function(errorText, payload) {
                this.notifySaveError(errorText, payload)
                win.close()
            })
        },

        approve: function(sentType) {
            if (sentType) {
                this.set('sendInvoiceType', sentType)
            }

            var self = this
            var isCreditNote = this.get('isCreditNote')
            var isApproveInvoiceInfoDismissed = this.get('isApproveInvoiceInfoDismissed')

            if (!isApproveInvoiceInfoDismissed && !isCreditNote) {
                self.container.lookup('component:approve-invoice-modal').set('approveInvoice', this.get('approveInvoiceInApproveInvoiceModal').bind(this)).show()
            } else {
                this.approveAndAdjustDateIfRequired()
            }
        },

        duplicateDraft: function() {
            var self = this
            var draft = self.get('model')
            if (draft.get('isDirty')) {
                self.container.lookup('util:dialog')
                    .confirm(null, t('invoice.edit.duplicate_dirty'), t('invoice.edit.save_and_duplicate')).then(function() {
                        self.saveAndThen({}, function() {
                            self.newDuplicateFromId(draft.get('id'))
                        })
                    })
            } else {
                self.newDuplicateFromId(draft.get('id'))
            }
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'draft_invoice_view',
                action: 'duplicate'
            })
        },

        delete: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('invoice.edit.confirm_delete'), t('invoice.edit.confirm_delete_yes')).then(function() {
                    self.get('model')
                        .deleteRecord()
                        .on('success', function() {
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_DRAFT_DELETE, t('invoice.edit.draft_was_deleted'))
                        })
                    self.replaceRoute('invoices')
                })
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'draft_invoice_view',
                action: 'delete'
            })
        }
    },

    customActions: function() {
        return _.merge(this.get('_customActions'), this.get('_customActionsBills'), {})
    }.property('_customActions', '_customActionsBills')
})
