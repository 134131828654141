var ListController = require('billy-webapp/ui/list/controller')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')
var getUserType = require('../utils/get-user-type')

// Required in the current Ember + React routing setup
// to make redirection like /{org-name}/ to /{org-name}/dashboard happen

module.exports = ListController.extend(ReactDirtyRouteMixin, {
    needs: ['application', 'user'],

    userOrganizations: Em.inject.service(),

    sideMenu: Ember.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership')
})
