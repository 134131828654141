var t = require('i18n').t
var Window = require('ember-window')
var tracking = require('../utils/tracking')
var Scope = require('../utils/scope')
var getLanguageFromLocale = require('../utils/get-language-from-locale')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {

    api: Ember.inject.service(),

    auth: Ember.inject.service(),

    contacts: Ember.inject.service(),

    constraints: Ember.inject.service(),

    cvr: Ember.inject.service(),

    layout: Window.proto().layout,

    segment: Em.inject.service(),

    template: require('../templates/components/contact-editor'),

    content: null,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    customEvent: Ember.inject.service(),

    userController: function() {
        return this.container.lookup('controller:user')
    }.property(),

    user: Em.computed.oneWay('userController.model'),

    typesHidden: false,

    callback: null,

    width: 800,

    focusSelector: ':input:not(button):first',

    isCompany: function() {
        return this.get('content.type') === 'company'
    }.property('content.type'),

    isPerson: function() {
        return this.get('content.type') === 'person'
    }.property('content.type'),

    resetErrorsOnTypeChange: function() {
        var contact = this.get('content')
        var contactPersons = contact.get('contactPersons')

        // 'firstName' is required only for 'person' content.type,
        // but inputs don't get refreshed when switching modal tabs
        // and validation errors stay even though they should not,
        // that's why we have to manually reset them
        if (this.get('isCompany')) {
            contactPersons.forEach(function(contactPerson) {
                contactPerson.set('errors.firstName', null)
            })
        }
    }.observes('content.type'),

    allContactPersons: function() {
        var contactPersons = Em.ArrayController.create({
            container: this.container,
            parentController: this,
            model: this.get('content.contactPersons'),
            itemController: 'contact-person-item-controller',
            sortProperties: ['isPrimary'],
            sortAscending: false
        })

        return contactPersons
    }.property('content.contactPersons.[]', 'content.type'),

    getPrimaryContactPerson: function() {
        var contact = this.get('content')
        var contactPersons = contact.get('contactPersons')
        var primaryContactPerson = contactPersons.find(function(person) {
            return person.get('isPrimary') === true
        })
        var firstContactPerson = contactPersons.objectAt(0)

        return primaryContactPerson || firstContactPerson
    },

    isPersonEdited: function() {
        var contact = this.get('content')

        return !contact.get('isNew') && this.get('isPerson')
    }.property('content'),

    getFirstAndLastName: function(fullName) {
        var fullNameParts = fullName.split(' ')
        var firstName = ''
        var lastName = ''

        if (fullNameParts.length === 1) {
            firstName = fullName
        } else {
            firstName = fullNameParts.shift()
            lastName = fullNameParts.join(' ')
        }

        return { firstName: firstName, lastName: lastName }
    },

    getFullName: function(firstName, lastName) {
        if (!firstName && !lastName) {
            return
        }

        var fullName = (firstName || '') + ' ' + (lastName || '')

        return fullName.trim()
    },

    // when user edits individual contact which has a contactPerson,
    // but without firstName & lastName attributes set:
    // set those attributes based on contact.name (needed due to structure change)
    setMissingContactPersonNameAttributesOnEdit: function(contactPerson) {
        var self = this

        if (self.get('isPersonEdited') && !contactPerson.get('firstName') && !contactPerson.get('lastName')) {
            var contactName = self.get('content.name')
            var personName = self.getFirstAndLastName(contactName)

            contactPerson.set('firstName', personName.firstName)
            contactPerson.set('lastName', personName.lastName)
        }
    },

    updateContactPersonOnEdit: function() {
        var primaryContactPerson = this.getPrimaryContactPerson()

        if (!primaryContactPerson) {
            return
        }

        this.setMissingContactPersonNameAttributesOnEdit(primaryContactPerson)
    }.observes('content'),

    prepareContact: function() {
        var contact = this.get('content')
        if (!contact) {
            return
        }

        // If contact is neither customer nor supplier, we default it to being both
        if (!contact.get('isCustomer') && !contact.get('isSupplier')) {
            contact.set('isCustomer', true)
                .set('isSupplier', true)
        }
    }.observes('content').on('init'),

    nameLabel: function() {
        return this.get('content.isCompany') ? t('contact_editor.company_name') : t('name')
    }.property('content.isCompany'),

    addContactPersonButtonLabel: function() {
        return this.get('content.isCompany') ? t('contact_editor.add_contact_person') : t('contact_editor.add_additonal_contact')
    }.property('content.isCompany'),

    contactPersonsLabel: function() {
        return (this.get('content.contactPersons.length') === 1 ? t('contact.contact_person') : t('contact.contact_persons'))
    }.property('content.contactPersons.length'),

    additionalContactsLabel: function() {
        return (this.get('contactPersonsExceptFirst.length') === 1 ? t('contact.additional_contact') : t('contact.additional_contacts'))
    }.property('contactPersonsExceptFirst.length'),

    typeSelectIsVisible: function() {
        return !this.get('typesHidden')
    }.property('typesHidden'),

    ensureHasContactPerson: function() {
        var self = this
        var contact = self.get('content')
        var wasDirty = contact.get('isDirty')

        if (contact.get('contactPersons.length') === 0) {
            var contactPerson = Billy.ContactPerson.createRecord({
                contact: contact
            })

            self.setMissingContactPersonNameAttributesOnEdit(contactPerson)

            if (!wasDirty && contact.get('isNew')) {
                contact.resetClean()
            }
        }
    }.on('didInsertElement'),

    shouldShowAdditionalFields: function() {
        return this.get('organization.isBrandBilly')
    }.property('organization.isBrandBilly'),

    shouldShowInvoicingLanguageField: function() {
        return this.get('organization.isBrandAgeras')
    }.property('organization.isBrandAgeras'),

    bookkeepingDefaultsScope: function() {
        return Scope.ContactBookkeepingDefaults
    }.property(),

    isContactBookkeepingDefaultsAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.ContactBookkeepingDefaults)
    }.property(),

    isContactBookkeepingDefaultsDisabled: function() {
        return !this.get('isContactBookkeepingDefaultsAuthorized')
    }.property(),

    isContactLookupAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.ContactRegisterLookup)
    }.property(),

    isCvrLookupAuthorized: function() {
        return this.get('content.canLookupCvrInfo') && this.get('isContactLookupAuthorized')
    }.property('isContactLookupAuthorized', 'content.canLookupCvrInfo'),

    paymentTermsModeOptions: function() {
        return [
            Ember.Object.create({
                label: t('contacts.editor.days_after'),
                value: 'daysAfter'
            }),
            Ember.Object.create({
                label: t('contacts.editor.current_month'),
                value: 'currentMonth'
            }),
            Ember.Object.create({
                label: t('contacts.editor.following_month'),
                value: 'followingMonth'
            })
        ]
    }.property(),

    accountNatures: function() {
        return [Billy.AccountNature.find('expense'), Billy.AccountNature.find('asset')]
    }.property(),

    typeOptions: function() {
        return [
            Ember.Object.create({
                dataCy: 'contact-type-company-option',
                label: t('contact.type.company'),
                value: 'company'
            }),
            Ember.Object.create({
                dataCy: 'contact-type-person-option',
                label: t('contact.type.private_person'),
                value: 'person'
            })
        ]
    }.property(),

    alwaysShowRegistrationNo: Em.computed.oneWay('content.isCompany'),

    canHaveEan: function() {
        return this.get('content.organization.isBillyProviderDk')
    }.property('content.organization.isBillyProviderDk'),

    extraFields: function() {
        var fields = [
            !this.get('alwaysShowRegistrationNo') && Em.O({
                name: 'registrationNo',
                label: t('contact.property.registrationNo'),
                isTextField: true
            }),
            Em.O({
                name: 'contactNo',
                label: t('contact.property.contact_no'),
                isTextField: true
            }),
            Em.O({
                name: 'paymentTermsMode',
                label: t('contact.property.payment_terms'),
                isPaymentTermsField: true
            }),
            Em.O({
                name: 'currency',
                label: t('contact.property.currency'),
                isSuperField: true,
                type: 'currency'
            }),
            Em.O({
                name: 'locale',
                label: t('contact.property.locale'),
                isSuperField: true,
                type: 'locale'
            }),
            Em.O({
                name: 'emailAttachmentDeliveryMode',
                label: t('contact.property.emailAttachmentDeliveryMode'),
                isSelectField: true,
                options: [
                    Em.Object.create({
                        name: t('email_attachment_delivery_mode.customer_portal_and_attachment'),
                        value: 'linkAndAttachment'
                    }),
                    Em.Object.create({
                        name: t('email_attachment_delivery_mode.email_attachment'),
                        value: 'attachment'
                    })
                ]
            })
        ]
        fields = fields.filter(function(field) {
            return !!field
        })
        return fields
    }.property('type'),

    activeExtraFields: function() {
        var contact = this.get('content')
        return this.get('extraFields').reduce(function(rest, field) {
            if (!Em.isEmpty(contact.get(field.get('name')))) {
                rest.pushObject(field)
            }
            return rest
        }, [])
    }.property('extraFields.@each'),

    inactiveExtraFields: function() {
        var contact = this.get('content')
        return this.get('extraFields').reduce(function(rest, field) {
            if (Em.isEmpty(contact.get(field.get('name')))) {
                rest.pushObject(field)
            }
            return rest
        }, [])
    }.property('extraFields.@each'),

    invoicingLanguageOptions: function() {
        return [
            Ember.Object.create({
                name: t('invoicing_language.german'),
                value: 'de'
            }),
            Ember.Object.create({
                name: t('invoicing_language.english'),
                value: 'en'
            }),
            Ember.Object.create({
                name: t('invoicing_language.finnish'),
                value: 'fi'
            }),
            Ember.Object.create({
                name: t('invoicing_language.french'),
                value: 'fr'
            }),
            Ember.Object.create({
                name: t('invoicing_language.dutch'),
                value: 'nl'
            }),
            Ember.Object.create({
                name: t('invoicing_language.swedish'),
                value: 'sv'
            })
        ]
    }.property(),

    defaultInvoicingLanguage: function() {
        var locale = this.get('user.locale')
        var localeLanguage = getLanguageFromLocale(locale)
        var invoicingLanguages = this.get('invoicingLanguageOptions').map(function(option) {
            return option.value
        })
        var defaultInvoicingLanguage = invoicingLanguages.includes(localeLanguage) ? localeLanguage : 'en'

        return defaultInvoicingLanguage
    }.property('user.locale', 'invoicingLanguageOptions'),

    setDefaultInvoicingLanguage: function() {
        var contact = this.get('content')
        var invoicingLanguage = contact.get('invoicingLanguage')
        var defaultInvoicingLanguage = this.get('defaultInvoicingLanguage')

        if (!invoicingLanguage) {
            contact.set('invoicingLanguage', defaultInvoicingLanguage)
        }
    }.observes('content.invoicingLanguage', 'defaultInvoicingLanguage'),

    enableExtraField: function(name) {
        this.toggleExtraField(name, true)
    },

    disableExtraField: function(name) {
        this.toggleExtraField(name, false)
    },

    toggleExtraField: function(name, enabled) {
        var field = this.get('extraFields').findBy('name', name)
        this.get('inactiveExtraFields')[enabled ? 'removeObject' : 'addObject'](field)
        this.get('activeExtraFields')[enabled ? 'addObject' : 'removeObject'](field)
    },

    hasMultipleContactPersons: Em.computed.gt('content.contactPersons.length', 1),

    showTaxOptions: function() {
        return (this.get('content.isCustomer') && !this.get('content.organization.hasVat'))
    }.property('content.{isCustomer,organization.hasVat}'),

    helpText: function() {
        return new Em.Handlebars.SafeString(t('contact_editor.help_text'))
    }.property(),

    forceClose: function() {
        this.set('confirmCloseWhenDirty', false)
        this.close()
    },

    actions: {
        upgradeToPremium: function() {
            this.get('constraints').showUpgradeOverlay('', 'contact_ean')
        },

        addField: function() {
            var self = this
            var popover = this._addFieldPopover
            if (popover) {
                popover.destroy()
            } else {
                popover = this._addFieldPopover = this.container.lookup('component:contactEditorAddFieldPopover')
                popover.set('editor', this)
                popover.one('select', function(field) {
                    var name = field.get('name')
                    self.enableExtraField(name)
                    Em.run.schedule('afterRender', function() {
                        self.$('input[name="' + name + '"]').focus()
                    })
                    popover.destroy()
                })
                popover.one('willDestroyElement', function() {
                    self._addFieldPopover = null
                })
                var targetView = Ember.View.views[this.$('.tool-icons-plus-circle:last').attr('id')]
                popover.show(targetView)
            }
        },

        didSelectCompany: function(item) {
            var contact = this.get('content')

            if (!item) {
                return
            }

            contact.set('name', item.name)
            contact.set('registrationNo', item.cvr)
            contact.set('phone', item.phone)
            contact.set('cityText', item.city)
            contact.set('zipcodeText', item.zipcode)
            contact.set('street', item.streetAndNumber)
            contact.set('stateText', item.state)
        },

        didSelectCountry: function() {
            var contact = this.get('content')
            var country = contact.get('country')
            if (!country) {
                return
            }
            var locale = country.get('locale') || Billy.Locale.find('en_US')
            if (contact.get('organization.country.locale') !== locale) {
                contact.set('locale', locale)
                this.enableExtraField('locale')
            } else {
                contact.set('locale', null)
                this.disableExtraField('locale')
            }
        },

        addPerson: function() {
            Billy.ContactPerson.createRecord({
                contact: this.get('content')
            })
        },

        deletePerson: function(person) {
            person.deleteRecord()
        },

        getCvrInfo: function() {
            var self = this
            var contact = this.get('content')
            if (!(contact.get('registrationNo') && Boolean(contact.get('registrationNo').match(/^\d{8}$/)))) {
                contact.set('errors.registrationNo', t('contact_editor.invalid_tax_id'))
                return
            }

            this.get('api')
                .request('GET', this.get('cvr').getCvrCompaniesUrl(contact.get('registrationNo')), {
                    mask: true
                })
                .then(function(payload) {
                    self.get('contacts').fillContactWithCvrData(this.get('model'), payload.data)
                })
                .catch(function() {
                    contact.set('errors.registrationNo', t('contact_editor.get_cvr_data.error'))
                })
        },

        save: function() {
            var self = this
            var contact = self.get('content')
            var wasNew = contact.get('isNew')

            self.trigger('validateForm')

            // Should be replaced with `contact.checkValidation()` once https://github.com/billysbilling/billy-webapp/issues/157 has been fixed
            if (contact.get('errors.zipcode')) {
                contact.trigger('didValidate')
                return
            }

            var primaryContactPerson = self.getPrimaryContactPerson()
            var primaryContactPersonFirstName = primaryContactPerson.get('firstName')
            var primaryContactPersonLastName = primaryContactPerson.get('lastName')
            var contactName = this.getFullName(primaryContactPersonFirstName, primaryContactPersonLastName)

            if (self.get('isPerson') && !!contactName) {
                contact.set('name', contactName)
            }

            if (!contact.get('name') || ((self.get('isPerson') && !primaryContactPerson.get('firstName')))) {
                return
            }

            contact
                .save({
                    embed: ['contactPersons']
                })
                .success(function() {
                    if (wasNew) {
                        self.get('segment').track('Contact Created (Client)')
                        tracking.emitAnalyticsEvent('free_activity', 'create_contact', 'created a contact')
                    } else {
                        self.get('segment').track('Contact Updated (Client)')
                    }
                    if (self.get('callback')) {
                        self.get('callback')(contact)
                    }
                    self.get('customEvent').dispatchEvent(CustomEvent.ContactUpdated)
                    self.close()
                })
        }
    }
})
