module.exports = Em.Route.extend({
    couponOffer: Ember.inject.service(),

    campaign: Ember.inject.service(),

    organizationSubscription: Ember.inject.service(),

    dashboardNavigator: Em.inject.service(),

    beforeModel: function(params) {
        var self = this
        var organizationUrl = this.get('organization.url')
        var couponOffer = this.get('couponOffer')
        var campaign = this.get('campaign')

        if (params.queryParams && params.queryParams.payment_successful) {
            this.get('organizationSubscription').ensureLoaded()
        }

        if (!this.modelFor('organization').get('isLocked')) {
            this.get('dashboardNavigator').replaceUrlWithDashboard(organizationUrl)
        }

        return Em.RSVP.all([
            couponOffer.load(),
            campaign.load()
        ])
            .then(function() {
                self.set('showCouponOffer', couponOffer.isApplicable())
                self.set('showCampaign', campaign.isApplicable())
            })
    },

    model: function() {
        return this.get('organizationSubscription').ensureLoaded()
    },

    setupController: function(controller) {
        var requiredAddons = this.get('organizationSubscription').getRequiredAddons()
        controller.set('requiredAddons', requiredAddons)
        controller.set('couponOffer', this.get('couponOffer'))
        controller.set('showCouponOffer', this.get('showCouponOffer'))
        controller.set('campaign', this.get('campaign'))
        controller.set('showCampaign', this.get('showCampaign'))
        this.pollLocked()
    },

    resetController: function() {
        clearTimeout(this._pollTimeout)
    },

    pollLocked: function() {
        var self = this
        var orgId = this.modelFor('organization').get('id')
        this._pollTimeout = setTimeout(function() {
            Billy.Organization.findByIdQuery(orgId).promise
                .then(function(organization) {
                    // There's some problem with Billy Data that makes the `isLocked` property not update until the end of this run loop or something, so we delay to hack around that
                    setTimeout(function() {
                        if (!organization.get('isLocked')) {
                            self.replaceWith('user')
                        } else {
                            self.pollLocked()
                        }
                    }, 100)
                })
        }, 5000)
    }
})
