module.exports = Em.Mixin.create(require('./dirty-confirmation'), {
    hasConfirmed: false,

    isDirty: function(model) {
        return model.get('isDirty')
    },

    rollback: function(model) {
        model.rollback()
    },

    actions: {
        willTransition: function(transition) {
            this._super(transition)
            var self = this

            var controller = this.get('controller')
            var model = controller.get('model')

            if (model.get('isDestroying') || model.get('isDeleted')) {
                return
            }

            if (this.hasConfirmed || !this.isDirty(model, controller)) {
                this.hasConfirmed = false
                // Rollback in case it's a new and clean record
                transition.then(function() {
                    self.rollback(model, controller)
                })
                return
            }

            // Abort the transition
            transition.abort()

            // Show confirm window (if not already open)
            var win = this._getConfirmNavigationWindow()
            if (win) {
                return win
                    .then(function() {
                        self.hasConfirmed = true
                        transition.retry()
                            .then(function() {
                                self.rollback(model, controller)
                            })
                    })
            }
        }
    }
})
