var ListController = require('billy-webapp/ui/list/controller')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')
var getUserType = require('../utils/get-user-type')
var billsView = require('../mixins/bills-view')

module.exports = ListController.extend(ReactDirtyRouteMixin, billsView, {
    needs: ['application', 'user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    categoryKey: null,
    contactId: null,
    contactName: null,
    initialRoute: null,
    page: null,
    pageSize: null,
    period: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,
    status: null,

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership'),

    queryParams: {
        categoryKey: 'category',
        contactId: 'contactId',
        contactName: 'contactName',
        initialRoute: 'initialRoute',
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        status: 'status'
    },

    customActions: function() {
        return _.merge(this.get('_customActions'), this.get('_customActionsBills'), {
            navigate: function(e) {
                var route = e.detail.route
                var props = e.detail.props || { queryParams: {} }
                this.transitionToRoute(route, props)
            }
        })
    }.property('_customActions', '_customActionsBills')
})
