var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    segment: Em.inject.service(),

    template: require('../templates/components/daybook-attachments-popover'),

    classNames: ['daybook-attachments-popover'],

    position: 'below',

    matchWidth: false,

    minWidth: 400,

    align: 'left',

    transaction: null,

    actions: {
        didUploadFile: function() {
            this.get('segment').track('File Uploaded (Client)', {
                context: 'daybook'
            })
            this.trigger('didAttachFile')
        },

        showReceipts: function() {
            this.trigger('showReceipts')
        }
    }
})
