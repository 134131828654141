var i18n = require('i18n')
var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Object.extend({

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    request: function(method, url, opts) {
        opts = opts || {}
        if (opts.mask) {
            batmask.maskDelayed()
        }
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')

        var xhr
        var ret = new Em.RSVP.Promise(function(resolve, reject) {
            var isV2 = url.match(/^\/v2\//)
            var headers = {}

            if (isV2) {
                headers['X-Access-Token'] = accessToken
            } else {
                headers.Authorization = 'Bearer ' + accessToken
            }

            var finalUrl = opts.customUrl ? url : (ENV.isTest || url.indexOf('http') === 0 ? '' : ENV.newApiUrl) + url

            var req = {
                type: method,
                url: finalUrl,
                headers: headers
            }

            headers.Accept = opts.accept || isV2 ? 'application/json' : 'application/vnd.billy.v2+json'
            headers['Accept-Language'] = i18n.locale()

            if (opts.payload) {
                req.headers['Content-Type'] = opts.accept || isV2 ? 'application/json' : 'application/vnd.billy.v2+json'
                req.data = method === 'GET' ? opts.payload : JSON.stringify(opts.payload)
            }

            req.headers = _.defaultsDeep(opts.headers, req.headers)

            // Store so we can access outside in `.abort()`
            xhr = $.ajax(req)

            xhr
                .then(function(payload) {
                    Em.run.join(function() {
                        if (opts.mask) {
                            batmask.unmask()
                        }
                        resolve(payload)
                    })
                }, function(xhr) {
                    Em.run.join(function() {
                        if (opts.mask) {
                            batmask.unmask()
                        }
                        var payload = null
                        try {
                            payload = JSON.parse(xhr.responseText)
                        } catch(e) {
                            // Ignore
                        }

                        var output = { message: '', code: null }

                        if (payload && payload.errors && payload.errors.reduce) {
                            output = formatServiceErrorsList(payload.errors)
                        } else if (payload && payload.errorMessage) {
                            // V2 api style
                            output.message = payload.errorMessage
                        } else if (payload && payload.errors) {
                            // Integration-service api style
                            output = formatIntegrationServiceErrors(payload.errors)
                        } else if (payload && payload.message) {
                            output.message = payload.message
                        }

                        var e = new Error(xhr.status + ' - ' + (output.message || 'url: ' + url))
                        e.type = 'API_ERROR'
                        e.code = output.code
                        e.status = xhr.status
                        e.payload = payload
                        reject(e)
                    })
                })
        })

        ret.abort = function() {
            xhr.abort()
        }

        return ret
    },

    requestV2: function(method, url, options) {
        var self = this

        return this.request(method, url, options)
            .then(function(payload) {
                if (options.extract) {
                    return options.extract(payload)
                }

                return options.raw ? payload : payload.data
            })
            .catch(function(e) {
                switch (e.code) {
                case 'E_NOT_FOUND':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.API_V2_REQUEST, t('util.request.not_found'))
                    break
                case 'E_INTERNAL':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.API_V2_REQUEST, t('util.request.default_error.support'))
                    break
                case 'E_INTEGRATION':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.API_INTEGRATION_REQUEST, t('util.request.not_found'))
                    throw e
                default:
                    throw e
                }
            })
    },

    getData: function(url, query, opts) {
        return this.requestV2('GET', url, _.defaultsDeep({ payload: query }, opts || {}))
    },

    postData: function(url, data, opts) {
        return this.requestV2('POST', url, _.defaultsDeep({ payload: data }, opts || {}))
    },

    putData: function(url, data, opts) {
        return this.requestV2('PUT', url, _.defaultsDeep({ payload: data }, opts || {}))
    },

    post: function(url, opts) {
        return this.request('POST', url, opts)
    },

    put: function(url, opts) {
        var self = this

        return this.request('PUT', url, opts)
            .catch(function(e) {
                switch (e.code) {
                case 'E_NOT_FOUND':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.API_PUT, t('util.request.not_found'))
                    break
                case 'E_INTERNAL':
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.API_PUT, t('util.request.default_error.support'))
                    break
                default:
                    throw e
                }
            })
    },

    patch: function(url, opts) {
        return this.request('PATCH', url, opts)
    },

    delete: function(url, opts) {
        return this.request('DELETE', url, opts)
    }
})

function humanPointer(p) {
    p = Ember.String.dasherize(p.replace(/^.+\/(.+?)$/, '$1')).replace(/-/g, ' ')
    p = p.substring(0, 1).toUpperCase() + p.substring(1)
    return p
}

function formatServiceErrorsList(errors) {
    var output = { message: '', code: null }

    output.message = errors.reduce(function(memo, error) {
        if (!output.code) {
            output.code = error.code
        }
        if (memo.length > 0) {
            memo += ' '
        }
        var pointer = error.pointer || (error.source && error.source.pointer)
        if (pointer) {
            memo += humanPointer(pointer) + ': '
        }
        if (error.detail) {
            memo += error.detail.trim()
        } else if (error.message) {
            memo += error.message.trim()
        }
        if (['.', '!', '?'].indexOf(memo.substring(memo.length - 1, memo.length)) === -1) {
            memo += '.'
        }
        return memo
    }, '')

    return output
}

function formatIntegrationServiceErrors(errors) {
    var output = { message: '', code: 'E_INTEGRATION' }

    var keys = Object.keys(errors)
    var messages = keys.reduce(function(memo, errorKey) {
        memo.push(errors[errorKey])
        return memo
    }, [])

    output.message = 'Request errors: ' + messages.join(', ')
    return output
}
