var t = require('i18n').t
var qs = require('qs')
var Cookies = require('js-cookie')
var cookieKey = require('../constants/cookieKey')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var Scope = require('../utils/scope')
var normalizeOriginatorType = require('../utils/normalize-originator-type')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    auth: Ember.inject.service(),

    user: Em.computed.alias('controllers.user.model'),

    externalInvoiceType: null,

    externalInvoiceId: null,

    isDaybookTransaction: function() {
        var originator = this.get('model.originator')
        return originator instanceof Billy.DaybookTransaction
    }.property('model.originator'),

    isVoidedOrVoid: function() {
        return this.get('isVoided') || this.get('isVoid')
    }.property('isVoided', 'isVoid'),

    hasDaybookReference: function() {
        return this.get('model.originator.daybook') !== null
    }.property('model.originator.daybook'),

    isEditingAttachments: false,

    isUploadingAttachments: false,

    isConversionBalance: function() {
        var originator = this.get('originator')
        return originator instanceof Billy.ConversionBalance
    }.property('originator.{type}'),

    isBillOriginator: function() {
        var originator = this.get('originator')
        return originator instanceof Billy.Bill
    }.property('originator.{type}'),

    isExternalInvoice: function() {
        var originator = this.get('originator')
        return originator instanceof Billy.ExternalInvoice
    }.property('originator.{type}'),

    loadInvoiceType: function() {
        var self = this
        var originator = this.get('originator')

        if (originator instanceof Billy.ExternalInvoice) {
            var originatorId = originator.get('id')
            this.get('api').request('GET', '/v2/externalInvoices/' + originatorId)
                .then(function(response) {
                    var externalInvoice = response.externalInvoice
                    self.set('externalInvoiceType', externalInvoice.type)
                    self.set('externalInvoiceId', externalInvoice.externalId)
                })
        }
    }.observes('originator.{type}'),

    originatorType: function() {
        var originator = this.get('originator')
        if (originator instanceof Billy.ExternalInvoice) {
            var externalInvoiceType = this.get('externalInvoiceType')
            return externalInvoiceType === 'creditNote' ? 'creditnote' : externalInvoiceType
        }
        return originator.route
    }.property('originator.{type}', 'externalInvoiceType'),

    originatorId: function() {
        var originator = this.get('originator')
        if (originator instanceof Billy.ExternalInvoice) {
            return this.get('externalInvoiceId')
        }
        return originator.get('id')
    }.property('originator.{type}', 'externalInvoiceId'),

    previousAttachments: null,

    hasAttachments: function() {
        return this.get('originator.attachments.length') > 0 || this.get('isUploadingAttachments')
    }.property('originator.attachments.length', 'isUploadingAttachments'),

    hasPreviousAttachments: function() {
        return this.get('previousAttachments') && this.get('previousAttachments').length > 0
    }.property('previousAttachments'),

    timelineUrl: function() {
        var transaction = this.get('model')
        return '/organizations/' + transaction.get('organization.id') + '/transactions/' + transaction.get('id') + '/timeline'
    }.property('model{organization,id}'),

    isTransactionAttachmentEnabled: function() {
        var scopes = [Scope.BillAttachmentRead]
        // @todo doubled scopes-check
        return this.get('auth').isAuthorized(scopes, false)
    }.property(),

    createdBy: function() {
        var formatValue = function(label, value) {
            return label + ': ' + value
        }

        var transaction = this.get('model')
        var creator = transaction.get('creator')
        var createdBy

        if (creator) {
            switch (creator.type) {
            case 'user':
                createdBy = formatValue(t('transactions.show.created_by.type.user'), creator.name)
                break
            case 'internalBillyService':
                createdBy = formatValue(t('transactions.show.created_by.type.system'), creator.name)
                break
            case 'oAuthActor':
                createdBy = formatValue(t('transactions.show.created_by.type.api'), creator.name)
                break
            }
        }

        return createdBy
    }.property('model.creator'),

    fetchAttachmentsHistory: function() {
        var self = this
        var transaction = this.get('model')
        var originator = this.get('originator')
        var originatorType = normalizeOriginatorType(originator && originator.get('type'))

        if (!transaction || !originator || !originatorType) {
            return
        }

        var ownerReference = originatorType + ':' + originator.get('id')

        return Billy.AttachmentHistory.findByQuery({
            organizationId: transaction.get('organization.id'),
            ownerReference: ownerReference,
            include: 'attachment.attachmentHistory:embed'
        }).promise
            .then(function(previousAttachments) {
                self.set('previousAttachments', previousAttachments.content)
            })
    }.observes('originator.{type}'),

    actions: {
        toggleEditAttachments: function() {
            this.set('isEditingAttachments', true)
        },

        rollbackAttachments: function() {
            this.get('model.originator').rollback()
            this.set('isEditingAttachments', false)
        },

        saveAttachments: function() {
            var self = this
            this.get('model.originator').save({
                embed: ['attachments']
            })
                .mask()
                .success(function() {
                    self.set('isEditingAttachments', false)
                })
                .error(function(errorMessage, payload) {
                    if (payload && payload.validationErrors) {
                        Object.values(payload.validationErrors).some(function(validationError) {
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.DAYBOOK_IMPORT_UPLOAD_FILE, validationError.message, NOTIFICATION_VARIANT.ERROR)
                        })
                    } else {
                        self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.DAYBOOK_IMPORT_UPLOAD_FILE, errorMessage, NOTIFICATION_VARIANT.ERROR)
                    }
                })
        },

        void: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('transactions.show.confirm_void'), t('transactions.show.yes_void'), t('no'))
                .then(function() {
                    self.get('model.originator')
                        .save({
                            properties: {
                                state: 'voided'
                            }
                        })
                        .mask()
                })
        },

        reverse: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('transactions.show.confirm_reversal'), t('transactions.show.yes_reverse'), t('no'))
                .then(function() {
                    self.get('model.originator')
                        .save({
                            properties: {
                                state: 'voided'
                            }
                        })
                        .mask()
                        .then(function() {
                            var daybook = self.get('model.originator.daybook')
                            var transactionId = self.get('model.originator.id')
                            var queryParams = {
                                recreate_transaction: transactionId
                            }

                            if (daybook) {
                                self.transitionToRoute('daybook', daybook, {
                                    queryParams: queryParams
                                })
                            }
                        })
                })
        },

        copyToDaybook: function() {
            var daybook = this.get('model.originator.daybook')
            var transactionId = this.get('model.originator.id')
            var queryParams = {
                recreate_transaction: transactionId,
                copy_attachments: false
            }

            if (daybook) {
                this.transitionToRoute('daybook', daybook, {
                    queryParams: queryParams
                })
            }
        },

        backToList: function() {
            var transactionsListQuery = Cookies.get(cookieKey.transactionsListQuery) || ''
            Cookies.remove(cookieKey.transactionsListQuery)
            this.transitionToRoute('transactions.index', { queryParams: qs.parse(transactionsListQuery, { ignoreQueryPrefix: true, allowDots: true }) })
        }
    }
})
