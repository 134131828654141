var batmask = require('batmask')
var t = require('i18n').t
var storage = require('storage')
var InfiniteScrollController = require('../mixins/infinite-scroll-controller')
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

var APPROVE_BATCH_SIZE = 50

module.exports = Em.Controller.extend(Em.Evented, InfiniteScrollController, {
    queryParams: [
        'recreate_transaction',
        'copy_attachments'
    ],

    recreate_transaction: null,

    copy_attachments: true,

    needs: ['organization', 'daybookBalanceAccounts', 'daybookVoucherNo', 'exports', 'user'],

    user: Em.computed.alias('controllers.user.model'),

    segment: Em.inject.service(),

    organization: Em.computed.alias('controllers.organization.model'),

    organizationSubscription: Em.inject.service(),

    constraints: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    subscriptionLimits: Ember.inject.service(),

    showCreateDaybookOption: Em.computed.alias('controllers.user.model.canCreateDaybooks'),

    daybook: null,

    daybooks: function() {
        var organization = this.get('organization')
        if (!organization) {
            return
        }
        return Billy.Daybook.filter({
            query: {
                isArchived: false,
                organization: organization
            }
        })
    }.property('organization'),

    hasMultipleDaybooks: Em.computed.gt('daybooks.length', 1),

    isNotDefault: Em.computed('organization', 'daybook', function() {
        return this.get('organization.defaultDaybook.id') !== this.daybook.id
    }),

    daybookOptions: function() {
        return this.get('daybooks').map(function(daybook) {
            return Em.O({
                value: daybook,
                name: daybook.get('name')
            })
        })
    }.property('daybooks.@each', 'daybooks.@each.name'),

    selectedDaybook: Em.computed.oneWay('daybook'),

    selectedDaybookDidChange: function() {
        var selectedDaybook = this.get('selectedDaybook')
        if (selectedDaybook && selectedDaybook !== this.get('daybook')) {
            storage('defaultDaybookId_' + this.get('organization.id'), selectedDaybook.get('id'))
            this.replaceRoute('daybook', selectedDaybook)
        }
    }.observes('selectedDaybook'),

    transactions: null,

    loadTransactions: function() {
        var self = this
        this.set('isLoaded', false)
            .set('content', null)

        var transactions

        var query = {
            organizationId: this.get('organization.id'),
            daybookId: this.get('daybook.id'),
            state: 'draft',
            sortProperty: 'priority',
            sortDirection: 'ASC',
            include: 'daybookTransaction.lines,daybookTransaction.attachments'
        }

        Em.RSVP.all([
            Billy.DaybookTransaction.find(query).promise,
            this.get('controllers.daybookVoucherNo').load(query.daybookId)
        ])
            .then(function(vals) {
                var transactionsRecordArray = vals[0]

                transactions = transactionsRecordArray.get('content').copy() // we just want the raw array, not the BD.RecordArray
                transactionsRecordArray.destroy()

                // Ensure all transactions' lines are loaded before inserting them
                return Em.RSVP.all(transactions.map(function(t) {
                    return t.get('lines').promise
                }))
            })
            .then(function() {
                var content = Em.ArrayController.create({
                    container: self.container,
                    parentController: self,
                    target: self,
                    model: transactions,
                    itemController: 'daybook-edit-transaction'
                })
                self.set('content', content)
                    .set('isLoaded', true)
                if (content.get('length') === 0) {
                    self.addTransaction()
                }

                if (self.get('recreate_transaction')) {
                    self.recreateTransaction()
                }
            }, function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_TRANSACTIONS_LOAD, t('util.request.default_error'))
                self.set('isLoaded', true)
            })
    },

    recreateTransaction: function() {
        var self = this

        // Look up the transaction to recreate
        Billy.DaybookTransaction.findByIdQuery(this.get('recreate_transaction'), {
            organizationId: this.get('organization.id'),
            daybookId: this.get('daybook.id')
        }).promise
            .then(function(transaction) {
                self.send('duplicateTransaction', transaction, self.get('copy_attachments'))
                self.set('recreate_transaction', null)
                self.set('copy_attachments', true)
                self.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
            })
    },

    numTransactions: function() {
        return this.get('content.length') || 0
    }.property('content.length'),

    focusedTransaction: null,

    activeToolsLine: null,

    selection: function() {
        return []
    }.property(),

    allSelected: function() {
        var selected = this.get('selection.length')
        return selected > 0 && this.get('content.length') === selected
    }.property('content.length', 'selection.length'),

    someSelected: function() {
        return !this.get('allSelected') && this.get('selection.length') > 0
    }.property('selection.length', 'allSelected'),

    validateTransactions: function(transactionControllers) {
        var isValid = true
        transactionControllers.forEach(function(c) {
            if (!c.validate()) {
                isValid = false
            }
        })
        return isValid
    },

    approveTransactions: function(transactionControllers) {
        var self = this
        var hasExceededPostingLimit = this.get('organizationSubscription').hasExceededPostingLimit()

        if (!this.validateTransactions(transactionControllers)) {
            this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_TRANSACTIONS_APPROVE_RECORD_INVALID, t('daybook.index.invalid_record'))
            return
        }

        if (hasExceededPostingLimit) {
            return this.get('constraints').showUpgradeOverlay('posting_limit', 'daybook_index')
        }

        // TODO: Show progress window if more than x
        batmask.maskDelayed()
        transactionControllers = transactionControllers.map(mapSelf).sortBy('priority')
        var total = transactionControllers.get('length')
        this.set('isApproving', true)
            .set('approveTotal', total)
            .set('approvedCount', 0)
        var promise = Em.RSVP.resolve()
        var batch
        for (var i = 0; i < total; i += APPROVE_BATCH_SIZE) {
            batch = transactionControllers.slice(i, i + APPROVE_BATCH_SIZE)
            promise = promise.then(this.approveTransactionBatch.bind(this, batch))
        }
        promise
            .catch(function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_TRANSACTIONS_APPROVE_RECORD_INVALID, t('daybook.index.invalid_record'))
            })
            .finally(function() {
                self.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
                batmask.unmask()
                self.afterRemovedTransactions()
                self.get('controllers.daybookBalanceAccounts').loadRecords()
            })
    },

    approveTransactionBatch: function(transactionControllers) {
        var self = this
        return new Em.RSVP.Promise(function(resolve, reject) {
            BD.saveRecords(transactionControllers.mapBy('content'), {
                embed: ['lines', 'attachments'],
                properties: { state: 'approved' }
            })
                .success(function() {
                    var approvedAmount = transactionControllers.get('length')
                    self.removeTransactions(transactionControllers)
                    self.incrementProperty('approvedCount', approvedAmount)

                    for (var i = 0; i < approvedAmount; i += 1) {
                        self.get('segment').track('Daybook Transaction Approved (Client)')
                    }

                    resolve()
                })
                .error(function(errorMessage) {
                    reject(new Error(errorMessage))
                })
        })
    },

    removeTransactions: function(transactionControllers) {
        if (!Em.isArray(transactionControllers)) {
            transactionControllers = [transactionControllers]
        }
        this.get('content').removeObjects(transactionControllers)
        this.get('selection').removeObjects(transactionControllers)
        this.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
    },

    afterRemovedTransactions: function() {
        var self = this
        var daybookId = this.get('daybook.id')
        var nextVoucherNoPromise = this.get('controllers.daybookVoucherNo').load(daybookId)

        if (this.get('content.length') === 0) {
            // We have to wait until we have reloaded next-voucher-no to insert another transaction
            this.set('isLoaded', false)
            nextVoucherNoPromise
                .then(function() {
                    self.set('isLoaded', true)
                    self.addTransaction()
                    self.trigger('newTransaction')
                })
        }
    },

    addTransaction: function() {
        var organization = this.get('organization')
        var daybook = this.get('daybook')
        var transactions = this.get('content')
        var transaction = Billy.DaybookTransaction.createRecord({
            organization: organization,
            daybook: daybook,
            state: 'draft',
            entryDate: transactions.get('lastObject.entryDate') || moment.utc()
        })
        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            contraAccount: daybook.get('defaultContraAccount'),
            currency: organization.get('baseCurrency'),
            priority: 1
        })
        this.pushTransaction(transaction)
        this.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
    },

    pushTransaction: function(transaction) {
        var transactions = this.get('content')
        var lastTransaction = transactions.get('lastObject')

        transaction.set('priority', lastTransaction ? lastTransaction.get('priority') + 1 : 1)
        transactions.get('content').pushObject(transaction)

        if (!transaction.get('voucherNo')) {
            var voucherNo = this.getNextVoucherNo()
            transaction.set('voucherNo', voucherNo)
        }

        transaction.save()
    },

    getNextVoucherNo: function() {
        var transactions = this.get('content')
        var lastTransaction = transactions.get('lastObject')
        var nextVoucherNoController = this.get('controllers.daybookVoucherNo')

        // If the last transaction has a voucher no. that's higher than the one we would get from `next-voucher-no`, then we add one to the last one instead
        var lastN = lastTransaction ? parseInt(lastTransaction.get('voucherNo')) || 0 : 0
        var curN = nextVoucherNoController.peek()
        var voucherNo = lastN > curN ? lastN + 1 : nextVoucherNoController.getAndIncrement()

        return voucherNo
    },

    blurredTransactions: {},

    hasFooter: function(k, v) {
        if (arguments.length > 1) {
            storage('daybookhasFooter', v ? '1' : '0')
        } else {
            v = storage('daybookhasFooter') !== '0'
        }
        return v
    }.property(),

    footerToggleIcon: function() {
        return this.get('hasFooter') ? 'icons/caret-down' : 'icons/caret-up'
    }.property('hasFooter'),

    blurTransaction: function(transactionController) {
        if (this.blurredTransactions[Em.guidFor(transactionController)]) {
            delete this.blurredTransactions[Em.guidFor(transactionController)]
            if (this.get('focusedTransaction') === transactionController) {
                this.set('focusedTransaction', null)
            }
            transactionController.set('focused', false)
            transactionController.save()
            this.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)
        }
    },

    actions: {
        toggleFooter: function() {
            this.toggleProperty('hasFooter')
        },

        focus: function(transactionController) {
            // If this transaction was just blurred, we cancel the blur behavior
            delete this.blurredTransactions[Em.guidFor(transactionController)]

            // Nothing to do if the transaction is already focused
            var oldFocused = this.get('focusedTransaction')
            if (oldFocused === transactionController) {
                return
            }

            // Blur the old focused transaction immediately
            if (oldFocused) {
                this.blurTransaction(oldFocused)
            }

            // Set the transaction as the focused one
            this.set('focusedTransaction', transactionController)
            transactionController.set('focused', true)
        },

        blur: function(transactionController) {
            // Cache the transaction that was blurred, and then perform the blur behavior on the next run loop,
            // unless a focus event for the same transaction is triggered
            this.blurredTransactions[Em.guidFor(transactionController)] = true
            Em.run.next(this, function() {
                if (this.get('isDestroying')) {
                    return
                }
                this.blurTransaction(transactionController)
            })
        },

        deleteTransaction: function(transactionController) {
            this.removeTransactions(transactionController)
            transactionController.get('content').deleteRecord().promise
                .then(this.afterRemovedTransactions.bind(this))
        },

        deleteSelected: function() {
            var self = this
            var customEventService = this.get('customEvent')
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('daybook.index.confirm_delete_transactions'), t('daybook.index.confirm_delete_transactions_yes'), t('cancel'))
                .then(function() {
                    batmask.maskDelayed()
                    var selection = self.get('selection')
                    BD.deleteRecords(selection.mapBy('content'))
                        .success(function() {
                            self.removeTransactions(selection)
                            self.afterRemovedTransactions()
                            customEventService.dispatchEvent(CustomEvent.UploadsUpdated)
                        })
                        .error(function(errorMessage) {
                            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_DELETE, errorMessage)
                        })
                        .on('complete', function() {
                            batmask.unmask()
                        })
                })
        },

        toggleSelectAll: function() {
            if (this.get('selection.length') > 0) {
                this.get('selection').forEach(function(c) {
                    c.set('selected', false)
                })
                this.set('selection', [])
            } else {
                var selection = this.get('content').map(mapSelf)
                this.set('selection', selection)
                selection.forEach(function(c) {
                    c.set('selected', true)
                })
            }
        },

        toggleSelect: function(transactionController) {
            var selection = this.get('selection')
            if (transactionController.get('selected')) {
                selection.removeObject(transactionController)
                transactionController.set('selected', false)
            } else {
                selection.addObject(transactionController)
                transactionController.set('selected', true)
            }
        },

        create: function() {
            var self = this

            var daybook = Billy.Daybook.createRecord({
                organization: this.get('controllers.organization.model'),
                isTransactionSummaryEnabled: true
            })

            this.container.lookup('component:daybook-edit-window')
                .set('content', daybook)
                .set('callback', function(daybook) {
                    self.selectedDaybook = daybook
                    self.replaceRoute('daybook', daybook)
                })
                .show()
        },

        edit: function() {
            var self = this
            this.container.lookup('component:daybook-edit-window')
                .set('content', this.get('daybook'))
                .set('callback', function() {
                    self.get('controllers.daybookBalanceAccounts').loadRecords()
                })
                .show()
        },

        export: function(format) {
            this.get('controllers.exports').send('exportDaybook', this.get('daybook.id'), format)
        },

        archive: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('daybook.index.confirm_delete_daybook'), t('daybook.yes_delete'), t('cancel'))
                .then(function() {
                    self.get('daybook').set('isArchived', true).save()
                        .success(function() {
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.JOURNAL_DELETE, t('daybook.index.was_deleted'))
                            var defaultDaybook = self.get('organization.defaultDaybook')
                            self.selectedDaybook = defaultDaybook
                            self.replaceRoute('daybook', defaultDaybook)
                        })
                        .error(function(errorMessage, payload) {
                            self.get('daybook').set('isArchived', false)
                            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.DAYBOOK_ARCHIVE, payload.errorMessage)
                        })
                })
        },

        addTransaction: function() {
            this.addTransaction()
            this.send('showAll')
            this.trigger('newTransaction')
        },

        approveSelected: function() {
            var self = this
            var newExpensesCount = self.get('selection').length

            self.get('subscriptionLimits').checkForExpenseLimitWithMultipleAndExecute(newExpensesCount, function() {
                self.container.lookup('util:dialog')
                    .confirm(null, t('daybook.index.confirm_approve_selected_transactions'), t('daybook.index.confirm_approve_transactions_yes'))
                    .then(function() {
                        self.approveTransactions(self.get('selection'))
                    })
            })
        },

        approveAll: function() {
            var self = this
            var newExpensesCount = self.get('content.model').length

            self.get('subscriptionLimits').checkForExpenseLimitWithMultipleAndExecute(newExpensesCount, function() {
                self.container.lookup('util:dialog')
                    .confirm(null, t('daybook.index.confirm_approve_all_transactions'), t('daybook.index.confirm_approve_transactions_yes'))
                    .then(function() {
                        self.approveTransactions(self.get('content'))
                    })
            })
        },

        duplicateTransaction: function(sourceController, copyAttachments) {
            var source = null

            if (sourceController.get('content')) {
                source = sourceController.get('content')
            } else {
                // Assume it is a raw DaybookTransaction model
                source = sourceController
                source.set('state', 'draft')
            }

            // Copy transaction
            var props = source.getProperties([
                'organization',
                'daybook',
                'state',
                'type',
                'entryDate'
            ])

            var transaction = Billy.DaybookTransaction.createRecord(props)

            // Copy each line
            source.get('lines').forEach(function(line) {
                var props = line.getProperties([
                    'text',
                    'account',
                    'taxRate',
                    'amount',
                    'paidInvoice',
                    'paidExternalInvoice',
                    'paidBill',
                    'side',
                    'contraAccount',
                    'currency',
                    'priority'
                ])
                props.daybookTransaction = transaction
                Billy.DaybookTransactionLine.createRecord(props)
            })

            // Copy each attachment
            if (copyAttachments) {
                source.get('attachments').forEach(function(attachment) {
                    var props = attachment.getProperties([
                        'organization',
                        'owner',
                        'file'
                    ])

                    props.owner = transaction
                    Billy.Attachment.createRecord(props)
                })
            }

            this.pushTransaction(transaction)

            // Make sure view focuses the new transaction
            this.send('showAll')
            this.trigger('newTransaction')
        },

        showConsequences: function() {
            var modal = this.container.lookup('component:daybook-consequences-modal')
            modal.set('daybookId', this.get('daybook.id'))
            modal.show()
        }
    }
})

function mapSelf(item) {
    return item
}
