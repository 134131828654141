var getHashQueryParams = require('../utils/get-hash-query-params')
var assign = require('lodash').assign

module.exports = Em.Route.extend({
    userOrganizations: Ember.inject.service(),

    model: function(params, transition) {
        var queryParams = transition.queryParams
        var self = this
        var userOrganizations = this.get('userOrganizations')
        var hashQueryParams = getHashQueryParams()
        var queryString = window.location.search
        var urlParams = new URLSearchParams(queryString)
        var couponFromUrl = urlParams.get('coupon')

        if (couponFromUrl) {
            queryParams.code = couponFromUrl
        }

        return userOrganizations.all()
            .then(function(organizations) {
                var organizationToActivate = userOrganizations.get('lastOrganizationUrl') || organizations[0].url

                if (organizations.length) {
                    try {
                        self.replaceWith(params.route, organizationToActivate, { queryParams: assign({}, queryParams, hashQueryParams) })
                    } catch(e) {
                        var url = '/' + organizationToActivate + '/' + params.route
                        self.replaceWith(url, { queryParams: assign({}, queryParams, hashQueryParams) })
                    }
                } else {
                    self.replaceWith('index')
                }
            })
    }
})
