/**
 * Normalizes a locale string by converting underscores to hyphens
 * to align with ISO 639-1 standards.
 *
 * Additionally, if the locale is `'en-US'`, it is converted to `'en-GB'`
 * due to Zervant's Userflow setup, which uses `'en-GB'` as the default
 * English locale.
 *
 * @param {string} locale - The locale string in the format `xx_XX`.
 * @returns {string} - The normalized locale in the format `xx-XX`, with
 *                     `'en-US'` mapped to `'en-GB'`.
 */
module.exports = function(locale) {
    var normalizedLocale = locale.replace('_', '-')

    if (normalizedLocale === 'en-US') {
        normalizedLocale = 'en-GB'
    }

    return normalizedLocale
}
