var numeral = require('numeral')
var mathjs = require('mathjs')
var numeralLanguages = require('numeral-languages')
var lang = numeralLanguages[numeral.language()] || numeralLanguages.en
var thousandsDelimiter = lang.delimiters && lang.delimiters.thousands ? lang.delimiters.thousands : ','
var decimalDelimiter = lang.delimiters && lang.delimiters.decimal ? lang.delimiters.decimal : '.'
var thousandsRegExp = new RegExp('\\' + thousandsDelimiter, 'g')
var decimalsRegExp = new RegExp('\\' + decimalDelimiter, 'g')

var MATH_OPERATORS = /([\+\-\*\/\(\)])/g

var OPERATORS = ['+', '-', '*', '/']
var ALLOWED_MATH_CHARACTERS = ['(', ')', '+', '-', '*', '/']

/*
  Strips right hand operators from expression, so:
    ['1', '+', '2', '/', '+']
  becomes:
    ['1', '+', '2']
  so user is able to leave last operator in the input.
*/
var clearRightHandExpressions = function(expressions) {
    var reversed = expressions.reverse()
    var indexOfNonOperator = reversed.findIndex(function(part) { return !OPERATORS.includes(part) })

    return reversed.slice(indexOfNonOperator).reverse()
}

var getDelimitedValue = function(value) {
    // Removes thousands delimiter from value
    var removedThousandsValue = value.replace(thousandsRegExp, '')
    // Replaces decimals delimiter with dot, so we can cast to float properly
    var updatedDecimalsValue = removedThousandsValue.replace(decimalsRegExp, '.')

    return updatedDecimalsValue
}

var evaluateMathExpression = function(amount) {
    try {
    /*
      Wraps special characters with # for easier splitting.
      Runs filter to discard empty values.
    */
        var expressionParts = amount
            .replace(MATH_OPERATORS, '#$1#')
            .split('#')
            .filter(function(part) { return part.trim().length > 0 })

        // Removes right-hand operators
        var clearedExpressions = clearRightHandExpressions(expressionParts)

        // Sanitizes parts of expression, leaves math characters, casts numbers to floats
        var sanitizedParts = clearedExpressions.map(function(part) {
            if (ALLOWED_MATH_CHARACTERS.includes(part)) {
                return part
            }

            var partToNumber = parseFloat(getDelimitedValue(part))
            return !isNaN(partToNumber) ? partToNumber : 0
        })

        var sanitizedExpression = sanitizedParts.join('')

        var evaluatedResult = mathjs.evaluate(sanitizedExpression)

        if (!isFinite(evaluatedResult)) {
            return 0
        }

        return Math.round(evaluatedResult * 100) / 100 // rounding float to 2 decimal places
    } catch(error) {
        return 0
    }
}

module.exports = evaluateMathExpression
