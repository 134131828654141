var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')

module.exports = ListController.extend({
    needs: ['contactBalancePayment'],

    payment: Em.computed.alias('controllers.contactBalancePayment.model'),

    isLazy: false,

    records: Em.computed.alias('payment.associations'),

    itemControllerClass: ListItemController.extend({
        positiveAmount: function() {
            return -this.get('amount')
        }.property('amount'),

        isInvoice: function() {
            var subject = this.get('subject')
            return subject instanceof Billy.Invoice
        }.property('subject')
    }),

    columns: [
        Column.create({
            name: 'description',
            header: t('description'),
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if isInvoice}}' +
                        '{{#link-to subject.route subject}}{{subject.genericDescription}}{{/link-to}}' +
                    '{{else}}' +
                        '<link-module {{bind-attr route=subject.route route-param=subject.id text=description}} />' +
                    '{{/if}}'
                )
            })
        }),
        MoneyColumn.create({
            name: 'amount',
            header: t('money_amount'),
            width: 120,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{money positiveAmount currencyBinding="currency"}}'
                )
            })
        })
    ],

    totals: function() {
        var totals = []
        var payment = this.get('payment')
        var amount = payment.get('amount')
        var currency = payment.get('currency')

        totals.push({
            itemClass: 'double-border-under bold',
            label: t('contact_balance_payment.total_amount'),
            values: {
                amount: Billy.money(amount, currency)
            }
        })
        return totals
    }.property('records.@each.amount', 'payment.subjectCurrency', 'payment.cashAmount', 'payment.cashAccount.currency', 'payment.cashExchangeRate')
})
