var t = require('i18n').t
var batmask = require('batmask')
var analytics = require('../utils/analytics')

module.exports = Em.ObjectController.extend({
    needs: ['user', 'organization'],

    api: Em.inject.service(),

    auth: Em.inject.service(),

    startGuide: Em.inject.service(),

    user: Em.computed.alias('controllers.user.model'),

    userOrganizations: Em.inject.service(),

    segment: Em.inject.service(),

    organization: null,

    enterCompany: true,

    manualCompanyEntry: false,

    confirmCompany: false,

    isCVRValid: null,

    displayWarning: false,

    init: function() {
        this.get('segment').track('xxx - iam org create viewed')
    },

    dkFlag: function() {
        return Billy.flagIcon('dk')
    }.property(),

    hasFooter: function() {
        return this.get('confirmCompany') || this.get('manualCompanyEntry')
    }.property('confirmCompany', 'manualCompanyEntry'),

    showSearchAgainLink: function() {
        return this.get('confirmCompany') || !this.get('visuallyHideManualCompanyEntry')
    }.property('confirmCompany', 'visuallyHideManualCompanyEntry'),

    isValid: function() {
        var isValid = true
        var organization = this.get('organization')
        var needsIndustry = !this.get('hidePickIndustryDropdown')
        var needsBaseCurrency = this.get('needsBaseCurrency')

        if (Em.isEmpty(organization.get('name'))) {
            organization.set('errors.name', t('required_field'))
            isValid = false
        }

        if (Em.isEmpty(organization.get('country'))) {
            organization.set('errors.country', t('required_field'))
            isValid = false
        }

        if (needsIndustry && Em.isEmpty(organization.get('industryGroup'))) {
            organization.set('errors.industryGroup', t('required_field'))
            isValid = false
        }

        if (needsBaseCurrency && Em.isEmpty(organization.get('baseCurrency'))) {
            organization.set('errors.baseCurrency', t('required_field'))
            isValid = false
        }

        return isValid
    },

    cvrObserver: function() {
        var cvr = this.get('organization.registrationNo')

        if (!cvr) {
            return
        }

        var country = this.get('organization.country')

        if (!country) {
            return
        }

        if (country.get('id') === 'DK') {
            this.setCvrStatus(cvr)
        } else {
            this.set('isCVRValid', false)
        }
    }.observes('organization.registrationNo'),

    setCvrStatus: function(cvr) {
        var self = this
        var sanitizedCvr = cvr.replace(/[^0-9]/g, '')

        this.get('api')
            .request('GET', '/cvr/dk/companies/' + sanitizedCvr, {
                mask: false
            })
            .then(function() {
                self.set('isCVRValid', true)
            }).catch(function() {
                self.set('isCVRValid', false)
            })
    },

    // Due to a defect in ember-super-field, these elements need to be visually hidden, rather than removed from the DOM.
    visuallyHideAutoCompanyEntry: function() {
        return this.get('confirmCompany') || this.get('manualCompanyEntry')
    }.property('confirmCompany', 'manualCompanyEntry'),

    visuallyHideManualCompanyEntry: function() {
        return !this.get('visuallyHideAutoCompanyEntry') || this.get('confirmCompany')
    }.property('visuallyHideAutoCompanyEntry', 'confirmCompany'),

    hidePickIndustryDropdown: function() {
        var countryId = this.get('organization.country.id')
        var isCVRValid = this.get('isCVRValid')

        if (countryId !== 'DK') {
            return false
        } else if (_.isEmpty(this.get('organization.registrationNo'))) {
            return false
        } else {
            return isCVRValid
        }
    }.property('isCVRValid', 'organization.country', 'organization.registrationNo'),
    // End visual hides.

    createNewOrganization: function(template) {
        template = template || {
            name: '',
            subscriptionPlan: 'premium',
            country: Billy.Country.find('DK'),
            email: this.get('user.email'),
            phone: this.get('user.phone'),
            analytics: this.getOrganizationAnalytics()
        }

        var organization = Billy.Organization.createRecord(template)
        this.set('organization', organization)
        this.set('displayWarning', false)
    },

    showManualEntryForm: function() {
        this.set('manualCompanyEntry', true)
        this.set('enterCompany', true)
        this.set('confirmCompany', false)
    },

    needsBaseCurrency: function() {
        var country = this.get('organization.country')
        return country && !country.get('currency')
    }.property('organization.country', 'organization.country.currency'),

    needsBaseCurrencyDidChange: function() {
        if (!this.get('needsBaseCurrency') && this.get('organization')) {
            delete this.get('organization').needsBaseCurrency
        }
    }.observes('needsBaseCurrency'),

    isMissingInput: function() {
        return (!this.get('organization.email') || !this.get('organization.phone'))
    }.property('organization.{email,phone}'),

    getOrganizationAnalytics: function() {
        var analyticsData = analytics.getSignupAnalyticsData()

        if (!analyticsData || !analyticsData.analytics) {
            return ''
        }

        return analyticsData.analytics
    },

    actions: {
        searchAgain: function() {
            // eslint-disable-next-line compat/compat
            var companyFinder = document.querySelector('.company-finder input')

            if (companyFinder) {
                companyFinder.value = ''
            }

            this.searchingAgain = true
            this.set('manualCompanyEntry', false)
            this.set('enterCompany', true)
            this.set('confirmCompany', false)
        },

        switchToManualEntry: function() {
            this.createNewOrganization()
            this.showManualEntryForm()
        },

        editDetails: function() {
            this.showManualEntryForm()
        },

        didSelectCompany: function(item) {
            var organization = this.get('organization')

            if (
                !organization ||
                (item && item.cvr && item.cvr !== organization.get('registrationNo')) ||
                (item && this.searchingAgain)
            ) {
                this.searchingAgain = false
                this.set('enterCompany', false)
                this.set('confirmCompany', true)
                this.createNewOrganization({
                    name: item.name,
                    street: item.streetAndNumber,
                    zipcode: item.zipcode,
                    city: item.city,
                    registrationNo: item.cvr,
                    subscriptionPlan: 'premium',
                    country: Billy.Country.find('DK'),
                    email: this.get('user.email'),
                    phone: this.get('user.phone'),
                    analytics: this.getOrganizationAnalytics()
                })
            }
        },

        nextStep: function() {
            if (!this.isValid()) {
                return
            }

            var self = this
            var organization = this.get('organization')
            organization.set('analytics', this.getOrganizationAnalytics())

            batmask.maskDelayed()

            organization
                .save()
                .then(function(res) {
                    // If there's no res, then the save was a no-op
                    if (res) {
                        analytics.removeSignupAnalyticsData()
                        return self.get('userOrganizations').bootstrapByUrl(res.organizations[0].url)
                    }
                })
                .then(function() {
                    return self.get('startGuide').completeStep()
                })
                .then(function() {
                    return self.get('auth').load()
                })
                .then(function() {
                    self.send('goToNextStep', organization)
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
