var Window = require('ember-window')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/transaction-manual-window'),

    classNames: ['transaction-window-view'],

    customEvent: Ember.inject.service(),

    segment: Em.inject.service(),

    content: null,

    // Bound to attachments-list in template
    isUploading: null,

    actions: {
        didUploadFile: function() {
            this.get('segment').track('File Uploaded (Client)', {
                context: 'transactions'
            })
        },

        addLine: function() {
            var transaction = this.get('content')
            var priority = (transaction.get('lines.lastObject.priority') || 0) + 1
            Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: transaction,
                priority: priority
            })
        },
        approve: function() {
            // Set transaction to approved and save
            var transaction = this.get('content')
            var self = this

            // Check for unfinished attachment uploads
            if (this.get('isUploading')) {
                transaction.set('error', t('still_uploading'))
                return
            }

            // Reset error message
            transaction.set('error', null)
            // Save
            transaction.set('state', 'approved')
            transaction.save({
                embed: ['lines', 'attachments']
            })
                .mask()
                .success(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TRANSACTION_APPROVED, t('transaction.window.approval_success'))
                    self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
                    self.close()

                    self.get('segment').track('Transaction Template Approved (Client)', {
                        type: 'transaction-manual-window'
                    })
                })
                .error(function(errorMessage, payload) {
                    if (payload.validationErrors) {
                        // Find possible amount/side error and set as error message
                        var amountError = false
                        Object.keys(payload.validationErrors).forEach(function(key) {
                            var attributes = payload.validationErrors[key].attributes
                            if (attributes && (attributes.amount || attributes.side)) {
                                amountError = true
                            }
                        })
                        if (amountError) {
                            transaction.set('error', t('transaction.window.amount_side_error'))
                        }
                    } else {
                        // Set error message, if not an error on a field
                        transaction.set('error', errorMessage)
                    }
                })
        }
    },

    totalDebit: function() {
        var sum = 0
        this.get('content.lines').forEach(function(line) {
            if (line.get('side.isDebit')) {
                sum += line.get('amount')
            }
        })
        return sum
    }.property('content.lines.@each.{amount,side}'),

    totalCredit: function() {
        var sum = 0
        this.get('content.lines').forEach(function(line) {
            if (line.get('side.isCredit')) {
                sum += line.get('amount')
            }
        })
        return sum
    }.property('content.lines.@each.{amount,side}')
})
