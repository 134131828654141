module.exports = BD.Model.extend({
    chartOfAccount: BD.belongsTo('Billy.ChartOfAccount', { readonly: true }),
    ownerUser: BD.belongsTo('Billy.User', { readonly: true }),
    ownerUmbrellaId: BD.attr('string', { readonly: true }),
    createdTime: BD.attr('datetime', { readonly: true }),
    chartOfAccountId: BD.attr('string', { readonly: true }),
    terminationTime: BD.attr('datetime', { readonly: true }),
    name: BD.attr('string'),
    url: BD.attr('string', { readonly: true }),
    street: BD.attr('string'),
    zipcode: BD.attr('string'),
    city: BD.attr('string'),
    country: BD.belongsTo('Billy.Country'),
    state: BD.belongsTo('Billy.State'),
    region: BD.attr('string'),
    phone: BD.attr('string'),
    fax: BD.attr('string'),
    email: BD.attr('string'),
    vatNo: BD.attr('string'),
    registrationNo: BD.attr('string'),
    companyType: BD.attr('string', { readonly: true }),
    baseCurrency: BD.belongsTo('Billy.Currency'),
    logoFile: BD.belongsTo('Billy.File'),
    logoPdfFile: BD.belongsTo('Billy.File', { readonly: true }),
    logoUrl: BD.attr('string', { readonly: true }),
    iconFile: BD.belongsTo('Billy.File'),
    iconUrl: BD.attr('string', { readonly: true }),
    icon48Url: BD.attr('string', { readonly: true }),
    fiscalYearEndMonth: BD.attr('number'),
    firstFiscalYearStart: BD.attr('date'),
    firstFiscalYearEnd: BD.attr('date'),
    fiscalLockingDate: BD.attr('date'),
    billyProviderId: BD.attr('string'),
    subscriptionPlan: BD.attr('string'),
    subscriptionProductPlan: BD.attr('string'),
    isSubscriptionBankPayer: BD.attr('boolean'),
    subscriptionCurrency: BD.belongsTo('Billy.Currency'),
    subscriptionPrice: BD.attr('number'),
    subscriptionPeriod: BD.attr('string'),
    subscriptionDiscount: BD.attr('number', { readonly: true }),
    subscriptionExpires: BD.attr('date'),
    consolidatedBilling: BD.attr('boolean'),
    isTrial: BD.attr('boolean', { readonly: true }),
    isTerminated: BD.attr('boolean'),
    hasBillVoucherNo: BD.attr('boolean'),
    billEmailAddress: BD.proxyAttr('string', function(email) {
        return email.split('@')[0] + '@' + ENV.inboundEmailDomain
    }),
    isLocked: BD.attr('boolean', { readonly: true }),
    lockedCode: BD.attr('string', { readonly: true }),
    lockedReason: BD.attr('string', { readonly: true }),
    isUnmigrated: BD.attr('boolean', { readonly: true }),
    appUrl: BD.attr('string', { readonly: true }),
    emailAttachmentDeliveryMode: BD.attr('string'),
    hasVat: BD.attr('boolean'),
    salesTaxPeriod: BD.attr('string'),
    locale: BD.belongsTo('Billy.Locale'),
    defaultTaxMode: BD.attr('string'),
    invoiceNoMode: BD.attr('string'),
    nextInvoiceNo: BD.attr('number'),
    paymentTermsMode: BD.attr('string'),
    paymentTermsDays: BD.attr('number'),
    subscriptionTransaction: BD.belongsTo('Billy.QuickpayTransaction'),
    defaultSalesAccount: BD.belongsTo('Billy.Account'),
    defaultSalesTaxRuleset: BD.belongsTo('Billy.SalesTaxRuleset'),
    defaultInventoryAccount: BD.belongsTo('Billy.Account'),
    bankSyncStartDate: BD.attr('date'),
    defaultBankFeeAccount: BD.belongsTo('Billy.Account'),
    defaultBillBankAccount: BD.belongsTo('Billy.Account'),
    defaultInvoiceBankAccount: BD.belongsTo('Billy.Account'),
    defaultTemplateId: BD.attr('string'),
    couponCode: BD.attr('string', { readonly: true }),
    couponRedeemed: BD.attr('date', { readonly: true }),
    couponExpires: BD.attr('date', { readonly: true }),
    couponDiscountPercent: BD.attr('number', { readonly: true }),
    defaultDaybook: BD.belongsTo('Billy.Daybook'),
    extraSeatsAddonLimit: BD.attr('number'),
    hasUnlimitedPostingsAddon: BD.attr('boolean'),
    postingCount: BD.attr('number', { readonly: true }),
    postingLimit: BD.attr('number', { readonly: true }),
    couponPeriod: BD.attr('string', { readonly: true }),
    industryGroup: BD.belongsTo('Billy.IndustryGroup'),
    baseCurrencyId: BD.attr('string', { readonly: true }),
    isVatExempted: BD.attr('boolean', { defaultValue: false }),
    activationDate: BD.attr('date', { readonly: true }),
    billingType: BD.attr('string', { readonly: true }),
    randomBucket: BD.attr('number'),
    analytics: BD.attr('string'),
    defaultInvoiceBankAccountId: BD.attr('string'),
    defaultSalesAccountId: BD.attr('string'),
    defaultSalesTaxRulesetId: BD.attr('string'),
    localeId: BD.attr('string'),
    globalId: BD.attr('string'),
    externalId: BD.attr('string'),
    brand: BD.attr('string'),
    source: BD.attr('string'),
    isTest: BD.attr('boolean'),
    accountingBasis: BD.attr('string')
})
