var batmask = require('batmask')

module.exports = Em.Route.extend({
    previousRouteName: null,

    actions: {
        willTransition: function(transition) {
            this.set('previousRouteName', transition.targetName)

            var allowTransition = true
            if (require('ember-window').hasWindows()) {
                console.warn('Transition blocked by open modal window')
                allowTransition = false
            }
            if (allowTransition) {
                return true
            } else {
                transition.abort()
            }
        },

        didTransition: function(transition) {
            var previousRouteName = this.get('previousRouteName')

            // For Annual Reports routes, we have to force unmask of the loading screen
            // since it takes long time to load the model and Ember route is settled
            // only after model is loaded. Therefore, we don't really have means to unmask it
            // when aborting that route in the middle of model fetching (so clicking on ARs
            // and then elsewhere when spinner still stays on the screen.
            if (previousRouteName && previousRouteName.includes('annual_report')) {
                batmask.unmask($('.user-main'), true)
            }
        }
    }
})
