var t = require('i18n').t
var batmask = require('batmask')
var timeHelper = require('../utils/time-helper')
var tracking = require('../utils/tracking')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    intercom: Ember.inject.service(),

    segment: Em.inject.service(),

    preselectedTemplate: null,

    deletedLines: function() {
        return []
    }.property(),

    editorErrors: function() {
        return DS.Errors.create()
    }.property(),

    setPreselectedTemplateOption: function() {
        var invoiceTemplateId = this.get('model.templateId')
        if (invoiceTemplateId) {
            this.set('preselectedTemplate', invoiceTemplateId)
            return
        }

        var templateOptions = this.get('templatesOptions')
        if (templateOptions) {
            this.set('preselectedTemplate', templateOptions[0].value)
        }
    }.observes('model'),

    validate: function() {
        var invoice = this.get('model')
        var lines = invoice.get('lines')

        // validate
        this.validateInvoice(invoice)
        lines.forEach(this.validateLine.bind(this))

        return this.get('editorErrors.isEmpty') && invoice.get('errors.isEmpty') && lines.every(function(line) {
            return line.get('errors.isEmpty')
        })
    },

    validateInvoice: function(invoice) {
        this.validatePresence(invoice, ['contact', 'currency', 'recurringInterval'])

        if (invoice.get('recurringSpawnMode') === 'email') {
            this.validatePresence(invoice, ['attContactPerson'])
            var person = invoice.get('attContactPerson')
            if (person && !person.get('email')) {
                invoice.get('errors').add('attContactPerson', t('recurring_invoice_edit.missing_contact_person_email'))
            }
        }
    },

    validateLine: function(line) {
        this.validatePresence(line, ['product', 'quantity', 'unitPrice'])
    },

    validatePresence: function(r, attributes) {
        var errors = r.get('errors')
        attributes.forEach(function(key) {
            if (Em.isEmpty(r.get(key))) {
                errors.add(key, t('required_field'))
            }
        })
    },

    save: function() {
        var invoice = this.get('model').set('templateId', this.get('preselectedTemplate'))
        if (!this.validate()) {
            return
        }
        var now = timeHelper.now()
        var start = invoice.get('parsedRecurringInterval.start').zone(now.zone())
        if (start.isBefore(now)) {
            // Job runs every 2 hours starting 02 in the morning
            var nextJob
            for (var jobTime = 2; jobTime <= 26; jobTime += 2) {
                if (jobTime > now.hours()) {
                    nextJob = moment().hours(jobTime).minutes(0)
                    break
                }
            }
            this.container.lookup('util:dialog')
                .confirm(t('recurring_invoice.edit.starts_now_title'), t('recurring_invoice.edit.starts_now_text', { duration: now.from(nextJob, true), time: nextJob.format('HH:mm DD. MMMM YYYY') }), t('recurring_invoice.edit.yes_start'), t('no'))
                .then(this.saveConfirmed.bind(this))
        } else if (start.diff(now, 'hour', true) < 12) {
            this.container.lookup('util:dialog')
                .confirm(t('recurring_invoice.edit.starts_soon_title'), t('recurring_invoice.edit.starts_soon_text', { time: start.format('HH:mm DD. MMMM YYYY'), duration: start.from(now, true) }), t('recurring_invoice.edit.yes_start'), t('no'))
                .then(this.saveConfirmed.bind(this))
        } else {
            this.saveConfirmed()
        }
    },

    saveConfirmed: function() {
        var self = this
        var invoice = this.get('model')
        var wasNew = invoice.get('isNew')
        batmask.maskDelayed()
        return invoice.save()
            .then(this.saveLines.bind(this))
            .then(function() {
                if (wasNew) {
                    self.get('segment').track('Recurring Invoice Started (Client)')
                    tracking.emitAnalyticsEvent('premium_activity', 'created_recurring_invoice', 'created recurring invoice')
                    self.get('intercom').trackEvent(self.get('organization'), 'created_recurring_invoice')
                }

                // Make sure that we override new-route with the edit route for the newly created invoice
                return self.replaceRoute('recurring_invoice.edit', invoice)
            })
            .then(function() {
                return self.transitionToRoute('recurring_invoice', invoice)
            })
            .finally(batmask.unmask.bind(batmask))
    },

    saveLines: function() {
        var deletedLines = this.get('deletedLines')
        var allLines = this.get('lines')
            .map(function(line) { // Can only concat a normal array. `lines` is a record array
                return line
            })
            .concat(deletedLines)

        return Em.RSVP.all(allLines.map(function(line) {
            return line.save()
                .then(function() {
                    deletedLines.removeObject(line)
                })
        }))
    },

    actions: {
        backToList: function() {
            this.transitionToRoute('recurring_invoices.index')
        },

        deleteLine: function(line) {
            this.get('deletedLines').pushObject(line)
        },

        save: function() {
            this.save()
        }
    }
})
