var _ = require('lodash')

module.exports = Em.Route.extend({
    userOrganizations: Ember.inject.service(),

    userUmbrellas: Em.inject.service(),

    dashboardNavigator: Em.inject.service(),

    redirectToCreateOrganizationGuide: function(user) {
        this.container.lookup('controller:signup-guide').set('user', user)
        this.replaceWith('signup-guide')
    },

    beforeModel: function(transition) {
        var self = this
        var queryParams = transition.queryParams
        var user

        var userOrganizations = this.get('userOrganizations')
        var lastOrganizationUrl = userOrganizations.get('lastOrganizationUrl')

        if (lastOrganizationUrl) {
            self.get('dashboardNavigator').replaceUrlWithDashboard(lastOrganizationUrl, queryParams)
            return
        }

        return this.get('api').request('GET', '/v2/user')
            .then(function(payload) {
                user = payload.user
                return userOrganizations.all()
            })
            .then(function(organizations) {
                if (organizations.length) {
                    var userId = user && user.id
                    var userOwnOrganization = _.find(organizations, function(organization) { return organization.organizationOwnerUserId === userId })
                    var userOwnOrganizationUrl = userOwnOrganization && userOwnOrganization.url
                    var organizationUrl = userOwnOrganizationUrl || organizations[0].url
                    self.get('dashboardNavigator').replaceUrlWithDashboard(organizationUrl, queryParams)
                } else {
                    self.get('userUmbrellas').all()
                        .then(function(umbrellas) {
                            if (umbrellas.length) {
                                var umbrellaId = umbrellas[0].umbrellaId
                                self.replaceWith('umbrella_profile', umbrellaId)
                                return
                            }

                            self.redirectToCreateOrganizationGuide(user)
                        })
                }
            })
    }
})
