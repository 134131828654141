var billyApi = require('./billy-api')
var tracking = require('./tracking')

var bootstrapModels = [
    Billy.Currency,
    Billy.Country,
    Billy.CountryGroup,
    Billy.State, // Server doesn't send this, but test bootstrap does
    Billy.AccountNature,
    Billy.File,
    Billy.Locale
]

var bootstrapRequest = null

module.exports = function() {
    if (bootstrapRequest) {
        if (bootstrapRequest.abort) {
            bootstrapRequest.abort()
        }
        bootstrapRequest = null
    }
    bootstrapRequest = billyApi.get('/user/bootstrap')
        .then(function(payload) {
            // User
            var user = Billy.User.load(payload.user)

            if (window.DD_RUM) {
                window.DD_RUM.onReady(function() {
                    window.DD_RUM.setUser({
                        id: user.id,
                        name: user.get('name'),
                        email: user.get('email')
                    })
                })
            }

            tracking.startIntercomTracking(
                user,
                ENV.INTERCOM_APP_ID,
                user.get('globalId'),
                payload.intercomGlobalIdUserHash
            )

            // Other loadAll
            bootstrapModels.forEach(function(type) {
                var root = BD.pluralize(Em.get(type, 'root'))
                if (payload[root]) {
                    BD.store.loadAll(type, payload[root])
                }
            })

            Billy.__container__.lookup('service:intercom').setMessengerIsHidden()

            // Resolve user model
            return user
        })
        .finally(function() {
            bootstrapRequest = null
        })

    return bootstrapRequest
}
