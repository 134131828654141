var Window = require('ember-window')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/transaction-owner-window'),

    segment: Em.inject.service(),

    classNames: ['transaction-window-view'],

    customEvent: Ember.inject.service(),

    type: null,
    isContribution: function() {
        return this.get('type') === 'contribution'
    }.property('type'),
    isWithdrawal: function() {
        return this.get('type') === 'withdrawal'
    }.property('type'),

    content: null,
    line: function() {
        return this.get('content.lines.firstObject')
    }.property('content'),

    equityNatures: function() {
        return [Billy.AccountNature.find('equity')]
    }.property(),

    isPaymentEnabledQuery: {
        isPaymentEnabled: true
    },

    errorMessage: '',

    // Bound to attachments-list in template
    isUploading: null,

    actions: {
        didUploadFile: function() {
            this.get('segment').track('File Uploaded (Client)', {
                context: 'transactions'
            })
        },

        approve: function() {
            var transaction = this.get('content')
            var line = this.get('line')
            var error = false
            var errorMessage = ''
            if (!transaction.get('entryDate')) {
                transaction.set('errors.entryDate', t('required_field'))
                error = true
            }
            if (!line.get('amount')) {
                line.set('errors.amount', t('required_field'))
                error = true
            }
            if (!line.get('account')) {
                line.set('errors.account', t('required_field'))
                error = true
            }
            if (!line.get('contraAccount')) {
                line.set('errors.contraAccount', t('required_field'))
                error = true
            }
            if (this.get('isUploading')) {
                errorMessage = t('still_uploading')
                error = true
            }
            // Reset error message
            this.set('errorMessage', errorMessage)
            if (error) {
                return
            }
            // Set transaction to approved and save
            var self = this
            transaction.set('state', 'approved')
            transaction.save({
                embed: ['lines', 'attachments']
            })
                .mask()
                .success(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TRANSACTION_APPROVED, t('transaction.window.approval_success'))
                    self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
                    self.close()

                    self.get('segment').track('Transaction Template Approved (Client)', {
                        type: 'transaction-owner-window' + '-' + self.get('type')
                    })
                })
                .error(function(errorMessage, payload) {
                    // Set error message, if not an error on a field
                    if (!payload.validationErrors) {
                        self.set('errorMessage', errorMessage)
                    }
                })
        }
    }
})
