var trackError = require('trackError')

module.exports = Em.Controller.extend({
    token: null,

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute.apply(this, [route].concat(props || []))
        },
        authorizeUser: function(e) {
            var token = e.detail.token

            if (!token) {
                trackError('You have to pass "token" to authorize user')
                return
            }

            var api = this.container.lookup('api:billy')
            api.authorize(token, false)
        }
    }
})
