var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListModelController = require('billy-webapp/ui/list/model-controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var ListAction = require('billy-webapp/ui/list/action')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = ListModelController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    segment: Em.inject.service(),

    showArchived: false,

    qBufferDelay: function() {
        return BD.get('loadedAll.products') ? 1 : require('ember-text-field').prototype.bufferDelay
    }.property('BD.loadedAll.products'),

    query: function() {
        var query = {
            organization: this.get('organization')
        }
        if (!this.get('showArchived')) {
            query.isArchived = false
        }
        return query
    }.property('organization', 'showArchived'),

    remoteQuery: function() {
        return {
            include: 'product.account,product.prices'
        }
    }.property(),

    handleCreate: function() {
        var self = this
        var organization = this.get('organization')
        var product = Billy.Product.createRecord({
            organization: organization,
            account: organization.get('defaultSalesAccount'),
            salesTaxRuleset: organization.get('defaultSalesTaxRuleset')
        })
        Billy.ProductPrice.createRecord({
            product: product,
            currency: organization.get('baseCurrency')
        })

        var productEditor = this.container.lookup('component:product-editor')
        productEditor.set('content', product)
        productEditor.set('callback', function() {
            self.refreshList()
        })
        productEditor.show()
    },

    refreshList: function() {
        this.clearAndLoadRecords()
    },

    actions: {
        edit: function(product) {
            this.container.lookup('component:product-editor')
                .set('content', product)
                .show()
        },

        create: function() {
            this.handleCreate()
        },

        delete: function(product) {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('products.confirm_delete_product'), t('products.yes_delete'), t('cancel'))
                .then(function() {
                    product.deleteRecord()
                        .success(function() {
                            self.get('segment').track('Product Deleted (Client)')
                            self.refreshList()
                        })
                        .error(function(errorMessage, payload) {
                            if (payload.errorCode === 'UNDELETABLE_RECORD') {
                                self.container.lookup('util:dialog').confirmWarning(t('products.archive_title'), t('products.archive_undeletable_question'), t('products.yes_archive'))
                                    .then(function() {
                                        product.set('isArchived', true).save()
                                        self.refreshList()
                                    })
                            } else {
                                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PRODUCT_DELETE, errorMessage)
                            }
                        })
                })
        }
    },

    type: Billy.Product,
    sortProperty: 'name',
    layout: 'table',
    tableRowHeight: 80,
    defaultBoxImage: 'icons/product',
    itemControllerClass: ListItemController.extend({
        productNoWithDescription: function() {
            var productNo = this.get('productNo') || ''
            var description = this.get('description') || ''
            return productNo + ((productNo && description) ? ' - ' : '') + description
        }.property('productNo', 'description'),

        firstPrice: function() {
            var html = ''
            var price = this.get('model.prices.firstObject')
            if (price) {
                html = Billy.money(price.get('unitPrice'), price.get('currency')) + '<br>'
            }
            return html.htmlSafe()
        }.property('prices.firstObject.unitPrice', 'prices.firstObject.currency')
    }),
    columns: function() {
        return [
            Column.create({
                header: t('name'),
                name: 'name',
                sortable: true,
                flex: 1,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '<div class="two-rows-cell">' +
                            '<div class="ellipsis" data-cy="product-name">' +
                                '{{name}}' +
                                '{{#if isArchived}} <span class="label orange" data-cy="archived-label">{{t \'accounts.index.archived\'}}</span>{{/if}}' +
                            '</div>' +
                            '<div class="small-font downtoned-text ellipsis">{{productNoWithDescription}}</div>' +
                        '</div>'
                    )
                })
            }),
            Column.create({
                header: null,
                name: 'image',
                width: ListController.tableRowHeightAdditionalSmallLine,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if imageUrl}}<img class="table-item-image" {{bind-attr src=imageUrl}}>{{/if}}'
                    )
                })
            }),
            Column.create({
                header: t('products.editor.account'),
                name: 'account.name',
                width: 200
            }),
            Column.create({
                header: t('products.editor.price'),
                name: 'firstPrice',
                width: 200,
                align: 'right'
            })
        ].filter(function(col) {
            return !!col // Filter null columns
        })
    }.property('organization.subscriptionPlan'),
    listActions: [
        ListAction.create({
            icon: 'icons/pencil',
            tip: t('products.edit_product'),
            click: 'edit',
            dataCy: 'edit-icon'
        }),
        ListAction.create({
            icon: 'icons/trashcan',
            tip: t('products.delete_product'),
            click: 'delete',
            dataCy: 'delete-icon'
        })
    ],

    customActions: {
        navigate: function(e) {
            this.handleCreate()
        }
    }
})
