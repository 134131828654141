var trackError = require('trackError')
var Window = require('ember-window')
var t = require('i18n').t
var batmask = require('batmask')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/inventory-primo-editor'),

    width: 670,

    content: null,

    callback: null,

    amount: null,

    isStatus: true,

    validate: function() {
        var status = this.get('content')
        var errors = status.get('errors')
        var isValid = true

        var requiredFields = [
            'organization',
            'approvedTime',
            'product',
            'amount',
            'quantity'
        ]
        requiredFields.forEach(function(f) {
            if (Em.isEmpty(status.get(f))) {
                errors.add(f, t('required_field'))
                isValid = false
            }
        })

        return isValid
    },

    actions: {
        save: function() {
            var self = this
            var status = this.get('content')
            if (!this.validate()) {
                return
            }
            batmask.maskDelayed()
            status.save()
                .then(function() {
                    self.close()
                    var callback = self.get('callback')
                    if (callback) {
                        callback(status)
                    }
                }, function(e) {
                    // eslint-disable-next-line no-console
                    trackError(e)
                    self.set('error', t('util.request.default_error'))
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
